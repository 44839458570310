import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BREADCRUMBS } from '../../components/layout/layout.types';
import { TestFile } from '@common/types/testFile';
import { QuestionRepo } from '../../services/question-repo/questionRepo';
import { OnlineApiService } from '../../services/onlineApi/online-api.service';
import { TestInterface } from '@common/types/api/test.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

  public currentState: BREADCRUMBS = 'ENTER';
  public newTest = new TestFile();
  public currentQuestion = null;
  public maxQuestion = null;
  public isLoading = true;
  public reportResult: TestInterface = null;
  public tryAgain = false;

  @Output() resultSent = new EventEmitter();
  @Output() nextWorker = new EventEmitter();

  constructor(
    public questionRepo: QuestionRepo,
    public api: OnlineApiService,
  ) {
  }

  ngOnInit() {
  }

  async sendResult() {
    this.isLoading = true;
    this.tryAgain = false;
    this.reportResult = await this.api.sendResult(this.newTest.test, this.newTest.worker, async (error) => {
      console.log(error);
      this.tryAgain = true;
    });
    this.isLoading = false;
  }

  downloadTestFile() {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify({
      test: _(this.newTest.test).omit('focusTimes', 'answerTimes'),
      worker: this.newTest.worker,
    })));
    const name = [this.newTest.worker.firstName, this.newTest.worker.secondName].join(' ');
    element.setAttribute('download', `Тестирование - ${name}.json`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  async next() {
    switch (this.currentState) {
      case 'ENTER':
        this.currentState = 'CLAIMS';
        this.currentQuestion = 0;
        this.maxQuestion = this.questionRepo.claims().length;
        this.api.log(this.newTest.test, this.newTest.worker);
        break;
      case 'CLAIMS':
        this.currentState = 'INTERESTS';
        this.currentQuestion = null;
        this.api.log(this.newTest.test, this.newTest.worker);
        break;
      case 'INTERESTS':
        this.currentState = 'STRATEGY';
        this.currentQuestion = 0;
        this.maxQuestion = this.questionRepo.strategy().length;
        this.api.log(this.newTest.test, this.newTest.worker);
        break;
      case 'STRATEGY':
        this.currentState = 'MOTIVATION';
        this.currentQuestion = 0;
        this.maxQuestion = this.questionRepo.motivation().length;
        this.api.log(this.newTest.test, this.newTest.worker);
        break;
      case 'MOTIVATION':
        this.questionRepo.fillResult(this.newTest.test);
        if (this.newTest.test.result.isConflict()) {
          this.currentState = 'EXTRA';
        } else {
          this.currentState = 'FINISH';
          await this.sendResult();
        }
        this.currentQuestion = null;
        this.maxQuestion = null;
        this.api.log(this.newTest.test, this.newTest.worker);
        break;
      case 'EXTRA':
        this.currentState = 'FINISH';
        this.api.log(this.newTest.test, this.newTest.worker);
        await this.sendResult();
        break;
      default:
        throw new Error('Неизвестный state');
    }
  }
}
