import { Test } from './test';

export class Worker {
  public id: number;

  public firstName: string = null;
  public secondName: string = null;
  public middleName: string = null;
  public gender: number = null;

  public birthday: string;
  public address: string;
  public phone: string;
  public email: string;

  public education: string = null;
  public social: string;
  public financial: string;

  public organization: string;
  public department: string;
  public post: string;
  public startWorkDate: string;
  public experience: string;

  public isActive = 1; // 0 = уволнен -1 = кандидат

  public tests: Test[] = null;

  public get fio() {
    return `${this.secondName || ''} ${this.firstName || ''} ${this.middleName || ''}`.trim();
  }

  public constructor(data: Partial<Worker> = {}) {
    Object.assign(this, data);
  }

  public get shortFio() {
    if (this.firstName && !this.secondName) {
      return this.firstName;
    }
    if (!this.firstName && this.secondName) {
      return this.secondName;
    }
    if (!this.secondName || !this.firstName) {
      return null;
    }
    let fio = `${this.secondName} ${this.firstName[0]}.`;
    if (this.middleName && this.middleName.length > 0) {
      fio += this.middleName[0] + '.';
    }
    return fio;
  }

  public get workStr() {
    if (!this.organization && !this.department) {
      return 'Организация не указана';
    }
    if (this.organization && !this.department) {
      return this.organization;
    }
    if (!this.organization && this.department) {
      return this.department;
    }
    return this.organization + ' - ' + this.department;
  }

  public getBirthdayDate() {
    try {
      return new Date(this.birthday.split('.').reverse().join('-'));
    } catch (e) {
      return null;
    }
  }

  public getStartWorkDate() {
    try {
      return new Date(this.startWorkDate.split('.').reverse().join('-'));
    } catch (e) {
      return null;
    }
  }

  public genderTitle(): string {
    switch (this.gender) {
      case 1:
        return 'мужской';
      case 0:
        return 'женский';
      default:
        return 'не указан';
    }
  }

  public lastTest(): Test {
    return this.tests.length > 0 ? this.tests.sort((test1, test2) => test2.date > test1.date ? 1 : -1)[0] : null;
  }
}
