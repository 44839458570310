import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BREADCRUMBS } from './layout.types';
import packageData from '../../../../package.json';

interface BreadcrumbsListInterface {
  type: BREADCRUMBS;
  title: string;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnChanges {

  @Input() activeBreadcrumb: BREADCRUMBS = 'ENTER';
  @Input() questionNumber: number = null;
  @Input() maxQuestionNumber: number = null;
  @Input() showBreadcrumbs = true;

  public version = packageData.version;

  breadcrumbsList: BreadcrumbsListInterface[] = [];

  constructor(
  ) { }

  ngOnInit() {
    this.setBreadcrumbsList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setBreadcrumbsList();
  }

  protected setBreadcrumbsList() {
    this.breadcrumbsList = [];
    this.breadcrumbsList.push({ type: 'ENTER', title: 'Вход' });
    this.breadcrumbsList.push({ type: 'CLAIMS', title: 'Притязания' });
    this.breadcrumbsList.push({ type: 'INTERESTS', title: 'Интересы' });
    this.breadcrumbsList.push({ type: 'STRATEGY', title: 'Стратегия' });
    this.breadcrumbsList.push({ type: 'MOTIVATION', title: 'Мотивация' });
    if (this.activeBreadcrumb === 'EXTRA') {
      this.breadcrumbsList.push({ type: 'EXTRA', title: 'Доп. вопросы' });
    }
    if (this.activeBreadcrumb === 'FINISH') {
      this.breadcrumbsList.push({ type: 'FINISH', title: 'Итоги тестирования' });
    }
  }
}
