import {QuestionInterface} from './questionInterface';
import {InterestIndicator} from './indicators';
import {Result} from '@common/types/result';
import { Lang } from '@common/lang';

const T = Lang.getText();

export const ideologOptoins = Object.keys(T.interests).filter(key => key.match('ideologOption')).map(key => T.interests[key]);
export const materialOptions = Object.keys(T.interests).filter(key => key.match('materialOption')).map(key => T.interests[key]);
export const emotionalOptions = Object.keys(T.interests).filter(key => key.match('emotionalOption')).map(key => T.interests[key]);
export const vitalOptions = Object.keys(T.interests).filter(key => key.match('vitalOption')).map(key => T.interests[key]);

// Константа с ID вопросов
export const INTERESTS_QUESTIONS = {
  VALUES: {
    IDEA:      2101,
    MATERIAL:  2102,
    EMOTIONAL: 2103,
    VITAL:     2104,
  },
  VIRTUAL: {
    IDEA:      2201,
    MATERIAL:  2202,
    EMOTIONAL: 2203,
    VITAL:     2204,
  },
  ANTI: {
    IDEA:      2301,
    MATERIAL:  2302,
    EMOTIONAL: 2303,
    VITAL:     2304,
  },
  ONE_OF_FOUR: {
    IDEA:      2401,
    MATERIAL:  2402,
    EMOTIONAL: 2403,
    VITAL:     2404,
  }
};

export class InterestsQuestion implements QuestionInterface {
  public id: number;
  public text: string;
  public group: number;
  public groupText: string;
  public options: string[];
  public key: InterestIndicator = null;
  public useInResult = false;

  constructor(data: Partial<InterestsQuestion>) {
    Object.assign(this, data);
  }

  fillResult(result: Result, answer: any) {
    if (this.key && this.useInResult) {
      result.addWeight(this.key, answer.length);
    }
  }
}

const IDS = INTERESTS_QUESTIONS;

export const interestsQuestions = [
  new InterestsQuestion({
    id: IDS.VALUES.IDEA,
    group: 1,
    groupText: T.pages.interests,
    text: T.pages.ideolog,
    options: ideologOptoins,
    key: 'ideolog',
  }),
  new InterestsQuestion({
    id: IDS.VALUES.MATERIAL,
    group: 1,
    groupText: T.pages.interests,
    text: T.pages.material,
    options: materialOptions,
    key: 'materialist',
  }),
  new InterestsQuestion({
    id: IDS.VALUES.EMOTIONAL,
    group: 1,
    groupText: T.pages.interests,
    text: T.pages.emotional,
    options: emotionalOptions,
    key: 'emotional',
  }),
  new InterestsQuestion({
    id: IDS.VALUES.VITAL,
    group: 1,
    groupText: T.pages.interests,
    text: T.pages.vital,
    options: vitalOptions,
    key: 'vitalist',
  }),

  /////////////////////////////////////////////
  new InterestsQuestion({
    id: IDS.VIRTUAL.IDEA,
    group: 2,
    groupText: T.pages.virtualInterests,
    text: T.pages.ideolog,
    options: ideologOptoins,
    key: 'materialist',
  }),
  new InterestsQuestion({
    id: IDS.VIRTUAL.MATERIAL,
    group: 2,
    groupText: T.pages.virtualInterests,
    text: T.pages.material,
    options: materialOptions,
    key: 'materialist',
  }),
  new InterestsQuestion({
    id: IDS.VIRTUAL.EMOTIONAL,
    group: 2,
    groupText: T.pages.virtualInterests,
    text: T.pages.emotional,
    options: emotionalOptions,
    key: 'emotional',
  }),
  new InterestsQuestion({
    id: IDS.VIRTUAL.VITAL,
    group: 2,
    groupText: T.pages.virtualInterests,
    text: T.pages.vital,
    options: vitalOptions,
    key: 'vitalist',
  }),

  /////////////////////////////////////////////
  new InterestsQuestion({
    id: IDS.ANTI.IDEA,
    group: 3,
    groupText: T.pages.antiInterests,
    text: T.pages.ideolog,
    options: ideologOptoins,
    key: 'ideolog',
  }),
  new InterestsQuestion({
    id: IDS.ANTI.MATERIAL,
    group: 3,
    groupText: T.pages.antiInterests,
    text: T.pages.material,
    options: materialOptions,
    key: 'materialist',
  }),
  new InterestsQuestion({
    id: IDS.ANTI.EMOTIONAL,
    group: 3,
    groupText: T.pages.antiInterests,
    text: T.pages.emotional,
    options: emotionalOptions,
    key: 'emotional',
  }),
  new InterestsQuestion({
    id: IDS.ANTI.VITAL,
    group: 3,
    groupText: T.pages.antiInterests,
    text: T.pages.vital,
    options: vitalOptions,
    key: 'vitalist',
  }),

  /////////////////////////////////////////////
  new InterestsQuestion({
    id: IDS.ONE_OF_FOUR.IDEA,
    group: 4,
    groupText: T.pages.oneOfFour,
    text: T.pages.ideolog,
    options: ideologOptoins,
    key: 'ideolog',
    useInResult: true,
  }),
  new InterestsQuestion({
    id: IDS.ONE_OF_FOUR.MATERIAL,
    group: 4,
    groupText: T.pages.oneOfFour,
    text: T.pages.material,
    options: materialOptions,
    key: 'materialist',
    useInResult: true,
  }),
  new InterestsQuestion({
    id: IDS.ONE_OF_FOUR.EMOTIONAL,
    group: 4,
    groupText: T.pages.oneOfFour,
    text: T.pages.emotional,
    options: emotionalOptions,
    key: 'emotional',
    useInResult: true,
  }),
  new InterestsQuestion({
    id: IDS.ONE_OF_FOUR.VITAL,
    group: 4,
    groupText: T.pages.oneOfFour,
    text: T.pages.vital,
    options: vitalOptions,
    key: 'vitalist',
    useInResult: true,
  }),
];
