import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TestFile } from '@common/types/testFile';
import * as _ from 'lodash';
export class TestPageComponent {
    constructor(questionRepo, api) {
        this.questionRepo = questionRepo;
        this.api = api;
        this.currentState = 'ENTER';
        this.newTest = new TestFile();
        this.currentQuestion = null;
        this.maxQuestion = null;
        this.isLoading = true;
        this.reportResult = null;
        this.tryAgain = false;
        this.resultSent = new EventEmitter();
        this.nextWorker = new EventEmitter();
    }
    ngOnInit() {
    }
    sendResult() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            this.tryAgain = false;
            this.reportResult = yield this.api.sendResult(this.newTest.test, this.newTest.worker, (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                console.log(error);
                this.tryAgain = true;
            }));
            this.isLoading = false;
        });
    }
    downloadTestFile() {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify({
            test: _(this.newTest.test).omit('focusTimes', 'answerTimes'),
            worker: this.newTest.worker,
        })));
        const name = [this.newTest.worker.firstName, this.newTest.worker.secondName].join(' ');
        element.setAttribute('download', `Тестирование - ${name}.json`);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
    next() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            switch (this.currentState) {
                case 'ENTER':
                    this.currentState = 'CLAIMS';
                    this.currentQuestion = 0;
                    this.maxQuestion = this.questionRepo.claims().length;
                    this.api.log(this.newTest.test, this.newTest.worker);
                    break;
                case 'CLAIMS':
                    this.currentState = 'INTERESTS';
                    this.currentQuestion = null;
                    this.api.log(this.newTest.test, this.newTest.worker);
                    break;
                case 'INTERESTS':
                    this.currentState = 'STRATEGY';
                    this.currentQuestion = 0;
                    this.maxQuestion = this.questionRepo.strategy().length;
                    this.api.log(this.newTest.test, this.newTest.worker);
                    break;
                case 'STRATEGY':
                    this.currentState = 'MOTIVATION';
                    this.currentQuestion = 0;
                    this.maxQuestion = this.questionRepo.motivation().length;
                    this.api.log(this.newTest.test, this.newTest.worker);
                    break;
                case 'MOTIVATION':
                    this.questionRepo.fillResult(this.newTest.test);
                    if (this.newTest.test.result.isConflict()) {
                        this.currentState = 'EXTRA';
                    }
                    else {
                        this.currentState = 'FINISH';
                        yield this.sendResult();
                    }
                    this.currentQuestion = null;
                    this.maxQuestion = null;
                    this.api.log(this.newTest.test, this.newTest.worker);
                    break;
                case 'EXTRA':
                    this.currentState = 'FINISH';
                    this.api.log(this.newTest.test, this.newTest.worker);
                    yield this.sendResult();
                    break;
                default:
                    throw new Error('Неизвестный state');
            }
        });
    }
}
