import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Lang } from '@common/lang';
export class MotivationTestComponent {
    constructor(questionRepo) {
        this.questionRepo = questionRepo;
        this.nextState = new EventEmitter();
        this.nextQuestion = new EventEmitter();
        this.T = Lang.getText();
        this.questions = [];
        // Специальная переменная для вопроса является ли сотрудник руководителем
        this.beforeLastQuestion = false;
        this.currentOption = null;
        this.currentQuestion = 0;
        this.hoverTimes = {
            [-1]: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
        };
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.questions = this.questionRepo.motivation();
            this.startThinkTime = (new Date()).getTime();
        });
    }
    getCurrentQuestion() {
        return this.questions[this.currentQuestion];
    }
    getCurrentOptions() {
        const q = this.getCurrentQuestion();
        return Object.keys(q.options).map(key => ({ option: parseInt(key, 10), text: q.options[key] }));
    }
    trackByFn(option) {
        return option.option;
    }
    selectAnswer() {
        if (!this.currentOption) {
            return;
        }
        this.newTest.test.setAnswer(this.getCurrentQuestion().id, this.currentOption);
        this.currentOption = null;
        // Залисываем время ответа
        this.newTest.test.answerTimes.push({
            questionId: this.getCurrentQuestion().id,
            testGroup: 'motivation',
            startTime: this.startThinkTime,
            endTime: (new Date()).getTime(),
        });
        this.startThinkTime = (new Date()).getTime();
        // Записываем время фокуса
        this.stopHover(this.currentOption);
        this.startHover();
        Object.keys(this.hoverTimes).map(key => {
            if (Object.keys(this.getCurrentQuestion().options).indexOf(key) !== -1) {
                this.newTest.test.focusTimes.push({
                    testGroup: 'motivation',
                    questionId: this.getCurrentQuestion().id,
                    answer: key,
                    time: this.hoverTimes[key] || 0,
                });
            }
        });
        this.hoverTimes = {
            [-1]: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
        };
        this.currentQuestion++;
        this.nextQuestion.emit(this.currentQuestion);
        if (this.currentQuestion === this.questions.length - 2) {
            this.beforeLastQuestion = true;
        }
        // Пропускаем последний вопрос, если мы ответили на предпоследний
        if (this.currentQuestion >= this.questions.length - 1) {
            this.nextState.emit(this.newTest);
        }
    }
    startHover() {
        this.startHoverTime = (new Date()).getTime();
    }
    stopHover(answer) {
        this.hoverTimes[answer] += (new Date()).getTime() - this.startHoverTime;
    }
    isLeader(answer) {
        // Сбрасываем замеряемое время для этого вопроса
        this.startThinkTime = (new Date()).getTime();
        this.startHover();
        this.beforeLastQuestion = false;
        if (!answer) {
            this.currentQuestion++;
        }
    }
}
