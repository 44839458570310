/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claims-test.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./claims-test.component";
import * as i4 from "../../../services/question-repo/questionRepo";
var styles_ClaimsTestComponent = [i0.styles];
var RenderType_ClaimsTestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimsTestComponent, data: {} });
export { RenderType_ClaimsTestComponent as RenderType_ClaimsTestComponent };
export function View_ClaimsTestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "f-col f-align-center f-justify-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text-center v-gap-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "text-center v-gap-32"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0435\u0440\u043D\u044B\u0439 \u0434\u043B\u044F \u0412\u0430\u0441 \u0432\u0430\u0440\u0438\u0430\u043D\u0442 "])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "label", [["class", "radio answer hover-input"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.startHover(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.stopHover(true) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.setAnswer(1) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "input", [["type", "radio"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 9).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.currentAnswer = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(9, 212992, null, 0, i2.RadioControlValueAccessor, [i1.Renderer2, i1.ElementRef, i2.ɵangular_packages_forms_forms_o, i1.Injector], { value: [0, "value"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.RadioControlValueAccessor]), i1.ɵdid(11, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0414\u0430"])), (_l()(), i1.ɵeld(16, 0, null, null, 9, "label", [["class", "radio answer hover-input v-gap-32"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.startHover(false) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.stopHover(false) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.setAnswer(0) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 6, "input", [["type", "radio"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 19).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 19).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.currentAnswer = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(19, 212992, null, 0, i2.RadioControlValueAccessor, [i1.Renderer2, i1.ElementRef, i2.ɵangular_packages_forms_forms_o, i1.Injector], { value: [0, "value"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.RadioControlValueAccessor]), i1.ɵdid(21, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(23, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041D\u0435\u0442"])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answer(_co.currentAnswer) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0414\u0430\u043B\u0435\u0435"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = 1; _ck(_v, 9, 0, currVal_8); var currVal_9 = _co.currentAnswer; _ck(_v, 11, 0, currVal_9); var currVal_17 = 0; _ck(_v, 19, 0, currVal_17); var currVal_18 = _co.currentAnswer; _ck(_v, 21, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCurrentQuestion().text; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 13).ngClassValid; var currVal_6 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 23).ngClassValid; var currVal_15 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 17, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }); }
export function View_ClaimsTestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claims-test", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ClaimsTestComponent_0, RenderType_ClaimsTestComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClaimsTestComponent, [i4.QuestionRepo], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimsTestComponentNgFactory = i1.ɵccf("app-claims-test", i3.ClaimsTestComponent, View_ClaimsTestComponent_Host_0, { newTest: "newTest" }, { nextQuestion: "nextQuestion", nextState: "nextState" }, []);
export { ClaimsTestComponentNgFactory as ClaimsTestComponentNgFactory };
