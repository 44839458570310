import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import { TestFile } from '@common/types/testFile';
import { InterestsQuestion } from '@common/types/questions/interests';
import { QuestionRepo } from '../../../services/question-repo/questionRepo';
import { Lang } from '@common/lang';
import {tmpl} from '@common/utils/text/tmlp';
import {plural} from '@common/utils/text/plural';
import {DOCUMENT} from '@angular/common';
import { AlertService } from '@common/ng/alert.service';

export interface FourthExtraOptionInterface {
  id: number;
  questionId: number;
  option?: string;
  line: number;
}

@Component({
  selector: 'app-interests-test',
  templateUrl: './interests-test.component.html',
  styleUrls: ['./interests-test.component.scss']
})
export class InterestsTestComponent implements OnInit {

  @Input() newTest: TestFile;
  @Output() nextState: EventEmitter<any> = new EventEmitter<any>();

  public T = Lang.getText();

  public group = 2;
  public fourthExtraOptions: FourthExtraOptionInterface[][] = [];
  public currentFourthExtra = 0;
  public questions: InterestsQuestion[] = [];
  public currentQuestionIdx = 0;
  public currentExtraAnswer = null;
  public disabledText: string = null;

  protected maxLine = 0;

  private startThinkTime: number;
  private startHoverTime: number;
  private hoverTimes: { [p: string]: number } = {};

  fixedMenu: boolean;

  constructor(
    private questionRepo: QuestionRepo,
    public alert: AlertService,
    @Inject(DOCUMENT) private doc: Document
  ) {
  }

  ngOnInit() {
    this.questions = this.questionRepo.interests();
  }

  get currentQuestions(): InterestsQuestion[] {
    const group = this.group === 3.5 ? 1 : this.group;
    return this.questions.filter(q => q.group === group);
  }

  get currentQuestion(): InterestsQuestion {
    return this.currentQuestions[this.currentQuestionIdx];
  }

  trackByFn(index, item) {
    return item;
  }

  nextStep() {
    if (this.group === 1) {
      const cntLeft = 10 - this.getAnswers([this.currentQuestion]).length;
      if (cntLeft !== 0) {
        this.disabledText = tmpl(this.T.client.disabledInterestsDesc, plural(cntLeft, this.T.client.interestsPlural));
        if (cntLeft > 0) {
          this.alert.info(tmpl(this.T.client.disabledInterestsDesc, plural(cntLeft, this.T.client.interestsPlural)));
        } else {
          this.alert.info(this.T.client.disabledMoreInterestsDesc);
        }
        return false;
      }
      if (this.currentQuestionIdx !== 3) {
        this.currentQuestionIdx++;
        return false;
      }
    } else if (this.group === 2 || this.group === 3) {
      const questions = this.questionRepo.interests().filter(q => q.group === this.group);
      // Считаем сумму выбранных ответов по всем вопросам группы
      const sum = this.getAnswers(questions).length;
      if (sum < 3) {
        this.alert.info(tmpl(this.T.client.disabledInterestsDesc, plural(3 - sum, this.T.client.interestsPlural)));
        return false;
      }
    } else if (this.group === 3.5) {
      if (this.currentQuestionIdx !== 3) {
        this.currentQuestionIdx++;
        return false;
      }
    } else if (this.group === 4) {
      if (this.currentFourthExtra < this.fourthExtraOptions.length) {
        this.selectFourthExtra(this.currentExtraAnswer);
        this.currentExtraAnswer = null;
        return false;
      }
      const answeredLines = [];
      for (const row of this.fourthExtraOptions) {
        for (const item of row) {
          if (this.newTest.test.hasOption(item.questionId, item.option) && answeredLines.indexOf(item.line) === -1) {
            answeredLines.push(item.line);
          }
        }
      }
      if (answeredLines.length < this.maxLine) {
        return false;
      }
    }

    window.scrollTo(0, 0);
    if (this.group === 1) {
      this.group = 3.5;
      this.currentQuestionIdx = 0;
    } else if (this.group === 3.5) {
      this.generateFourthExtraOptions();
      this.group = 4;
    } else if (this.group === 3) {
      this.group = 1;
    } else {
      this.currentQuestionIdx = 0;
      this.group++;
    }
  }

  protected getAnswers(questions: InterestsQuestion[]): string[] {
    const res = [];
    questions.forEach(q => {
      if (this.newTest.test.answers[q.id]) {
        res.push(...this.newTest.test.answers[q.id]);
      }
    });
    return res;
  }

  // Считаем сумму всех ответов по всем вопросам группы
  public totalSelectedCount(): number {
    const questions = this.questions.filter(q => q.group === this.group);
    return questions.map(q => this.newTest.test.answers[q.id] ? this.newTest.test.answers[q.id].length : 0, 0).reduce((a, b) => a + b);
  }

  private generateFourthExtraOptions() {
    let id = 0;
    this.fourthExtraOptions = [];
    const questions = this.questions.filter(q => q.group === 1);
    const newQuestions = this.questions.filter(q => q.group === 4);
    const answers = questions.map(q => this.newTest.test.answers[q.id]);
    this.maxLine = Math.max(...answers.map(a => a ? a.length : 0));
    for (let i = 0; i < this.maxLine; i++) {
      const row = [];
      for (let j = 0; j < questions.length; j++) {
        const question = questions[j];
        const newQuestion = newQuestions[j];
        if (this.newTest.test.answers[question.id] && this.newTest.test.answers[question.id][i]) {
          row.push({line: i, questionId: newQuestion.id, option: this.newTest.test.answers[question.id][i], id});
        } else {
          row.push({line: i, questionId: newQuestion.id, option: null, id});
        }
        id++;
      }
      this.fourthExtraOptions.push(row);
    }
    this.currentFourthExtra = 0;
    this.startThinkTime = (new Date()).getTime();
  }

  selectFourthExtra(option: FourthExtraOptionInterface) {
    const sameLine = this.fourthExtraOptions[option.line];
    sameLine.forEach(o => {
      this.newTest.test.removeOption(o.questionId, o.option);
    });
    this.newTest.test.addOption(option.questionId, option.option);

    const qId = 20000 + this.newTest.test.answerTimes.length;
    // Записываем время ответа
    this.newTest.test.answerTimes.push({
      questionId: qId,
      testGroup: 'interests',
      startTime: this.startThinkTime,
      endTime: (new Date()).getTime(),
    });
    this.startThinkTime = (new Date()).getTime();

    sameLine.forEach(o => {
      this.newTest.test.focusTimes.push({
        testGroup: 'interests',
        questionId: qId,
        answer: o.option,
        time: this.hoverTimes[o.option] || 0,
      });
    });
    this.hoverTimes = {};
    this.currentFourthExtra++;
    if (this.currentFourthExtra === this.fourthExtraOptions.length) {
      this.nextState.emit(this.newTest);
    }
  }

  startHover() {
    this.startHoverTime = (new Date()).getTime();
  }

  stopHover(option: FourthExtraOptionInterface) {
    if (!this.hoverTimes[option.option]) {
      this.hoverTimes[option.option] = 0;
    }
    this.hoverTimes[option.option] += (new Date()).getTime() - this.startHoverTime;
    this.startHoverTime = null;
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const num = this.doc.documentElement.scrollTop;
    if (num > 180) {
      this.fixedMenu = true;
    } else if (this.fixedMenu && num < 180) {
      this.fixedMenu = false;
    }
  }

  // Хак для быстрого заполнения тестов - Ctrl+Shift+Alt+A
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'KeyA' && event.altKey && event.ctrlKey && event.shiftKey && this.group === 1) {
      this.questions.filter(q => q.group === 1).forEach(q => {
        this.newTest.test.answers[q.id] = [...q.options.slice(0, 10)];
      });
      this.group = 3.5;
    }
  }
}
