import {ClaimQuestion, claimsQuestions} from '@common/types/questions/claims';
import {InterestsQuestion, interestsQuestions} from '@common/types/questions/interests';
import {StrategyQuestion, strategyQuestions} from '@common/types/questions/strategy';
import {MotivationQuestion, motivationQuestions} from '@common/types/questions/motivation';
import {Test} from '@common/types/test';
import {Result} from '@common/types/result';
import {QuestionInterface} from '@common/types/questions/questionInterface';
import {Indicator} from '@common/types/questions/indicators';

export class QuestionRepo {

  public claims(): ClaimQuestion[] {
    return claimsQuestions;
  }

  public interests(): InterestsQuestion[] {
    return interestsQuestions;
  }

  public strategy(): StrategyQuestion[] {
    return strategyQuestions;
  }

  public motivation(): MotivationQuestion[] {
    return motivationQuestions;
  }

  public all(): QuestionInterface[] {
    const claims: QuestionInterface[] = this.claims();
    const interests: QuestionInterface[] = this.interests();
    const strategy: QuestionInterface[] = this.strategy();
    const motivation: QuestionInterface[] = this.motivation();
    return [...claims, ...interests, ...strategy, ...motivation];
  }

  public fillResult(test: Test) {
    test.result = new Result();
    const all = this.all();
    all.forEach(question => {
      if (test.answers[question.id]) {
        question.fillResult(test.result, test.answers[question.id]);
      }
    });
    if (test.extraAnswers) {
      Object.keys(test.extraAnswers).forEach(key => test.result.setExtraWeight(key as Indicator, test.extraAnswers[key]));
    }
    test.result.sort();
  }
}
