import {ClaimIndicator} from './indicators';
import {QuestionInterface} from './questionInterface';
import {Result} from '@common/types/result';
import { Lang } from '@common/lang';

export class ClaimQuestion implements QuestionInterface {
  id: number;
  text: string;
  key: ClaimIndicator;

  constructor(data: Partial<ClaimQuestion>) {
    Object.assign(this, data);
  }

  fillResult(result: Result, answer: any) {
    if (answer === 1) {
      result.addWeight(this.key);
    }
  }
}

const T = Lang.getText();

export const claimsQuestions: ClaimQuestion[] = [
  new ClaimQuestion({ id: 10003, key: 'achievement', text: T.claims.question_10003}),
  new ClaimQuestion({ id: 10002, key: 'power',       text: T.claims.question_10002}),
  new ClaimQuestion({ id: 10004, key: 'achievement', text: T.claims.question_10004}),
  new ClaimQuestion({ id: 10005, key: 'power',       text: T.claims.question_10005}),
  new ClaimQuestion({ id: 10006, key: 'involvement', text: T.claims.question_10006}),
  new ClaimQuestion({ id: 10001, key: 'avoid',       text: T.claims.question_10001}),
  new ClaimQuestion({ id: 10007, key: 'avoid',       text: T.claims.question_10007}),
  new ClaimQuestion({ id: 10008, key: 'avoid',       text: T.claims.question_10008}),
  new ClaimQuestion({ id: 10009, key: 'power',       text: T.claims.question_10009}),
  new ClaimQuestion({ id: 10011, key: 'achievement', text: T.claims.question_10011}),
  new ClaimQuestion({ id: 10012, key: 'involvement', text: T.claims.question_10012}),
  new ClaimQuestion({ id: 10013, key: 'avoid',       text: T.claims.question_10013}),
  new ClaimQuestion({ id: 10014, key: 'involvement', text: T.claims.question_10014}),
  new ClaimQuestion({ id: 10015, key: 'achievement', text: T.claims.question_10015}),
  new ClaimQuestion({ id: 10016, key: 'avoid',       text: T.claims.question_10016}),
  new ClaimQuestion({ id: 10017, key: 'power',       text: T.claims.question_10017}),
  new ClaimQuestion({ id: 10018, key: 'involvement', text: T.claims.question_10018}),
  new ClaimQuestion({ id: 10019, key: 'power',       text: T.claims.question_10019}),
  new ClaimQuestion({ id: 10020, key: 'achievement', text: T.claims.question_10020}),
  new ClaimQuestion({ id: 10021, key: 'avoid',       text: T.claims.question_10021}),
  new ClaimQuestion({ id: 10022, key: 'power',       text: T.claims.question_10022}),
  new ClaimQuestion({ id: 10023, key: 'achievement', text: T.claims.question_10023}),
  new ClaimQuestion({ id: 10024, key: 'avoid',       text: T.claims.question_10024}),
  new ClaimQuestion({ id: 10025, key: 'involvement', text: T.claims.question_10025}),
  new ClaimQuestion({ id: 10026, key: 'power',       text: T.claims.question_10026}),
  new ClaimQuestion({ id: 10027, key: 'involvement', text: T.claims.question_10027}),
  new ClaimQuestion({ id: 10028, key: 'achievement', text: T.claims.question_10028}),
  new ClaimQuestion({ id: 10029, key: 'power',       text: T.claims.question_10029}),
  new ClaimQuestion({ id: 10031, key: 'involvement', text: T.claims.question_10031}),
  new ClaimQuestion({ id: 10032, key: 'achievement', text: T.claims.question_10032}),
  new ClaimQuestion({ id: 10033, key: 'involvement', text: T.claims.question_10033}),
  new ClaimQuestion({ id: 10034, key: 'avoid',       text: T.claims.question_10034}),
  new ClaimQuestion({ id: 10035, key: 'involvement', text: T.claims.question_10035}),
  new ClaimQuestion({ id: 10036, key: 'power',       text: T.claims.question_10036}),
  new ClaimQuestion({ id: 10037, key: 'achievement', text: T.claims.question_10037}),
  new ClaimQuestion({ id: 10038, key: 'power',       text: T.claims.question_10038}),
  new ClaimQuestion({ id: 10039, key: 'avoid',       text: T.claims.question_10039}),
  new ClaimQuestion({ id: 10040, key: 'involvement', text: T.claims.question_10040}),
  new ClaimQuestion({ id: 10041, key: 'avoid',       text: T.claims.question_10041}),
  new ClaimQuestion({ id: 10042, key: 'power',       text: T.claims.question_10042}),
  new ClaimQuestion({ id: 10044, key: 'power',       text: T.claims.question_10044}),
  new ClaimQuestion({ id: 10045, key: 'involvement', text: T.claims.question_10045}),
  new ClaimQuestion({ id: 10046, key: 'avoid',       text: T.claims.question_10046}),
  new ClaimQuestion({ id: 10047, key: 'achievement', text: T.claims.question_10047}),
  new ClaimQuestion({ id: 10048, key: 'involvement', text: T.claims.question_10048}),
  new ClaimQuestion({ id: 10049, key: 'power',       text: T.claims.question_10049}),
  new ClaimQuestion({ id: 10050, key: 'achievement', text: T.claims.question_10050}),
  new ClaimQuestion({ id: 10051, key: 'involvement', text: T.claims.question_10051}),
  new ClaimQuestion({ id: 10052, key: 'avoid',       text: T.claims.question_10052}),
  new ClaimQuestion({ id: 10053, key: 'power',       text: T.claims.question_10053}),
  new ClaimQuestion({ id: 10054, key: 'achievement', text: T.claims.question_10054}),
  new ClaimQuestion({ id: 10055, key: 'achievement', text: T.claims.question_10055}),
  new ClaimQuestion({ id: 10056, key: 'avoid',       text: T.claims.question_10056}),
  new ClaimQuestion({ id: 10057, key: 'power',       text: T.claims.question_10057}),
  new ClaimQuestion({ id: 10058, key: 'involvement', text: T.claims.question_10058}),
  new ClaimQuestion({ id: 10059, key: 'achievement', text: T.claims.question_10059}),
  new ClaimQuestion({ id: 10060, key: 'involvement', text: T.claims.question_10060}),
  new ClaimQuestion({ id: 10061, key: 'avoid',       text: T.claims.question_10061}),
  new ClaimQuestion({ id: 10062, key: 'achievement', text: T.claims.question_10062}),
  new ClaimQuestion({ id: 10063, key: 'power',       text: T.claims.question_10063}),
  new ClaimQuestion({ id: 10064, key: 'involvement', text: T.claims.question_10064}),
  new ClaimQuestion({ id: 10065, key: 'achievement', text: T.claims.question_10065}),
  new ClaimQuestion({ id: 10030, key: 'avoid',       text: T.claims.question_10030}),
  new ClaimQuestion({ id: 10066, key: 'avoid',       text: T.claims.question_10066}),
  new ClaimQuestion({ id: 10067, key: 'power',       text: T.claims.question_10067}),
  new ClaimQuestion({ id: 10069, key: 'achievement', text: T.claims.question_10069}),
  new ClaimQuestion({ id: 10070, key: 'avoid',       text: T.claims.question_10070}),
  new ClaimQuestion({ id: 10071, key: 'power',       text: T.claims.question_10071}),
  new ClaimQuestion({ id: 10072, key: 'avoid',       text: T.claims.question_10072}),
  new ClaimQuestion({ id: 10043, key: 'achievement', text: T.claims.question_10043}),
  new ClaimQuestion({ id: 10010, key: 'involvement', text: T.claims.question_10010}),
  new ClaimQuestion({ id: 10068, key: 'involvement', text: T.claims.question_10068}),
];
