import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Lang } from '@common/lang';
export class ProfileComponent {
    constructor() {
        this.next = new EventEmitter();
        this.T = Lang.getText();
        this.errors = {};
    }
    ngOnInit() {
    }
    validateUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.errors = {};
            if (!this.newTest.worker.firstName) {
                this.errors['firstName'] = this.T.pages.errorNameNotFilled;
            }
            if (!this.newTest.worker.secondName) {
                this.errors['secondName'] = this.T.pages.errorSurnameNotFilled;
            }
            if (!this.newTest.worker.birthday) {
                this.errors['birthday'] = this.T.pages.errorBirthdayNotFilled;
            }
            if (!this.newTest.worker.getBirthdayDate()) {
                this.errors['birthday'] = this.T.pages.errorBirthdayIncorrect;
            }
            if (this.newTest.worker.getBirthdayDate() > new Date()) {
                this.errors['birthday'] = this.T.pages.errorBirthdayIncorrect;
            }
            if (Object.keys(this.errors).length > 0) {
                return;
            }
            this.next.emit();
        });
    }
}
