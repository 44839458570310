import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
export class StartPageComponent {
    constructor(alert, dotEnv) {
        this.alert = alert;
        this.dotEnv = dotEnv;
        this.agreed = false;
        this.env = null;
        this.isLoading = true;
        this.onAgree = new EventEmitter();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.env = yield this.dotEnv.get();
            this.isLoading = true;
        });
    }
    next() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.agreed) {
                yield this.alert.alert('Для продолжения Вы должны дать согласие на обработку персональных данных');
                return;
            }
            this.onAgree.emit();
        });
    }
}
