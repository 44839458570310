<div class="layout">
  <div class="layout_header">
    <div class="content">
      <div class="header_row">
        <img src="/assets/icons/logo.png"/>
        <div class="version">v {{ version }}</div>
      </div>
    </div>
  </div>
  <div class="f-row f-grow backdrop">
    <div class="layout-content content">
      <div *ngIf="showBreadcrumbs" class="f-row f-align-center f-justify-between breadcurmbs-area">
        <div class="f-row text-14 breadcurmbs">
          <span
            *ngFor="let item of breadcrumbsList"
            class="breadcurmb"
            [ngClass]="{active: item.type === activeBreadcrumb}"
          >{{ item.title }}</span>
        </div>
        <div class="f-row question">
          <div *ngIf="questionNumber !== null && maxQuestionNumber !== null" class="text-14">
            Вопрос {{ questionNumber + 1 }} / {{ maxQuestionNumber }}
          </div>
        </div>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="layout_footer">
    <div class="footer-title content">
    </div>
  </div>
</div>
