import * as tslib_1 from "tslib";
import { parseDotEnv } from '@common/utils/dotenv/parseDotEnv';
export class DotEnvService {
    constructor(http) {
        this.http = http;
    }
    get() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.http.get('/assets/public.env', { responseType: 'text' }).toPromise();
            return parseDotEnv(data);
        });
    }
}
