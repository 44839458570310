export function checkSupportedBrowser() {
  function getBrowserNameAndVersion() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null)   {return {name: 'Opera', version: tem[1]}; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: Number(M[1])
    };
  }

  const browser = getBrowserNameAndVersion();
  return browser.name === 'Chrome' && browser.version >= 83 ||
    browser.name === 'Firefox' && browser.version >= 78 ||
    browser.name === 'Safari' && browser.version >= 10;
}
export function printUnsupportedBrowserText() {
  const body = document.getElementsByTagName('BODY')[0];
  (body as HTMLElement).style.margin = '25px auto 0 25px';
  body.innerHTML = 'К сожалению, Ваш браузер не поддерживается.<br/>' +
    'Для прохождения тестирования, пожалуйста установите последнюю версию ' +
    '<a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>, ' +
    '<a href="https://www.mozilla.org/ru/firefox/new/" target="_blank">Firefox</a> или ' +
    '<a href="https://support.apple.com/ru-ru/HT204416" target="_blank">Safari</a>';
}
