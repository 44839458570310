import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Lang } from '../../../../../common/lang';
import { tmpl } from '@common/utils/text/tmlp';
export class ExtraQuestionsTestComponent {
    constructor() {
        this.nextState = new EventEmitter();
        this.T = Lang.getText();
        this.sets = [];
        this.currentSetIndex = 0;
        this.currentMatch = null;
        this.history = [];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < this.newTest.test.result.resultArray.length; i++) {
                const row = this.newTest.test.result.resultArray[i];
                let currentSet = { items: [], isSolved: false };
                for (let j = 0; j <= row.length - 1; j++) {
                    if (currentSet.items.length === 0) {
                        currentSet.items.push(row[j]);
                        continue;
                    }
                    const prev = currentSet.items[currentSet.items.length - 1];
                    if (prev.weight === row[j].weight) {
                        currentSet.items.push(row[j]);
                    }
                    else {
                        if (currentSet.items.length > 1) {
                            this.sets.push(currentSet);
                        }
                        currentSet = { items: [row[j]], isSolved: false };
                    }
                }
                if (currentSet.items.length > 1) {
                    this.sets.push(currentSet);
                }
            }
            this.versus1 = this.sets[this.currentSetIndex].items[0];
            this.versus2 = this.sets[this.currentSetIndex].items[1];
        });
    }
    getQuestsion() {
        return tmpl(this.T.client.extraQuestion, this.getTitle(this.versus1), this.getTitle(this.versus2));
    }
    getTitle(item, toLower = false) {
        const title = this.T.extraQuestions[item.name];
        return toLower && title.indexOf('Ваш') !== 0 ? title.toLowerCase() : title;
    }
    answer() {
        if (this.currentMatch === 1) {
            this.addMatch(this.versus1, this.versus2);
        }
        else if (this.currentMatch === 2) {
            this.addMatch(this.versus1, this.versus2);
        }
        else {
            return;
        }
        this.currentMatch = null;
    }
    /**
     * Сначала мы ищем самую важную характеристику, присваиваем ей extraWeight, затем повторяем это с остальными
     */
    addMatch(winner, loser) {
        this.history.push({ win: winner.name, lose: loser.name });
        let set = this.sets[this.currentSetIndex];
        set.winner = winner.name;
        if (!set.losers) {
            set.losers = [];
        }
        set.losers.push(loser.name);
        const restOfSet = set.items.filter(item => item.extraWeight === 0);
        if (set.losers.length === restOfSet.length - 1) {
            set.items.find(item => item.name === winner.name).extraWeight = set.losers.length;
            let rest = set.items.filter(item => item.extraWeight === 0);
            if (rest.length === 1) {
                if (this.currentSetIndex === this.sets.length - 1) {
                    this.done();
                    return;
                }
                else {
                    this.currentSetIndex++;
                    set = this.sets[this.currentSetIndex];
                    rest = set.items.filter(item => item.extraWeight === 0);
                }
            }
            set.losers = [];
            set.winner = null;
            this.versus1 = rest[0];
            this.versus2 = rest[1];
            this.checkHistory();
            return;
        }
        this.versus1 = winner;
        this.versus2 = set.items.filter(item => item.extraWeight === 0 && set.losers.indexOf(item.name) === -1 && item.name !== winner.name)[0];
        this.checkHistory();
    }
    checkHistory() {
        if (this.history.find(h => h.win === this.versus1.name && h.lose === this.versus2.name)) {
            this.addMatch(this.versus1, this.versus2);
        }
        if (this.history.find(h => h.win === this.versus2.name && h.lose === this.versus1.name)) {
            this.addMatch(this.versus2, this.versus1);
        }
    }
    done() {
        this.newTest.test.extraAnswers = {};
        this.sets.forEach(set => set.items.forEach(item => this.newTest.test.extraAnswers[item.name] = item.extraWeight));
        this.nextState.emit();
    }
}
