/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../components/layout/layout.component.ngfactory";
import * as i2 from "../components/layout/layout.component";
import * as i3 from "../pages/start-page/start-page.component.ngfactory";
import * as i4 from "../pages/start-page/start-page.component";
import * as i5 from "../../../common/ng/alert.service";
import * as i6 from "../../../common/ng/dot-env/dot-env.service";
import * as i7 from "../pages/test-page/test-page.component.ngfactory";
import * as i8 from "../pages/test-page/test-page.component";
import * as i9 from "../services/question-repo/questionRepo";
import * as i10 from "../services/onlineApi/online-api.service";
import * as i11 from "@angular/common";
import * as i12 from "../../../node_modules/ng-snotify/ng-snotify.ngfactory";
import * as i13 from "ng-snotify";
import * as i14 from "./app.component";
import * as i15 from "@angular/router";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, i1.View_LayoutComponent_0, i1.RenderType_LayoutComponent)), i0.ɵdid(1, 638976, null, 0, i2.LayoutComponent, [], { showBreadcrumbs: [0, "showBreadcrumbs"] }, null)], function (_ck, _v) { var currVal_0 = false; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-start-testing-page", [], null, [[null, "onAgree"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAgree" === en)) {
        _co.setStage("test");
        var pd_0 = ((_co.preventClose = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_StartPageComponent_0, i3.RenderType_StartPageComponent)), i0.ɵdid(1, 114688, null, 0, i4.StartPageComponent, [i5.AlertService, i6.DotEnvService], null, { onAgree: "onAgree" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-test-page", [], null, [[null, "resultSent"], [null, "nextWorker"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resultSent" === en)) {
        var pd_0 = ((_co.preventClose = false) !== false);
        ad = (pd_0 && ad);
    } if (("nextWorker" === en)) {
        var pd_1 = (_co.start() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_TestPageComponent_0, i7.RenderType_TestPageComponent)), i0.ɵdid(1, 114688, null, 0, i8.TestPageComponent, [i9.QuestionRepo, i10.OnlineApiService], null, { resultSent: "resultSent", nextWorker: "nextWorker" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(3, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i0.ɵdid(5, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "ng-snotify", [], null, null, null, i12.View_ɵa_0, i12.RenderType_ɵa)), i0.ɵdid(7, 245760, null, 0, i13.ɵa, [i13.ɵb], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.stage === "loading"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.stage === "start"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.stage === "test"); _ck(_v, 5, 0, currVal_2); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).unloadNotification($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i14.AppComponent, [i6.DotEnvService, i5.AlertService, i10.OnlineApiService, i15.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
