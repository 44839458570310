import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ClaimQuestion } from '@common/types/questions/claims';
import { QuestionRepo } from '../../../services/question-repo/questionRepo';
import { TestFile } from '@common/types/testFile';

@Component({
  selector: 'app-claims-test',
  templateUrl: './claims-test.component.html',
  styleUrls: ['./claims-test.component.scss']
})
export class ClaimsTestComponent implements OnInit {

  @Input() newTest: TestFile;
  @Output() nextQuestion = new EventEmitter<number>();
  @Output() nextState = new EventEmitter<any>();

  public questions: ClaimQuestion[] = [];
  public currentQuestion: number;
  public currentAnswer = null;

  private startThinkTime: number;
  private yesHover = 0;
  private noHover = 0;
  private yesHoverStart: number;
  private noHoverStart: number;

  constructor(
    private questionRepo: QuestionRepo,
  ) {
  }

  public ngOnInit() {
    this.questions = this.questionRepo.claims();
    this.currentQuestion = 0;
    this.startThinkTime = (new Date()).getTime();
  }

  public getCurrentQuestion(): ClaimQuestion {
    return this.questions[this.currentQuestion];
  }

  public answer(yes: boolean) {
    if (yes === null) {
      return;
    }
    this.currentAnswer = null;
    if (yes) {
      this.newTest.test.answers[this.getCurrentQuestion().id] = 1;
    } else {
      this.newTest.test.answers[this.getCurrentQuestion().id] = -1;
    }

    // Добавляем время ответа на вопрос
    this.newTest.test.answerTimes.push({
      questionId: this.getCurrentQuestion().id,
      testGroup: 'claims',
      startTime: this.startThinkTime,
      endTime: (new Date()).getTime(),
    });
    this.startThinkTime = (new Date()).getTime();

    // Записываем время фокуса
    this.stopHover(yes);
    this.startHover(yes);
    this.newTest.test.focusTimes.push({
      testGroup: 'claims',
      questionId: this.getCurrentQuestion().id,
      answer: 1,
      time: this.yesHover,
    });
    this.newTest.test.focusTimes.push({
      testGroup: 'claims',
      questionId: this.getCurrentQuestion().id,
      answer: -1,
      time: this.noHover,
    });
    this.yesHover = 0;
    this.noHover = 0;

    this.currentQuestion++;
    if (this.currentQuestion === this.questions.length) {
      this.nextState.emit(this.newTest);
    } else {
      this.nextQuestion.emit(this.currentQuestion);
    }
  }

  public startHover(yes: boolean) {
    if (yes) {
      this.yesHoverStart = (new Date()).getTime();
    } else {
      this.noHoverStart = (new Date()).getTime();
    }
  }

  public setAnswer(value: number) {
    console.log('set!');
    this.currentAnswer = Boolean(value);
  }

  public stopHover(yes: boolean) {
    if (yes) {
      this.yesHover += (new Date()).getTime() - this.yesHoverStart;
      this.yesHoverStart = null;
    } else {
      this.noHover += (new Date()).getTime() - this.noHoverStart;
      this.noHoverStart = null;
    }
  }

  // Хак для быстрого заполнения тестов - Ctrl+Shift+Alt+A
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'KeyA' && event.altKey && event.ctrlKey && event.shiftKey) {
      for (let i = this.currentQuestion; i < this.questions.length; i++) {
        this.answer(false);
      }
    }
  }
}
