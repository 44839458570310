<div>
  <div class="text-18 f-row f-justify-center v-gap-32 text-center">
    <b>Перед началом тестирования введите свои данные</b>
  </div>
  <div class="form">
    <div class="form-row v-gap-32">
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Фамилия<span class="req">*</span></div>
        <input
          type="text"
          tabindex="1"
          [(ngModel)]="newTest.worker.secondName"
          (ngModelChange)="errors.secondName = null"
        />
        <app-error-text [error]="errors.secondName"></app-error-text>
      </div>
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Имя<span class="req">*</span></div>
        <input
          type="text"
          tabindex="2"
          [(ngModel)]="newTest.worker.firstName"
          (ngModelChange)="errors.firstName = null"
        />
        <app-error-text [error]="errors.firstName"></app-error-text>
      </div>
    </div>
    <div class="form-row v-gap-32">
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Отчество</div>
        <input
          type="text"
          tabindex="3"
          [(ngModel)]="newTest.worker.middleName"
          (ngModelChange)="errors.middleName = null"
        />
        <app-error-text [error]="errors.middleName"></app-error-text>
      </div>
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Пол</div>
        <select
          tabindex="4"
          [(ngModel)]="newTest.worker.gender"
          (ngModelChange)="errors.gender = null"
        >
          <option [ngValue]="1">Мужской</option>
          <option [ngValue]="0">Женский</option>
        </select>
        <app-error-text [error]="errors.gender"></app-error-text>
      </div>
    </div>
    <div class="form-row v-gap-32">
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Дата рождения<span class="req">*</span></div>
        <input
          type="date"
          tabindex="5"
          [(ngModel)]="newTest.worker.birthday"
          (ngModelChange)="errors.birthday = null"
        />
        <app-error-text [error]="errors.birthday"></app-error-text>
      </div>
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Образование</div>
        <select
          tabindex="6"
          [(ngModel)]="newTest.worker.education"
          (ngModelChange)="errors.education = null"
        >
          <option [ngValue]="'Без образования'">Без образования</option>
          <option [ngValue]="'Среднее'">Среднее</option>
          <option [ngValue]="'Среднее/специальное'">Среднее/специальное</option>
          <option [ngValue]="'Неполное высшее'">Неполное высшее</option>
          <option [ngValue]="'Высшее'">Высшее</option>
          <option [ngValue]="'Ученая степень'">Ученая степень</option>
        </select>
        <app-error-text [error]="errors.education"></app-error-text>
      </div>
    </div>
    <div class="form-row v-gap-32">
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Телефон</div>
        <input
          type="text"
          tabindex="7"
          [(ngModel)]="newTest.worker.phone"
          (ngModelChange)="errors.phone = null"
        />
        <app-error-text [error]="errors.phone"></app-error-text>
      </div>
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Электронная почта</div>
        <input
          type="text"
          tabindex="8"
          [(ngModel)]="newTest.worker.email"
          (ngModelChange)="errors.email = null"
        />
        <app-error-text [error]="errors.email"></app-error-text>
      </div>
    </div>
    <div class="form-row v-gap-48">
      <div class="f-col f-grow f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Адрес проживания</div>
        <input
          type="text"
          tabindex="9"
          [(ngModel)]="newTest.worker.address"
          (ngModelChange)="errors.address = null"
        />
        <app-error-text [error]="errors.address"></app-error-text>
      </div>
    </div>
    <hr class="v-gap-32"/>
    <div class="form-row v-gap-32">
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Организация</div>
        <input
          type="text"
          tabindex="10"
          [(ngModel)]="newTest.worker.organization"
          (ngModelChange)="errors.organization = null"
        />
        <app-error-text [error]="errors.organization"></app-error-text>
      </div>
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Подразделение</div>
        <input
          type="text"
          tabindex="11"
          [(ngModel)]="newTest.worker.department"
          (ngModelChange)="errors.department = null"
        />
        <app-error-text [error]="errors.department"></app-error-text>
      </div>
    </div>
    <div class="form-row v-gap-64">
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Должность</div>
        <input type="text" [(ngModel)]="newTest.worker.post" tabindex="12"/>
        <app-error-text [error]="errors.post"></app-error-text>
      </div>
      <div class="f-col f-half f-align-stretch gap-32">
        <div class="text-18 v-gap-16 text-center">Общий стаж работы</div>
        <input type="text" [(ngModel)]="newTest.worker.experience" tabindex="13"/>
        <app-error-text [error]="errors.experience"></app-error-text>
      </div>
    </div>
    <div class="f-row f-grow f-justify-center">
      <button class="btn profile-btn" (click)="validateUser()">Начать тестирование</button>
    </div>
  </div>
</div>
