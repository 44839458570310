<div *ngIf="group == 1">
  <div [class.fixed]="fixedMenu">
    <div class="text-center v-gap-16" [class.sticky]="fixedMenu" *ngIf="group === 1">
      <h3 style="margin: 0">{{ T.client.interests.toUpperCase() }}. {{ currentQuestion.text }}</h3>
      <p [innerHtml]="T.client.chooseInterestsDesc | safeHtml"></p>
    </div>
    <div class="f-row f-grow f-justify-center v-gap-16">
    <div>
      <span class="bold">Выбрано</span><b> {{ newTest.test.answers[currentQuestion.id] ? newTest.test.answers[currentQuestion.id].length : 0 }}</b>
      <span *ngIf="group === 1"> из <b> 10 </b></span>
    </div>
    </div>
  </div>
  <div class="questions-grid">
    <div class="questions-option"
         *ngFor="let option of currentQuestion.options; trackBy: trackByFn">
      <div>
        <div class="checkbox">
          <label class="checkbox hover-input option">
            <input
              type="checkbox"
              value="optionA"
              [checked]="newTest.test.hasOption(currentQuestion.id, option)"
              (change)="newTest.test.toggleOption(currentQuestion.id, option)"
            />
            <span>{{ option }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="group === 2 || group === 3">
  <div [class.fixed]="fixedMenu">
    <div class="text-center v-gap-16" [class.sticky]="fixedMenu" *ngIf="group === 2">
      <h3>{{ T.client.virtualInterests }}</h3>
      <p [innerHtml]="T.client.chooseVirtualInterestsDesc | safeHtml"></p>
    </div>

    <div class="text-center v-gap-16" [class.sticky]="fixedMenu" *ngIf="group === 3">
      <h3>{{ T.client.antiInterests }}</h3>
      <p [innerHtml]="T.client.antiInterestsDesc | safeHtml"></p>
    </div>

    <div class="text-center v-gap-16">
      <p><b>{{ T.client.selected }} {{ totalSelectedCount() }}</b></p>
    </div>
  </div>

  <div *ngFor="let question of currentQuestions" class="v-gap-32">
    <div class="f-row f-grow f-justify-center v-gap-16"><b>{{ question.text }}</b></div>
    <div class="questions-grid">
      <div class="questions-option" *ngFor="let option of question.options; trackBy: trackByFn">
        <div>
          <div class="checkbox">
            <label class="checkbox hover-input option">
              <input
                type="checkbox"
                value="optionA"
                [checked]="newTest.test.hasOption(question.id, option)"
                (change)="newTest.test.toggleOption(question.id, option)"
              />
              <span>{{ option }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="group === 3.5" class="group3_5">

  <div class="text-center v-gap-32" *ngIf="group === 3.5">
    <h3>{{ T.client.interests }}. {{ currentQuestion.text }}</h3>
    <p [innerHtml]="T.client.sortInterests | safeHtml"></p>
  </div>

  <div
    [dragula]="'q' + currentQuestion.id"
    [(dragulaModel)]="newTest.test.answers[currentQuestion.id]"
  >
    <div
      class="answer-group v-gap-16"
      *ngFor="let answer of newTest.test.answers[currentQuestion.id]; let i = index"
    >
      <div class="arraows-icon">
        <img src="/assets/icons/arrows.svg" alt="" />
      </div>
      <span>{{ answer }}</span>
      <div></div>
    </div>
  </div>
</div>


<div *ngIf="group === 4">

  <div class="text-center v-gap-32" *ngIf="group === 4">
    <h4 [innerHtml]="T.client.oneOfFourDesc | safeHtml"></h4>
  </div>
  <div class="f-col f-grow f-align-center f-justify-center">
    <label class="radio hover-input extra-answer v-gap-8" *ngFor="let option of fourthExtraOptions[currentFourthExtra]">
      <input type="radio" (mouseenter)="startHover()" (mouseleave)="stopHover(option)" [(ngModel)]="currentExtraAnswer" [value]="option">
      <span>{{ option.option }}</span>
    </label>
  </div>
</div>

<div class="v-gap-64"></div>

<div class="f-row f-grow f-justify-center">
  <button class="btn" (click)="nextStep()">{{ T.client.next }}</button>
</div>
