/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "breadcurmb"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "breadcurmb"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit.type === _co.activeBreadcrumb)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
function View_LayoutComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" \u0412\u043E\u043F\u0440\u043E\u0441 ", " / ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.questionNumber + 1); var currVal_1 = _co.maxQuestionNumber; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "f-row f-align-center f-justify-between breadcurmbs-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "f-row text-14 breadcurmbs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "f-row question"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbsList; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.questionNumber !== null) && (_co.maxQuestionNumber !== null)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "layout_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "header_row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "/assets/icons/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "version"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["v ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "f-row f-grow backdrop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "layout-content content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "layout_footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "footer-title content"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showBreadcrumbs; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 6, 0, currVal_0); }); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 638976, null, 0, i3.LayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i3.LayoutComponent, View_LayoutComponent_Host_0, { activeBreadcrumb: "activeBreadcrumb", questionNumber: "questionNumber", maxQuestionNumber: "maxQuestionNumber", showBreadcrumbs: "showBreadcrumbs" }, {}, ["*"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
