import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app/app.component';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { LayoutComponent } from './components/layout/layout.component';
import { DotEnvService } from '@common/ng/dot-env/dot-env.service';
import { FormsModule } from '@angular/forms';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { ClaimsTestComponent } from './pages/test-page/claims-test/claims-test.component';
import { QuestionRepo } from './services/question-repo/questionRepo';
import { ProfileComponent } from './pages/test-page/profile/profile.component';
import { ErrorTextComponent } from './components/error-text/error-text.component';
import { InterestsTestComponent } from './pages/test-page/interests-test/interests-test.component';
import { DragulaModule } from 'ng2-dragula';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StrategyTestComponent } from './pages/test-page/strategy-test/strategy-test.component';
import { MotivationTestComponent } from './pages/test-page/motivation-test/motivation-test.component';
import { ExtraQuestionsTestComponent } from './pages/test-page/extra-questions-test/extra-questions-test.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {OnlineApiService} from './services/onlineApi/online-api.service';
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    AppComponent,
    StartPageComponent,
    LayoutComponent,
    TestPageComponent,
    ClaimsTestComponent,
    ProfileComponent,
    ErrorTextComponent,
    InterestsTestComponent,
    SafeHtmlPipe,
    StrategyTestComponent,
    MotivationTestComponent,
    ExtraQuestionsTestComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    Nl2BrPipeModule,
    DragulaModule.forRoot(),
    SnotifyModule,
    RouterModule.forRoot([])
  ],
  providers: [
    DotEnvService,
    QuestionRepo,
    SnotifyService,
    OnlineApiService,
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
  ],
  bootstrap: [AppComponent]
})
export class IndexModule {
}
