import { Test } from './test';
import { Worker } from './worker';

export class TestFile {

  public test: Test;

  public worker: Worker;

  public lang: string;

  public constructor() {
    this.test = new Test();
    this.worker = new Worker();
  }
}
