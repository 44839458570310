import {Injectable} from '@angular/core';
import {SnotifyPosition, SnotifyService, SnotifyButton, ToastDefaults} from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    protected notify: SnotifyService,
  ) {
    this.notify.config = ToastDefaults;
  }

  public async alert(message: string, title = 'HR Lean', keepOpen = false) {
    this.notify.simple(message, title, {
      showProgressBar: false,
      bodyMaxLength: 10000,
      closeOnClick: !keepOpen,
      timeout: 0,
      icon: null,
      backdrop: 0.5,
      position: SnotifyPosition.centerCenter,
    });
  }

  public async html(html: string, title = 'HR Lean', keepOpen = false, buttons: SnotifyButton[] = null) {
    this.notify.html(`<div class="snotifyToast__title">${title}</div>${html}`, {
      showProgressBar: false,
      bodyMaxLength: 10000,
      closeOnClick: !keepOpen,
      timeout: 0,
      icon: null,
      backdrop: 0.5,
      position: SnotifyPosition.centerCenter,
      buttons: buttons,
    });
  }

  public async info(message, title = 'HR Lean') {
    this.notify.simple(message, title, {
      showProgressBar: false,
      bodyMaxLength: 10000,
      closeOnClick: true,
      timeout: 10000,
      icon: null,
      position: SnotifyPosition.rightBottom,
    });
  }

  public removeToast(toastId: number) {
    this.notify.remove(toastId);
  }
}
