import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionRepo } from '../../../services/question-repo/questionRepo';
import { StrategyQuestion } from '@common/types/questions/strategy';
import { Lang } from '../../../../../common/lang';
import { TestFile } from '@common/types/testFile';
import { tmpl } from '@common/utils/text/tmlp';

@Component({
  selector: 'app-strategy-test',
  templateUrl: './strategy-test.component.html',
  styleUrls: ['./strategy-test.component.scss']
})
export class StrategyTestComponent implements OnInit {

  @Input() newTest: TestFile;
  @Output() nextState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextQuestion = new EventEmitter<number>();

  public T = Lang.getText();

  public questions: StrategyQuestion[] = [];
  public currentQuestion: number;
  public currentAnswer: any;

  private startThinkTime: number;
  private startHoverTime: number;
  private hoverTimes = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  };

  constructor(
    private questionRepo: QuestionRepo,
  ) {}

  ngOnInit() {
    this.questions = this.questionRepo.strategy();
    this.currentQuestion = 0;
    this.startThinkTime = (new Date()).getTime();
  }

  public getCurrentQuestion(): StrategyQuestion {
    return this.questions[this.currentQuestion];
  }

  public getCurrentOptions(): { option: number, text: string }[] {
    const q = this.getCurrentQuestion();
    if (!q) {
      return [];
    }
    return Object.keys(q.options).map(key => ({ option: parseInt(key, 10), text: q.options[key] as string }));
  }

  selectAnswer(option: { option: number, text: string }) {
    if (this.currentAnswer === null) {
      return;
    }
    this.newTest.test.setAnswer(this.getCurrentQuestion().id, option.option);
    this.currentAnswer = null;

    // Залисываем время ответа
    this.newTest.test.answerTimes.push({
      testGroup: 'strategy',
      questionId: this.getCurrentQuestion().id,
      startTime: this.startThinkTime,
      endTime: (new Date()).getTime(),
    });
    this.startThinkTime = (new Date()).getTime();

    // Записываем время фокуса
    this.stopHover(option.option);
    this.startHover();
    Object.keys(this.hoverTimes).map(key => {
      this.newTest.test.focusTimes.push({
        testGroup: 'strategy',
        questionId: this.getCurrentQuestion().id,
        answer: key,
        time: this.hoverTimes[key] || 0,
        comment: tmpl(this.T.client.theAnswerIs, this.getCurrentQuestion().options[key]),
      });
    });
    this.hoverTimes = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    };

    this.currentQuestion++;
    this.nextQuestion.emit(this.currentQuestion);
    if (this.currentQuestion === this.questions.length) {
      this.nextState.emit(this.newTest);
    }
  }

  startHover() {
    this.startHoverTime = (new Date()).getTime();
  }

  stopHover(answer: number) {
    this.hoverTimes[answer] += (new Date()).getTime() - this.startHoverTime;
  }

  trackByFn(option) {
    return option.option;
  }
}
