import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
export class AppComponent {
    constructor(dotEnv, alert, api, route) {
        this.dotEnv = dotEnv;
        this.alert = alert;
        this.api = api;
        this.route = route;
        this.stage = 'loading';
        this.preventClose = false;
        this.subs = [];
        this.stopByError = false;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.config = yield this.dotEnv.get();
            this.subs.push(this.route.queryParams.subscribe((params) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (params.token) {
                    this.api.setToken(params.token);
                    yield this.start();
                }
                else {
                    this.stopByError = true;
                    yield this.alert.html(`Для доступа к тестированию, используйте ссылку в
            <a href="${this.config.MAIN_URL}" target="_blank">Личном кабинете</a>`, 'HR Lean', true);
                }
            })));
            yield this.api.sampleLog();
            // Проверяем каждые 30 сек, не "протухли" ли данные пользователя"
            setInterval(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!this.stopByError) {
                    yield this.check();
                }
            }), 30 * 1000);
        });
    }
    apiError(error, status = null) {
        this.stopByError = true;
        if (status === 0) {
            // TODO! Нужно дать возможность пользователю продолжить тестирование после восстановления соединения
            this.alert.html(`Пожалуйста, проверьте Ваше соединение с интернетом`, 'Соединение разорвано', true, [{ text: 'Проверить ещё раз', action: (toast) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        yield this.check();
                        this.alert.removeToast(toast.id);
                    }) }]);
        }
        else {
            this.alert.html(`<br/>${error}<br/><p>Пожалуйста, обратитесь к администратору или
        <a href=${this.config.MAIN_URL}/#contacts target="_blank">свяжитесь с нами</a></p>`, 'Ошибка доступа', true);
        }
    }
    check() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const profile = yield this.api.loadProfile((error, status) => tslib_1.__awaiter(this, void 0, void 0, function* () { return this.apiError(error, status); }));
            if (!profile) {
                return false;
            }
            if (profile.onlineTestsUsed >= profile.onlineTests) {
                this.apiError('У Вас закончились онлайн тестирования');
                return false;
            }
            if (!profile.notExpires && moment().diff(profile.onlineExpired) > 0) {
                this.apiError('Срок действия Вашей лицензии истек');
                return false;
            }
            return true;
        });
    }
    setStage(stage) {
        this.stage = stage;
        window.scrollTo({ top: 0 });
    }
    start() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.setStage('loading');
            if (yield this.check()) {
                this.setStage('start');
            }
        });
    }
    unloadNotification($event) {
        if (this.preventClose) {
            $event.returnValue = true;
        }
    }
    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
