import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestFile } from '@common/types/testFile';
import { ResultValueInterface } from '@common/types/result';
import { Lang } from '../../../../../common/lang';
import { tmpl } from '@common/utils/text/tmlp';

export interface ConflictSet {
  items: ResultValueInterface[];
  winner?: string;
  losers?: string[];
  isSolved: boolean;
}

@Component({
  selector: 'app-extra-questions-test',
  templateUrl: './extra-questions-test.component.html',
  styleUrls: ['./extra-questions-test.component.scss']
})
export class ExtraQuestionsTestComponent implements OnInit {

  @Input() newTest: TestFile;
  @Output() nextState: EventEmitter<any> = new EventEmitter<any>();

  public T = Lang.getText();

  public versus1: ResultValueInterface;
  public versus2: ResultValueInterface;
  public sets: ConflictSet[] = [];
  public currentSetIndex = 0;
  public currentMatch: number = null;
  public history: { win: string, lose: string }[] = [];

  constructor(
  ) { }

  async ngOnInit() {
    for (let i = 0; i < this.newTest.test.result.resultArray.length; i++) {
      const row = this.newTest.test.result.resultArray[i];
      let currentSet: ConflictSet = { items: [], isSolved: false };
      for (let j = 0; j <= row.length - 1; j++) {
        if (currentSet.items.length === 0) {
          currentSet.items.push(row[j]);
          continue;
        }
        const prev = currentSet.items[currentSet.items.length - 1];
        if (prev.weight === row[j].weight) {
          currentSet.items.push(row[j]);
        } else {
          if (currentSet.items.length > 1) {
            this.sets.push(currentSet);
          }
          currentSet = { items: [row[j]], isSolved: false };
        }
      }
      if (currentSet.items.length > 1) {
        this.sets.push(currentSet);
      }
    }
    this.versus1 = this.sets[this.currentSetIndex].items[0];
    this.versus2 = this.sets[this.currentSetIndex].items[1];
  }

  getQuestsion(): string {
    return tmpl(this.T.client.extraQuestion, this.getTitle(this.versus1), this.getTitle(this.versus2));
  }

  getTitle(item: ResultValueInterface, toLower = false) {
    const title = this.T.extraQuestions[item.name];
    return toLower && title.indexOf('Ваш') !== 0 ? title.toLowerCase() : title;
  }

  answer() {
    if (this.currentMatch === 1) {
      this.addMatch(this.versus1, this.versus2);
    } else if (this.currentMatch === 2) {
      this.addMatch(this.versus1, this.versus2);
    } else {
      return;
    }
    this.currentMatch = null;
  }

  /**
   * Сначала мы ищем самую важную характеристику, присваиваем ей extraWeight, затем повторяем это с остальными
   */
  addMatch(winner: ResultValueInterface, loser: ResultValueInterface) {
    this.history.push({ win: winner.name, lose: loser.name });
    let set = this.sets[this.currentSetIndex];
    set.winner = winner.name;
    if (!set.losers) {
      set.losers = [];
    }
    set.losers.push(loser.name);
    const restOfSet = set.items.filter(item => item.extraWeight === 0);
    if (set.losers.length === restOfSet.length - 1) {
      set.items.find(item => item.name === winner.name).extraWeight = set.losers.length;
      let rest = set.items.filter(item => item.extraWeight === 0);
      if (rest.length === 1) {
        if (this.currentSetIndex === this.sets.length - 1) {
          this.done();
          return;
        } else {
          this.currentSetIndex++;
          set = this.sets[this.currentSetIndex];
          rest = set.items.filter(item => item.extraWeight === 0);
        }
      }
      set.losers = [];
      set.winner = null;
      this.versus1 = rest[0];
      this.versus2 = rest[1];
      this.checkHistory();
      return;
    }
    this.versus1 = winner;
    this.versus2 = set.items.filter(item => item.extraWeight === 0 && set.losers.indexOf(item.name) === -1 && item.name !== winner.name)[0];
    this.checkHistory();
  }

  protected checkHistory() {
    if (this.history.find(h => h.win === this.versus1.name && h.lose === this.versus2.name)) {
      this.addMatch(this.versus1, this.versus2);
    }
    if (this.history.find(h => h.win === this.versus2.name && h.lose === this.versus1.name)) {
      this.addMatch(this.versus2, this.versus1);
    }
  }

  protected done() {
    this.newTest.test.extraAnswers = {};
    this.sets.forEach(set => set.items.forEach(item => this.newTest.test.extraAnswers[item.name] = item.extraWeight));
    this.nextState.emit();
  }
}
