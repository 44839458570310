import * as tslib_1 from "tslib";
import { SnotifyPosition, ToastDefaults } from 'ng-snotify';
import * as i0 from "@angular/core";
import * as i1 from "ng-snotify";
export class AlertService {
    constructor(notify) {
        this.notify = notify;
        this.notify.config = ToastDefaults;
    }
    alert(message, title = 'HR Lean', keepOpen = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notify.simple(message, title, {
                showProgressBar: false,
                bodyMaxLength: 10000,
                closeOnClick: !keepOpen,
                timeout: 0,
                icon: null,
                backdrop: 0.5,
                position: SnotifyPosition.centerCenter,
            });
        });
    }
    html(html, title = 'HR Lean', keepOpen = false, buttons = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notify.html(`<div class="snotifyToast__title">${title}</div>${html}`, {
                showProgressBar: false,
                bodyMaxLength: 10000,
                closeOnClick: !keepOpen,
                timeout: 0,
                icon: null,
                backdrop: 0.5,
                position: SnotifyPosition.centerCenter,
                buttons: buttons,
            });
        });
    }
    info(message, title = 'HR Lean') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.notify.simple(message, title, {
                showProgressBar: false,
                bodyMaxLength: 10000,
                closeOnClick: true,
                timeout: 10000,
                icon: null,
                position: SnotifyPosition.rightBottom,
            });
        });
    }
    removeToast(toastId) {
        this.notify.remove(toastId);
    }
}
AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.ɵɵinject(i1.SnotifyService)); }, token: AlertService, providedIn: "root" });
