export function plural(cnt: number, pluralString: string) {
  const plurals = pluralString.split('|');
  let num = cnt % 100;
  if (num > 20) {
    num = num % 10;
  }
  switch (num) {
    case 1: {
      return `${cnt} ${plurals[0]}`;
    }
    case 2: {
      return `${cnt} ${plurals[1]}`;
    }
    case 3: {
      return `${cnt} ${plurals[1]}`;
    }
    case 4: {
      return `${cnt} ${plurals[1]}`;
    }
    default: {
      return `${cnt} ${plurals[2]}`;
    }
  }
}
