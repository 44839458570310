export const T_SVS = {
  "lang": "svs",
  "claims": {
    "question_10001": "Будучи вынужденным защищать свои интересы, Вы способны пойти на интриги?",
    "question_10002": "Вам нравится влиять на поведение и мысли окружающих Вас людей?",
    "question_10003": "Вы согласны с поговоркой: «Сократ мне друг, но истина дороже»?",
    "question_10004": "В своей работе Вы ориентируетесь на будущее Вашей организации?",
    "question_10005": "Как Вы считаете, Вы можете вызывать нужные Вам эмоции у окружающих людей?",
    "question_10006": "Вы можете сказать, что Вы счастливый человек?",
    "question_10007": "Вы хотели бы, чтобы Вас не касались курсы повышения квалификации?",
    "question_10008": "Вы согласны с тем, что по справедливости все сотрудники Вашего отдела должны получать одинаковую зарплату?",
    "question_10009": "Для Вас лично, контролировать ситуацию – значит задавать свои правила игры?",
    "question_10010": "У Вас есть домашние питомцы?",
    "question_10011": "Для Вас важно достижение Вами уровня эксперта в интересующей Вас области?",
    "question_10012": "Умение приобретать друзей для Вас является ценным?",
    "question_10013": "Для Вас справедливость начинается с защиты своих собственных интересов?",
    "question_10014": "Для Вас важно уметь поддерживать людей?",
    "question_10015": "К другим Вы обычно применяете такие же высокие стандарты, как и к себе?",
    "question_10016": "В сложных жизненных ситуациях Вы обычно обращаетесь за помощью к более влиятельному человеку?",
    "question_10017": "Вам нравится привлекать к себе внимание окружающих?",
    "question_10018": "Вам нравится участвовать в деятельности различных клубов, общественных организаций?",
    "question_10019": "Ваш личный социальный статус для Вас имеет значение?",
    "question_10020": "Вас беспокоит необходимость передавать свой опыт и знания другим людям?",
    "question_10021": "Вы согласны, что нормальное стремление любого сотрудника - меньше делать и больше получать?",
    "question_10022": "В силу особенностей Вашей деятельности, Вам часто приходится делать замечания окружающим Вас лицам?",
    "question_10023": "Наличие сложных задач Вас вдохновляет?",
    "question_10024": "У Вас бывают ситуации, когда, обстоятельства вынуждают Вас переложить ответственность за результат на кого-то другого?",
    "question_10025": "Необходимость руководить другими людьми Вам лично дискомфортна?",
    "question_10026": "В силу особенностей Вашей деятельности, Вам часто приходится давать советы и рекомендации окружающим?",
    "question_10027": "В ситуации Вашей личной заинтересованности в результате Вам удобнее работать в команде?",
    "question_10028": "По складу своего характера, Вы можете взяться за дело, связанное с большим риском?",
    "question_10029": "По роду своей деятельности, Вам часто приходится демонстрировать свою осведомлённость в тех или иных вопросах?",
    "question_10030": "В Вашей жизни случаются ситуации, когда обстоятельства Вас вынуждают «перевести стрелки» на кого-либо постороннего?",
    "question_10031": "В группе Вы работаете более продуктивно?",
    "question_10032": "Обычно Вы стараетесь браться только за те дела, в которых считаете себя специалистом?",
    "question_10033": "Критика со стороны результатов Вашей деятельности Вас лично задевает?",
    "question_10034": "Работая в группе, Вы обычно предпочитаете роль негласного лидера?",
    "question_10035": "Для Вас работать с друзьями лучше, чем с экспертами?",
    "question_10036": "По роду своей деятельности, Вам часто приходится добиваться своей доминирующей позиции в различных вопросах?",
    "question_10037": "Деятельность Ваших коллег часто вызывает Вашу экспертную критику?",
    "question_10038": "В силу своего положения, Вам часто приходится быть источником идей и правил?",
    "question_10039": "В конфликтных ситуациях Вас часто стараются сделать «крайним»?",
    "question_10040": "Как Вы считаете, Вы умеете по-настоящему веселиться?",
    "question_10041": "Вы согласны с тем, что основной мотив деятельности любого человека – это, прежде всего, безопасность?",
    "question_10042": "Вы любите побеждать в дискуссиях и спорах?",
    "question_10043": "Для Вас лучше работать с экспертами, чем с друзьями?",
    "question_10044": "Как Вы считаете, высокая руководящая должность –  это Ваше место?",
    "question_10045": "У Вас много людей, которых Вы действительно можете назвать настоящими друзьями?",
    "question_10046": "Вас часто подводят те, на кого Вы рассчитываете?",
    "question_10047": "Вам удобнее работать в одиночку?",
    "question_10048": "Вы часто боитесь незаслуженно обидеть человека?",
    "question_10049": "Вам нравится владеть престижными вещами?",
    "question_10050": "За порученное Вам дело Вы предпочитаете нести личную ответственность?",
    "question_10051": "Вы считаете своей целью быть полезным обществу?",
    "question_10052": "При стечении обстоятельств Вы могли бы пожертвовать интересами других людей в свою пользу?",
    "question_10053": "Как Вы считаете, Ваши позиции авторитетны среди коллег?",
    "question_10054": "Достигнув успеха в выполнении поставленной задачи, Вы обычно теряете к ней интерес?",
    "question_10055": "Быть специалистом в своем деле, для Вас лично, важнее умения руководить людьми?",
    "question_10056": "В Вашей жизни часто бывают ситуации, когда к Вам относятся несправедливо?",
    "question_10057": "Как Вы считаете, Вы популярны в своём кругу?",
    "question_10058": "В нерабочее время Вам часто приходится общаться в социальных сетях и мессенджерах?",
    "question_10059": "Критика результатов Вашей деятельности Вас вдохновляет на поиск новых решений?",
    "question_10060": "Вас привлекает возможность участвовать в каких-либо групповых мероприятиях?",
    "question_10061": "Вы хотели бы родиться в другие времена?",
    "question_10062": "Вас вдохновляет решение сложных и трудных задач?",
    "question_10063": "Вам нравится проводить изменения и реформы в интересующих Вас областях жизни?",
    "question_10064": "Вы умеете сохранять дружбу?",
    "question_10065": "Вам нравится в своих достижениях быть лучше других?",
    "question_10066": "В жизни Вам часто приходится отвечать за ошибки других?",
    "question_10067": "Как Вы считаете, Вы хороший наставник?",
    "question_10068": "Как Вы считаете, у Вас много настоящих друзей?",
    "question_10069": "Вам нравится в своих достижениях превосходить самого себя?",
    "question_10070": "Периодически вы попадаете в ситуации, когда кому-то из Вашего окружения неоправданно везёт?",
    "question_10071": "Иногда, в ситуации, когда Вы не в силах что-либо изменить, Вы можете прибегнуть к помощи алкоголя?",
    "question_10072": "Ваши усилия часто обесцениваются окружающими?"
  },
  "interests": {
    "ideologOption1": "Идея",
    "ideologOption2": "Идеология",
    "ideologOption3": "Религия",
    "ideologOption4": "Вера",
    "ideologOption5": "Справедливость",
    "ideologOption6": "Правда",
    "ideologOption7": "Законность",
    "ideologOption8": "Равноправие",
    "ideologOption9": "Свобода",
    "ideologOption10": "Равенство",
    "ideologOption11": "Патриотизм",
    "ideologOption12": "Верность",
    "ideologOption13": "Долг",
    "ideologOption14": "Постижение",
    "ideologOption15": "Наука",
    "ideologOption16": "Совершение открытий",
    "ideologOption17": "Передача опыта",
    "ideologOption18": "Стремление к неизвестному",
    "ideologOption19": "Принадлежность",
    "ideologOption20": "Имидж",
    "ideologOption21": "Феминизм",
    "ideologOption22": "'Надо!'",
    "ideologOption23": "Порядочность",
    "ideologOption24": "Настойчивость",
    "ideologOption25": "Последовательность",
    "ideologOption26": "Чувство долга",
    "ideologOption27": "Предсказуемость",
    "ideologOption28": "Принадлежность к национальности",
    "ideologOption29": "Принадлежность к определенной группе",
    "ideologOption30": "Качество",
    "materialOption1": "Деньги (сбережения)",
    "materialOption2": "Деньги (зарплата)",
    "materialOption3": "Акции",
    "materialOption4": "Драгоценности",
    "materialOption5": "Инвестиции",
    "materialOption6": "Работа",
    "materialOption7": "Карьера",
    "materialOption8": "Имущество",
    "materialOption9": "Рабочая схема",
    "materialOption10": "Рабочая идея",
    "materialOption11": "Собственный бизнес",
    "materialOption12": "Бренд",
    "materialOption13": "Личный автомобиль",
    "materialOption14": "Собственное жилье",
    "materialOption15": "Награда",
    "materialOption16": "Связи",
    "materialOption17": "Влиятельные друзья",
    "materialOption18": "Социальный статус",
    "materialOption19": "Место в иерархии",
    "materialOption20": "Долги",
    "materialOption21": "Финансовые обязательства",
    "materialOption22": "Наследство",
    "materialOption23": "Коллекция",
    "materialOption24": "Образование",
    "materialOption25": "Профессия",
    "materialOption26": "Опыт",
    "materialOption27": "Квалификация",
    "materialOption28": "Достаток",
    "materialOption29": "Перспективы",
    "materialOption30": "Рабочая информация",
    "emotionalOption1": "Любовь",
    "emotionalOption2": "Дружба",
    "emotionalOption3": "Радость",
    "emotionalOption4": "Удовольствие",
    "emotionalOption5": "Спокойствие",
    "emotionalOption6": "Месть",
    "emotionalOption7": "Кайф",
    "emotionalOption8": "Понимание",
    "emotionalOption9": "Адреналин",
    "emotionalOption10": "Сексуальное удовлетворение",
    "emotionalOption11": "Любопытство",
    "emotionalOption12": "Нирвана",
    "emotionalOption13": "Сопричастность",
    "emotionalOption14": "Согласие",
    "emotionalOption15": "Смелость",
    "emotionalOption16": "Веселье",
    "emotionalOption17": "Счастье",
    "emotionalOption18": "Мужественность",
    "emotionalOption19": "Женственность",
    "emotionalOption20": "Привлекательность",
    "emotionalOption21": "Уравновешенность",
    "emotionalOption22": "Уверенность",
    "emotionalOption23": "Отзывчивость",
    "emotionalOption24": "Эмпатия",
    "emotionalOption25": "Самоуверенность",
    "emotionalOption26": "Самодостаточность",
    "emotionalOption27": "Честность",
    "emotionalOption28": "Чувственность",
    "emotionalOption29": "Добросердечие",
    "emotionalOption30": "Удивление",
    "vitalOption1": "Сохранение жизни",
    "vitalOption2": "Сохран здоровья",
    "vitalOption3": "Укреплен. здоровья",
    "vitalOption4": "Излечение",
    "vitalOption5": "Качество жизни",
    "vitalOption6": "Продолжение рода",
    "vitalOption7": "Наличие семьи",
    "vitalOption8": "Согласие в семье",
    "vitalOption9": "Наличие детей",
    "vitalOption10": "Отношения с детьми",
    "vitalOption11": "Отн.  с родителями",
    "vitalOption12": "Экология",
    "vitalOption13": "Питание",
    "vitalOption14": "Диета",
    "vitalOption15": "Спорт",
    "vitalOption16": "Физкультура",
    "vitalOption17": "Режим дня",
    "vitalOption18": "Профилактика",
    "vitalOption19": "Здоровый образ жизни",
    "vitalOption20": "Трезвость",
    "vitalOption21": "Родители",
    "vitalOption22": "Раздельное питание",
    "vitalOption23": "Здоровье детей",
    "vitalOption24": "Безопасный секс",
    "vitalOption25": "Активный отдых",
    "vitalOption26": "Стиль жизни",
    "vitalOption27": "Оберегание себя",
    "vitalOption28": "Лекарство",
    "vitalOption29": "Домашнее животное",
    "vitalOption30": "Релаксация"
  },
  "strategy": {
    "question_20001": "Когда я чему-то учусь...",
    "question_20001_1": "Я люблю доверять своим  чувствам и ощущениям",
    "question_20001_2": "Я люблю смотреть и слушать",
    "question_20001_3": "Я люблю думать над идеями",
    "question_20001_4": "Я люблю что-то делать",
    "question_20002": "Лучше всего я учусь, когда...",
    "question_20002_1": "Я опираюсь на свою интуицию и ощущения",
    "question_20002_2": "Внимательно слушаю и наблюдаю",
    "question_20002_3": "Я полагаюсь на логическое мышление",
    "question_20002_4": "Я упорно работаю, чтобы сделать дело",
    "question_20003": "В процессе учёбы я...",
    "question_20003_1": "Чувствую сильную эмоциональную вовлеченность",
    "question_20003_2": "Я спокоен и сдержан",
    "question_20003_3": "Я склонен отыскивать причины",
    "question_20003_4": "Я отвечаю за дело",
    "question_20004": "Я учусь посредством...",
    "question_20004_1": "Ощущений и чувств",
    "question_20004_2": "Наблюдения",
    "question_20004_3": "Размышлений",
    "question_20004_4": "Делания",
    "question_20005": "Когда я учусь...",
    "question_20005_1": "Я открыт новым переживаниям",
    "question_20005_2": "Рассматриваю все стороны вопроса",
    "question_20005_3": "Я люблю анализировать вещи, разделять их на части",
    "question_20005_4": "Я люблю испытывать что-то на себе",
    "question_20006": "В процессе учёбы я...",
    "question_20006_1": "Человек Интуиции",
    "question_20006_2": "Человек – Наблюдатель",
    "question_20006_3": "Человек Логичный",
    "question_20006_4": "Человек Активный",
    "question_20007": "Лучше всего я учусь на...",
    "question_20007_1": "Личных отношениях",
    "question_20007_2": "Наблюдениях",
    "question_20007_3": "Рациональных теориях",
    "question_20007_4": "Личном опыте действия",
    "question_20008": "Когда я учусь...",
    "question_20008_1": "Я чувствую личную увлечённость делом",
    "question_20008_2": "Думаю перед тем как действовать",
    "question_20008_3": "Мне нравится опираться на идеи и теории ",
    "question_20008_4": "Я люблю видеть результаты своего труда",
    "question_20009": "Я учусь лучше всего, когда:",
    "question_20009_1": "Полагаюсь на свои чувства",
    "question_20009_2": "Полагаюсь на свои наблюдения",
    "question_20009_3": "Полагаюсь на свои идеи",
    "question_20009_4": "Полагаюсь на свой практический опыт",
    "question_20010": "В процессе учёбы я...",
    "question_20010_1": "Человек, открытый  к принятию нового опыта",
    "question_20010_2": "Человек спокойный и сдержанный ",
    "question_20010_3": "Человек Рациональный",
    "question_20010_4": "Человек Ответственный",
    "question_20011": "Когда я учусь...",
    "question_20011_1": "Я увлекаюсь процессом",
    "question_20011_2": "Я люблю наблюдать",
    "question_20011_3": "Я оцениваю ситуации и вещи",
    "question_20011_4": "Я люблю активно действовать",
    "question_20012": "Я учусь лучше всего, когда...",
    "question_20012_1": "Я восприимчив и открыт",
    "question_20012_2": "Я точен",
    "question_20012_3": "Я анализирую идеи",
    "question_20012_4": "Я практичен"
  },
  "motivation": {
    "question_30001": "Какая возможность в рабочем процессе ценится Вами более всего?",
    "question_30001_1": "Возможность самому решать, что и как мне делать.",
    "question_30001_2": "Возможность проявить свои навыки и умения.",
    "question_30001_3": "Возможность чувствовать себя полезным и нужным.",
    "question_30001_4": "Чтобы работа была мне хорошо знакома и привычна.",
    "question_30002": "Какое выражение, из перечисленных ниже, Вам подходит более всего?",
    "question_30002_1": "В своей работе я хочу быть полным  хозяином ситуации.",
    "question_30002_2": "У меня достаточно знаний и опыта, чтобы справиться с любыми трудностями в моей работе.",
    "question_30002_3": "Я - ценный, незаменимый для организации работник.",
    "question_30002_4": "Я стараюсь всегда выполнять то, что от меня требуют.",
    "question_30003": "Как Вы предпочитаете работать?",
    "question_30003_1": "Предпочитаю делать то, что знакомо, привычно.",
    "question_30003_2": "Хочу, чтобы в работе постоянно появлялось что-то новое, чтобы не стоять на месте.",
    "question_30003_3": "Предпочитаю работать под полную личную ответственность.",
    "question_30003_4": "Готов(а) делать все, что нужно для организации.",
    "question_30004": "Допустим, что Вам предлагают перейти на другую должность. При каких условиях Вы на это согласитесь?",
    "question_30004_1": "Если другая работа будет более творческой, интересней, чем нынешняя.",
    "question_30004_2": "Если новая работа даст мне больше самостоятельности.",
    "question_30004_3": "Если это очень нужно и принесет пользу для организации.",
    "question_30004_4": "Я предпочел (предпочла) бы остаться на той работе, к которой привык (привыкла).",
    "question_30005": "Попробуйте определить, что для Вас означает Ваш заработок?",
    "question_30005_1": "Я расцениваю зарплату как плату за мои знания и  квалификацию.",
    "question_30005_2": "Я расцениваю зарплату как оплату за результаты деятельности всей организации.",
    "question_30005_3": "Я расцениваю зарплату как гарантированный доход, возможно небольшой, но стабильный.",
    "question_30005_4": "Я расцениваю зарплату как эффект от своей собственной деятельности.",
    "question_30006": "Как Вы относитесь к перечисленным ниже источникам дохода (в том числе теоретически возможным источникам)?",
    "question_30061": "Заработная плата и премии.",
    "question_30062": "Доплаты за квалификацию.",
    "question_30063": "Доплаты за тяжелые и опасные условия работы.",
    "question_30064": "Социальные выплаты и льготы, пособия.",
    "question_30065": "Доходы от капитала (проценты от вкладов в банке, акций).",
    "question_30066": "Возможность дополнительных подработок (разрешенных официально).",
    "question_30067": "Возможность дополнительного дохода, но не любого, а только по своей специальности\r\n(разрешенного официально).",
    "question_30068": "Выигрыш в лотерею, казино и пр.",
    "question_30007": "На каких принципах, по-Вашему, должны строиться отношения между работником и организацией?",
    "question_30007_1": "Работник должен относиться к организации как к своему дому, отдавать ей все и вместе\r\nпереживать трудности и подъемы. Организация должна, соответственно, оценивать преданность и труд работника.\r\nОрганизация должна, соответственно, оценивать преданность и труд работника.",
    "question_30007_2": "Работник приходит в организацию для самореализации и относится к ней как к месту реализации своих способностей. Организация должна обеспечивать работнику такую возможность, извлекать из этого выгоду для себя и на этой основе развиваться.",
    "question_30007_3": "Работник тратит свои силы ради организации, а организация должна взамен гарантировать ему зарплату и социальное обеспечение.",
    "question_30008": "Как Вы считаете, почему в процессе работы люди проявляют инициативу, вносят различные предложения?",
    "question_30008_1": "Чувствуют особую ответственность за свою работу.",
    "question_30008_2": "Из-за стремления реализовать свои знания и опыт, выйти за установленные работой рамки.",
    "question_30008_3": "Чаще всего из-за желания улучшить работу своей организации.",
    "question_30008_4": "Хотят завоевать расположение руководства и продвинуться по службе.",
    "question_30009": "Какое суждение о коллективной работе Вам ближе?",
    "question_30009_1": "Коллектив для меня очень важен, одному хороших результатов не добиться.",
    "question_30009_2": "Предпочитаю работать автономно, но чувствую себя так же хорошо, когда работаю вместе с интересными людьми.",
    "question_30009_3": "Мне важно, чтобы коллектив не ограничивал свободу моих действий.",
    "question_30009_4": "Мне важно работать в коллективе, так как там я среди своих.",
    "question_30010": "Представьте себе, что у Вас появилась возможность стать руководителем (или руководителем более высокого уровня).  Воспользуетесь ли Вы этой возможностью?",
    "question_30010_1": "Да, так как я смогу участвовать в управлении организацией.",
    "question_30010_2": "Да, так как хороший работник должен стремиться стать руководителем.",
    "question_30010_3": "Нет, так как прибавка к зарплате не является моей целью, участие в руководстве мне не важно, а интересной работе это помешает.",
    "question_30010_4": "Нет, так как это не является интересным для меня и несет лишние заботы.",
    "question_30011": "Представьте, пожалуйста, что Вы сейчас думаете о переводе в другой отдел. У Вас есть несколько предложений. Какое из них Вы выберете?",
    "question_30011_1": "Наиболее интересную, творческую.",
    "question_30011_2": "Наиболее самостоятельную, независимую.",
    "question_30011_3": "Там, где будет привычная работа и стабильная заработная плата.",
    "question_30011_4": "Не могу представить, что мне понадобится уходить из нашего коллектива в другое место.",
    "question_30012": "Что Вы прежде всего учитываете, когда оцениваете успехи другого работника в Вашей организации?",
    "question_30012_1": "Уровень его профессионализма, квалификации.",
    "question_30012_2": "Насколько хорошая у него должность.",
    "question_30012_3": "Насколько его уважают в организации.",
    "question_30012_4": "Насколько он самостоятелен, независим.",
    "question_30013": "Если Вы - РУКОВОДИТЕЛЬ, то что Вас привлекает прежде всего в этом должности?",
    "question_30013_1": "Возможность принимать самостоятельные, ответственные решения.",
    "question_30013_2": "Возможность принести наибольшую пользу организации.",
    "question_30013_3": "Возможность наилучшим образом применить свои знания и умения.",
    "question_30013_4": "Ничего особенно не привлекает, за положение руководителя не держусь.",
    "question_30014": "Если Вы НЕ ЯВЛЯЕТЕСЬ РУКОВОДИТЕЛЕМ, то хотели бы им стать?",
    "question_30014_1": "Да, поскольку это даст возможность принимать самостоятельные, ответственные решения.",
    "question_30014_2": "Не против, если это нужно для пользы дела.",
    "question_30014_3": "Да, так как при этом я смогу лучше применить свои знания и умения.",
    "question_30014_4": "Нет, это слишком большая нагрузка для меня.",
    "separate_question": "Являетесь ли Вы руководителем на Вашей текущей должности?"
  },
  "pages": {
    "personalAdviser": "Персональный советник",
    "login": "Логин",
    "password": "Пароль",
    "group": "Группа",
    "enter": "Войти",
    "errorLoginIsRequired": "Поле \"Логин\" обязательно для заполнения",
    "errorPasswordIsRequired": "Поле \"Пароль\" обязательно для заполнения",
    "errorGroupIsRequired": "Поле \"Группа\" обязательно для заполнения",
    "errorWrongLoginOrPassword": "Неверный логин или пароль",
    "errorWrongPassword": "Неверный пароль",
    "loaderImage": "loader_ru.jpg",
    "version": "Версия",
    "build": "Сборка",
    "close": "Закрыть",
    "testMode": "Режим тестирования",
    "reportsAndBlanks": "Отчеты и бланки",
    "settings": "Настройки",
    "systemLogs": "Системные логи",
    "about": "О программе",
    "exit": "Выйти",
    "help": "Справка",
    "back": "Назад",
    "cancel": "Отмена",
    "save": "Сохранить",
    "done": "Готово",
    "choose": "Выбрать",
    "unlimited": "Не ограничен",
    "active": "Активна",
    "expired": "Истекла",
    "hello": "Здравствуйте",
    "testing": "Тестирование",
    "yes": "Да",
    "no": "Нет",
    "next": "Далее",
    "important": "Очень важно",
    "notImportant": "Совсем не важно",
    "notReallyImportant": "Не очень важно",
    "ideolog": "Идеологические",
    "material": "Материальные",
    "vital": "Витальные",
    "emotional": "Эмоциональные",
    "ideologInterests": "Идеологические ценности",
    "materialInterests": "Материальные ценности",
    "vitalInterests": "Витальные ценности",
    "emotionalInterests": "Эмоциональные ценности",
    "question": "Вопрос",
    "focusChart": "Время фокуса на вариантах ответа",
    "createReport": "Создать отчет",
    "searchSettings": "Параметры поиска",
    "organization": "Организация",
    "allOrganizations": "Все организации",
    "department": "Подразделение",
    "allDepartment": "Все подразделения",
    "leadingCharacteristic": "Ведущая характеристика",
    "anyF": "Любая",
    "find": "Найти",
    "searchResult": "Результаты поиска",
    "addWorkerToReport": "Добавить сотрудника к отчету",
    "removeWorkerFromReport": "Удалить сотрудника из отчета",
    "workersForReport": "Сотрудники для отчета",
    "reportIsGenerating": "Отчет генерируется",
    "errorNoWorkers": "Ни одного сотрудника не добавлено",
    "errorTestLimit": "Превышено максимальное количество тестирований",
    "yearPlural": "год|года|лет",
    "lessThanOneYear": "менее 1 года",
    "moreThan15Year": "более 15 лет",
    "confirmCloseModalWindow": "Вы уверены, что хотите закрыть окно без сохранения изменений?",
    "errorNameNotFilled": "Имя обязательно для заполнения",
    "errorSurnameNotFilled": "Фамилия обязательно для заполнения",
    "errorBirthdayNotFilled": "Дата рождения обязательна для заполнения",
    "errorBirthdayIncorrect": "Дата рождения заполнена некорректно",
    "errorStartWorkDateIncorrect": "Дата трудоустройства заполнена некорректно",
    "errorStartWorkDateMoreThanBirthday": "Дата трудоустройства не может быть раньше даты рождения",
    "errorOrganizationIsRequired": "Поле \"Организация\" обязательно для заполнения",
    "errorDepartmentIsRequired": "Поле \"Подразделение\" обязательно для заполнения",
    "errorSomeFieldsIncorrect": "Некоторые поля заполнены некорректно",
    "createNewWorker": "Создание нового сотрудника",
    "uploadTesting": "Загрузить файлы тестирований",
    "uploadLogs": "Загрузить файлы логов",
    "editWorker": "Редактировать сотрудника",
    "removeWorker": "Удалить сотрудника",
    "workerEditing": "Редактирование сотрудника: {0}",
    "uploadPhoto": "Загрузить фото c диска",
    "notFilled": "Не заполнено",
    "surname": "Фамилия",
    "firstName": "Имя",
    "middleName": "Отчество",
    "virtualInterests": "Виртуальные ценности",
    "antiInterests": "Антиценности",
    "gender": "Пол",
    "male": "Мужской",
    "female": "Женский",
    "phone": "Телефон",
    "education": "Образование",
    "noEducation": "Без образования",
    "middleEducation": "Среднее",
    "middleSpecialEducation": "Среднее/специальное",
    "partlyHighEducation": "Неполное высшее",
    "highEducation": "Высшее",
    "academicDegree": "Ученая степень",
    "birthday": "Дата рождения",
    "email": "Email",
    "address": "Адрес",
    "post": "Должность",
    "experience": "Общий стаж работы",
    "startWorkDate": "Дата трудоустройства",
    "dateFormat": "дд.мм.гггг",
    "state": "Состояние",
    "stateCandidate": "Кандидат",
    "stateWorker": "Работает",
    "stateFired": "Уволен",
    "selected": "Выбрано",
    "testResults": "Результаты тестирований",
    "chooseTestingDate": "Выберите дату тестрования",
    "workerWithoutTests": "Сотрудник не проходил ни одного тестирования",
    "processing": "Идет обработка...",
    "shortReport": "Краткий отчет",
    "fullReport": "Полный отчет",
    "testingTime": "Время тестирования",
    "allWorkers": "Все сотрудники",
    "workingOnly": "Только работающие",
    "firedOnly": "Только уволенные",
    "candidatesOnly": "Только кандидаты",
    "personnelReserve": "Кадровый резерв",
    "confirmRemoveWorker": "Вы уверены, что хотите удалить этого сотрудника? Это действие нельзя отменить",
    "errorLicenseExpiredAndFunctionsBlocked": "Срок действия Вашей лицензии истек! Функции редактирования сотрудников и проведения тестов отключены.",
    "errorLicenceExpired": "Срок действия Вашей лицензии истек",
    "searchForWorkers": "Поиск по сотрудникам",
    "paginationRange": "Показано",
    "paginationLimit": "По",
    "tests": "Тест-ия",
    "oneOfFour": "Один из четырех",
    "testGroup": "Группа тестов",
    "noWorkerWasAdd": "Ни одного сотрудника пока не добавлено",
    "welcome": "Добро пожаловать",
    "dataWillBeSavedInFolder": "Данные будут сохранены в папку {0}",
    "copy": "Скопировать",
    "activationCode": "Код активации",
    "codeCopied": "Код скопирован!",
    "errorUnableToApplyLicenceFile": "Не удалось прикрепить файл лицензии",
    "enterServerIp": "Введите IP адрес сервера",
    "configureProgramToStartWork": "Для начала работы с программой выполните начальную настройку",
    "localVersion": "Локальная версия",
    "chooseLocalVersion": "Выберите эту версию, если вам нужно установить программу только на один рабочий компьютер",
    "serverVersion": "Сетевая версия - \"Сервер\"",
    "chooseServerVersion": "Сервер содержит все данные, которые хранятся в системе. Он должен быть всегда запущен, чтобы программы-клиенты могли к нему подключится",
    "clientVersion": "Сетевая версия - \"Клиент\"",
    "chooseClientVersion": "Клиент не содержит данные, а использует данные сервера, к которому он подключается",
    "chooseFolder": "Выберите папку для хранения данных о программе",
    "chooseAuto": "Выбрать автоматически",
    "oneLicenceButton": "Нажмите эту кнопку, если приобретали лицензию на 1 пользователя - программа сама выберет место для хранения данных",
    "chooseManual": "Выбрать вручную",
    "chooseCustomFolderButton": "Если Вы приобретали лицензию на несколько пользователей,\nВы должны выбрать папку, которая будет доступна со всех компьютеров в сети",
    "initStep1": "1. Скопировать",
    "initStep2": "2. Перейдите по ссылке на сайт получения лицензии:",
    "initStep3": "3. На сайте получения лицензии введите <b>код активации</b>, Ваш email и пароль, который Вы получили, когда приобретали программу. На Вашу почту будет отправлен файл с лицензией.",
    "initStep4": "4. Прикрепите полученный <b>файл лицензии</b> для активации программы.",
    "backToChooseVersion": "Вернуться к выбору версии",
    "attachFile": "Прикрепить файл",
    "chooseIp": "Введите IP адрес сервера, к которому будет подключен клиент. Программа \"Персональный советник\" должна быть запущена на сервере",
    "chooseIpExamplePlaceholder": "Например, 127.0.0.1",
    "check": "Проверить",
    "claims": "Притязания",
    "interests": "Интересы",
    "strategy": "Стратегия",
    "motivation": "Мотивация",
    "extraQuestions": "Доп. вопросы",
    "testClaims": "Тест \"Притязания\"",
    "testInterests": "Тест \"Интересы\"",
    "testStrategy": "Тест \"Стратегия\"",
    "testMotivation": "Тест \"Мотивация\"",
    "personalDataAgreement": "Соглашение об использовании персональных данных",
    "testClaimsFile": "claims_svs.docx",
    "testInterestsFile": "interests_svs.docx",
    "testStrategyFile": "strategy_svs.docx",
    "testMotivationFile": "motivation_svs.docx",
    "personalDataAgreementFile": "agree_ru.pdf",
    "errorNoWorkerTested": "Не удалось сформировать отчет - ни одного протестированного сотрудника не найдено",
    "totalReport": "Отчет \"Сводная таблица\"",
    "openReportFile": "Открыть файл отчета",
    "teamForming": "Формирование команды",
    "errorNoOrganizationWithWorkers": "Ни одной организации с работающими сотрудниками не найдено",
    "createUser": "Создание пользователя",
    "deleteUser": "Удалить пользователя",
    "usersPlural": "пользователя|пользователей|пользователей",
    "errorMaxUsersLimit": "Ваша лицензия не позволяет создать более {0}",
    "errorUserWithLoginAlreadyExist": "Пользователь с таким логином уже существует",
    "users": "Пользователи",
    "licenceInformation": "Информация по лицензии",
    "validity": "Срок действия",
    "licenceStatus": "Статус лицензии",
    "maxUsersCount": "Максимальное количество пользоваталей",
    "maxTestsCount": "Максимальное количество тестирований",
    "updateLicence": "Обновить лицензию",
    "networkSettings": "Сетевые настройки",
    "folderPath": "Путь до папки с данными",
    "confirmDiscardLicence": "Для продления лицензии необходимо сбросить старую.\nВы уверены, что хотите продолжить?",
    "getNewLicenceInfo": "Для продолжения работы Вам нужно получить новый файл лицензии с новым кодом активации и подключить его как обычно",
    "cannotDeleteAdminUser": "Нельзя удалить пользователя - администратора",
    "confirmDeleteUser": "Вы уверены, что хотите удалить этого пользователя?",
    "confirmRestart": "Требуется перезапуск приложения. Вы уверены, что хотите продолжить?",
    "serverIp": "IP адрес сервера",
    "discardNetworkSettings": "Сбросить сетевые настройки",
    "errorAllFieldsRequired": "Все поля обязательны для заполнения",
    "pleaseConfirmCorrectData": "Пожалуйста, проверьте что данные указаны верно",
    "errorAuthTemporaryAvailableForAdminOnly": "Количество пользователей в системе ({0}) превышает количество, предусмотренное Вашей лицензией ({1}).\nАвторизация временно доступна только администратору.",
    "errorUnableToConnectToServer": "Не удалось подключиться к серверу, приложение будет закрыто\nВы хотите сбросить сетевые настройки?",
    "errorVersionMismatch": "Версия приложения-клиента не совпадает с версией приложения-сервера.\nВерсия сервера - {0}, версия клиента - ${1}\nПожалуйста обратитесь к администратору",
    "errorUnableToConnectToDatabase": "Не удалось подключиться к базе данных",
    "errorCannotUseNetworkPath": "Нельзя указать сетевой путь в качестве места хранения данных.",
    "confirmAction": "Для этого действия необходимо подтверждение",
    "enterPassword": "Введите пароль",
    "errorUnknown": "Произошла неизвестная ошибка",
    "confirmCloseApp": "Вы уверены, что хотите завершить работу программы?",
    "errorSeveralAuthConnections": "Ошибка соединения - возможно кто-то авторизовался под Вашей учетной записью",
    "errorOnlyImagesCanBeUpload": "Для загрузки доступны только файлы изображений",
    "errorTestFileIsSkipped": "Файл сотрудника \"{0}\" пропущен, т.к. уже был загружен ранее",
    "errorUnableToLoadFile": "Не удалось загрузить файл {0}",
    "sysLogAppOpen": "Приложение было запущено. Информация о машине: {0}",
    "sysLogKeyPress": "Нажата клавища '{0}'",
    "sysLogAppClose": "Приложение было закрыто",
    "sysLogTestSTart": "Начало тестирования. Информация о машине: {0}",
    "sysLogPause": "Простой в работе программы: {0} сек.",
    "sysLogFocus": "Окно программы получило фокус",
    "sysLogBlur": "Окно программы потеряло фокус",
    "sysLogMinimize": "Программа была свернута",
    "sysLogRestore": "Программа была развернута",
    "sysLogStartTest": "Начало прохождения тестирования. Информация по машине: {0}",
    "sysLogFinishTest": "Окончание прохождения тестирования",
    "sysLogClick": "Нажата кнопка '{0}'",

    "logTypeStartApp": "Приложение было запущено",
    "logTypeCloseApp": "Приложение было закрыто",
    "logTypeKeypress": "Нажата клавиша",
    "logTypePause": "Простой в работе программы",
    "logTypeFocus": "Окно программы получило фокус",
    "logTypeBlur": "Окно программы потеряло фокус",
    "logTypeMinimize": "Программа была свернута",
    "logTypeRestore": "Программа была развернута",
    "logTypeStartTest": "Начало прохождения тестирования",
    "logTypeFinishTest": "Окончание прохождения тестирования",
    "logTypeClick": "Нажата кнопка",

    "dateAndTime": "Дата и время",
    "compId": "ID компьютера",
    "typeTitle": "Тип события",
    "logText": "Текст события",
    "logFileUploadSuccess": "Файл логов был успешно загружен. {0} записей добавлено",
    "chooseDay": "Выберите дату",
  },
  "extraQuestions": {
    "power": "Ваши личные амбиции",
    "achievement": "Достижение высокого\nпрофессионального результата",
    "involvement": "Сохранение отношений\nс конкретным человеком",
    "avoid": "Свобода от ответственности\nза результат",
    "ideolog": "Ваши принципы",
    "materialist": "Ваша выгода",
    "vitalist": "Поддержание привычного\nобраза жизни",
    "emotional": "Сохранение собственного\nэмоционального комфорта",
    "practices": "Опираться на имеющийся опыт",
    "watcher": "Видеть реальную ситуацию",
    "pragmatic": "Учитывать риски и выгоды",
    "theorist": "Cохранение существующего\nтеоретического алгоритма",
    "master": "Ваша самостоятельность и\nнезависимость",
    "patriot": "Конкретные интересы организации",
    "professional": "Профессиональная самореализация",
    "lumpen": "Свобода от ответственности\nза возможные последствия"
  },
  "reports": {
    "avoid": "Избегатель",
    "power": "Властник",
    "achievement": "Достиженец",
    "involvement": "Причастник",
    "ideolog": "Идеолог",
    "materialist": "Материалист",
    "emotional": "Эмоционал",
    "vitalist": "Виталист",
    "practices": "Практик",
    "watcher": "Наблюдатель",
    "pragmatic": "Прагматик",
    "theorist": "Теоретик",
    "master": "Хозяин",
    "patriot": "Патриот",
    "professional": "Профессионал",
    "lumpen": "Люмпен",
    "actions": "Действия",
    "observations": "Наблюдения",
    "feelings": "Чувства",
    "understanding": "Понимание",
    "characteristic": "ХАРАКТЕРИСТИКА",
    "confidential": "Строго конфиденциально",
    "hrLeanTesting": "Тестирование на платформе HR Lean",
    "testConclusion": "Вывод согласно тестированию",
    "date": "Дата",
    "specialistFio": "ФИО специалиста",
    "specialistSign": "Подпись специалиста",
    "signPlace": "М.П.",
    "totalReportDesc": "Сводная таблица результатов тестирования с детализацией личных показателей",
    "totalTableDesc": "Общая сводная таблица по итогам тестирования",
    "ofOrganization": "организации",
    "ofDepartment": "подразделения",
    "claimsWhat": "Притязания (Что?)",
    "interestsWhere": "Интересы (Где?)",
    "strategyHow": "Стратегия (Как?)",
    "motivationWhy": "Мотивация (Зачем?)",
    "organizationSum": "Сумма по организации",
    "departmentSum": "Сумма по подразделению",
    "teamSum": "Сумма по команде",
    "target": "Цель",
    "means": "Средство",
    "instrument": "Инструмент",
    "context": "Контекст",
    "specialistComment": "Комментарий специалиста",
    "organizationLogic": "Логика поведения организации",
    "risksAndGrowth": "Риски / Зона развития / над чем работать",
    "howToMotivate": "Мотивировать",
    "formOfPunishment": "Форма наказания за ошибки",
    "summary": "По итогу",
    "fio": "ФИО",
    "textClaimsLevel": "Уровень притязаний",
    "textTarget": "[Цель]",
    "textTargetValue": "[Цель]/Ценность",
    "textMeans": "[Средство]",
    "textMeansOfImplementation": "[Средство] реализации",
    "textType": "[Тип]",
    "textInstrument": "[Инструмент]",
    "textOperatingPrinciple": "[Принцип деятельности]",
    "textPositiveMotivation": "[Позитивно мотивирован]: мотивируется ",
    "byIdea": "идеей",
    "byMaterialGoods": "приобретением материальных благ",
    "byEmotions": "эмоциями",
    "byLifeQuality": "качеством жизни",
    "testingDate": "Дата тестирования",
    "seeTarget": "см. Цель",
    "textAreaOfInterests": "Сфера интересов: (контекст влияния и самореализации)",
    "values": "ЦЕННОСТИ",
    "learningStyle": "Стиль обучения",
    "actionStrategy": "Стратегия деятельности",
    "actionSchema": "Схема деятельности",
    "strategy_logic_practices": "Опробовали на практике",
    "strategy_logic_watcher": "Увидели результат",
    "strategy_logic_theorist": "Опираясь на имеющийся опыт, создали концепцию",
    "strategy_logic_pragmatic": "Оценили возможные риски и выгоды/убытки и прибыли",
    "strategy_risk_conflict_1": "Из-за отсутствия фокусировки на работу у руководства рабочие концепции часто бывают не проверены в силу слабой предварительной экспериментальной проработки. Поэтому коллектив время от времени бывает парализован альтернативами, не имея чёткой системы критериев выбора. Пытаясь это преодолеть, организация периодически совершает однотипные ошибки, опираясь на желаемое вместо действительного. Как следствие, команду бросает из стороны в сторону, она либо занимается тривиальными усовершенствованиями, либо осуществляет громадные свершения, но не тех вещей, что действительно нужны.",
    "strategy_risk_conflict_2": "Ориентировка на желаемое вместо действительного у руководства (ложные надежды, воздушные замки) при отсутствии практического подхода приводит к тому, что коллектив может оказаться парализован альтернативами вариантов своей деятельности. При этом рабочие концепции могут оказаться не проверенными из-за слабой экспериментальной проработки, так как отсутствует у руководства фокусировка на основной вид деятельности. Итогом это может привести к нарушению сроков завершения работы из-за отсутствия направленности коллектива на конкретную цель.",
    "strategy_risk_conflict_3": "Ориентировка на желаемое вместо действительного при отсутствии практического подхода у руководства (ложные надежды, воздушные замки) может привести коллектив к нарушению сроков завершения работы, так как отсутствует фокусировка на конкретную цель. В силу преждевременного завершения процесса работы или качественного решения руководством второстепенных проблем и задач, организация в ситуации выбора может не различать проблемы и возможности из-за слабости исходной рабочей концепции. ",
    "strategy_risk_conflict_4": "Руководство может периодически совершать однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности, так как отсутствует теоретическая основа для работы. А именно, может завершить процесс работы преждевременно или качественно решать не ту проблему. Это приводит к тому, что в ситуации выбора коллектив может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, коллектив может осуществлять громадные свершения, но не тех вещей, что надо или же заниматься тривиальными усовершенствованиями.",
    "strategy_risk_practices": "Из-за отсутствия теоретической основы для работы руководство может периодически совершать однотипные ошибки, из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Снаружи это может привести коллектив к громадным свершениям, но не тех вещей, что нужно или же к занятиям тривиальными усовершенствованиями.",
    "strategy_risk_theorist": "Ориентировка на желаемое вместо действительного при отсутствии практического подхода у руководства (ложные надежды, воздушные замки) может привести к тому, что коллектив не завершит работу вовремя, решая не ту проблему или задачу.",
    "strategy_risk_watcher": "Из-за отсутствия фокусировки на основной вид деятельности при слабой экспериментальной проработке исходной концепции у руководства, в ситуации выбора коллектив может оказаться парализован альтернативами вариантов своей деятельности. Как следствие, возможно нарушение сроков завершения работы.",
    "strategy_risk_pragmatic": "Возможно преждевременное завершение процесса работы или качественное решение второстепенных проблем руководством. Как следствие, в ситуации выбора коллектив может не различать проблемы и возможности из-за слабости рабочей концепции.",
    "motivation_org_professional": "Предоставьте возможность обучаться за счет компании, признавайте достижения, не контролируйте каждый рабочий шаг. Не оплачивайте обучение «профессионалов» просто так, ставьте цели, например, что з\\п будет повышена после обучения при условии улучшения результатов.",
    "motivation_org_patriot": "Благодарите заслуженных сотрудников на глазах у всего коллектива, отмечайте их заслуги визуально (доска почета, звание «лучший сотрудник»). Линейному руководителю предложите войти в состав совета директоров или посещать стратегические совещания. Если в компании патриотов много, разработайте положение о социальной защите работников.",
    "motivation_org_master": "Дайте возможность подразделению брать на себя полную ответственность за выполнение своей работы, делегируйте полномочия. Руководителю предложите курировать стратегические проекты компании, владеть акциями.",
    "motivation_org_lumpen": "Четко формулируйте задания и требования, причем они должны быть простейшие, например, работа на конвейере. Заботьтесь о сотрудниках данного подразделения как родная мать - применяйте методы кнута и пряника.",
    "punishment_org_professional": "Игнорируйте личные достижения самих сотрудников, ухудшите условия труда и лишите возможности учиться и развиваться, завалите рутинной работой.",
    "punishment_org_patriot": "Благодарите другие подразделения компании так, чтобы патриоты это видели. При этом патриотов не поощряйте. Можно пригрозить увольнением, лишить возможности «опекать» других сотрудников (перевести в другой отдел, филиал).",
    "punishment_org_master": "Такие сотрудники не терпят, когда их контролируют, указывают, что именно и как нужно делать.",
    "punishment_org_lumpen": "Пригрозите ключевым сотрудникам, что лишите возможности получать единственный доход, не помогайте решать материальные проблемы, лишите доплат и бонусов.",
    "risks_power_achievement_vitalist_ideolog_1": "Управляющая компания или коллектив экспертов, работающие на обеспечении какого-либо подряда под управлением авторитарного руководителя, диктатора. Организация направляет имеющиеся в её распоряжении наработки, правила, принципы и регламенты на улучшение качества жизни и семейное благополучие. ",
    "risks_power_achievement_vitalist_ideolog_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, юридическая, адвокатская компания, структура по экологическому контролю и др.) ",
    "risks_power_achievement_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_vitalist_ideolog_4": "Руководитель авторитарно-экспертного плана, диктатор, выросший из рядовых специалистов, который использует своё понимание корпоративных идей, правил и принципов ради укрепления и распространения своего собственного влияния на окружающих. ",
    "risks_power_achievement_vitalist_materialist_1": "Управляющая компания или коллектив экспертов, работающие на обеспечении какого-либо подряда под управлением авторитарного руководителя, диктатора. Организация направляет имеющиеся в её распоряжении материальные и финансовые ресурсы на улучшение качества жизни и семейное благополучие. ",
    "risks_power_achievement_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, УК ЖКХ.)",
    "risks_power_achievement_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_vitalist_materialist_4": "Потенциальный руководитель авторитарно-экспертного плана, диктатор, выросший из рядовых специалистов, который зарабатывает деньги и строит карьеру ради укрепления и распространения своего влияния на окружающих и благополучия своей семьи. В случае если руководитель не является собственником или совладельцем, появляется вероятность риска ухода со всеми наработками к другому работодателю в ситуации конфликта интересов семьи и работы. ",
    "risks_power_achievement_vitalist_emotional_1": "Управляющая компания или коллектив экспертов, работающие на обеспечении какого-либо подряда под управлением авторитарного руководителя, диктатора. Организация направляет имеющиеся в её распоряжении ресурсы или стратегии по манипулированию эмоциональным состоянием людей на улучшение качества жизни и семейное благополучие. ",
    "risks_power_achievement_vitalist_emotional_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, социологическая служба, рекламное агентство, event агентство, PR агентство.)",
    "risks_power_achievement_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_vitalist_emotional_4": "Руководитель авторитарно-экспертного плана, диктатор, выросший из рядовых специалистов, который использует свои достижения в области манипуляций эмоциями окружающих в качестве средства укрепления и распространения своего собственного влияния на окружающих, а также своего семейного благополучия. В случае если руководитель не является собственником или совладельцем, появляется вероятность риска коррупционной составляющей в виде использования служебного положения в личных целях, а также саботажа с целью повышения собственной важности в глазах руководства или вследствие обиды на «несправедливое отношение». ",
    "risks_power_achievement_ideolog_vitalist_1": "Контролирующая организация под управлением авторитарного руководителя, диктатора или надзорный орган, которые подчиняют свою жизнь уставу, регламенту или принципам, следя за их соблюдением другими людьми. ",
    "risks_power_achievement_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, прокуратура, СЭС.)",
    "risks_power_achievement_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_ideolog_vitalist_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который навязывает свои идеи, правила и принципы окружающим ради укрепления и распространения своего собственного влияния на них. В случае если руководитель не является собственником или совладельцем, появляется вероятность риска идейных противоречий с вышестоящим руководством. ",
    "risks_power_achievement_ideolog_materialist_1": "Организация-инвестор, которая направляет финансовые потоки и ресурсы на поддержание определенных регламентов и программ, отслеживая их использование по назначению. ",
    "risks_power_achievement_ideolog_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, инвестиционные фонды, финансирующие те или иные программы или организации и отслеживающие целевое использование вложенных средств.)",
    "risks_power_achievement_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_ideolog_materialist_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который использует свои достижения в области материального успеха ради торжества своих идей, правил и принципов в области управления. ",
    "risks_power_achievement_ideolog_emotional_1": "Организация, которая занимается рекламой, PR, HR, пропагандой любых видов деятельности, направляющая эмоциональные ресурсы и креатив своих сотрудников на обеспечение функционирования определенных алгоритмов, программ и концепций.",
    "risks_power_achievement_ideolog_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, ООО СПФИ.)",
    "risks_power_achievement_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_ideolog_emotional_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, манипулятор и пропагандист, который направляет свои достижения в области эмоционального влияния на точное соблюдение наработанных им определенных схем, алгоритмов и правил. ",
    "risks_power_achievement_materialist_vitalist_1": "Организация, которая использует физические возможности и ресурсы своих сотрудников ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_power_achievement_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, фитнес-центры, модельное агентство, стриптизеры, танцевальные коллективы, команды легионеров в различных видах спорта, коммерческая структура, ориентированная на получение прибыли от продаж чего бы то, ни было.)",
    "risks_power_achievement_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_materialist_vitalist_4": "Руководитель авторитарно-экспертного плана, диктатор у себя в подразделении, выросший из рядовых специалистов, манипулятор и притворщик, который жертвует собственным семейным благополучием ради увеличения своего статуса в области материальных благ и социального успеха. ",
    "risks_power_achievement_materialist_ideolog_1": "Организация, которая занимается продажей всевозможных информационных ресурсов, программ, систем и алгоритмов ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_power_achievement_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, коммерческая структура, ориентированная на получение прибыли от продаж определенной информации, газеты, журналы, информационные порталы, фирмы, занимающиеся «инфо-бизнесом».)",
    "risks_power_achievement_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_materialist_ideolog_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который вкладывает свои опыт и знания в увеличение масштабов своего влияния в области материального благополучия и социального успеха. ",
    "risks_power_achievement_materialist_emotional_1": "Организация, которая занимается продажей развлечений ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_power_achievement_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, аниматоры, продюсерские агентства, event агентства, эстрадные команды и коллективы.) ",
    "risks_power_achievement_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_materialist_emotional_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, манипулятор и притворщик, который жертвует собственным эмоциональным комфортом ради увеличения своего статуса в области материальных благ и социального успеха. ",
    "risks_power_achievement_emotional_vitalist_1": "Организация, которая направляет жизненные ресурсы своих сотрудников на формирование и удовлетворение духовных и эмоциональных потребностей общества. ",
    "risks_power_achievement_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, театральные труппы, альтернативное искусство, киноискусство.) ",
    "risks_power_achievement_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_emotional_vitalist_4": "Руководитель авторитарно-экспертного плана, «самодур», выросший из рядовых специалистов, который использует своё умение подчинять окружающих своему влиянию, ради своего эмоционального комфорта. (Виктюк, Гришковец, Станиславский) ",
    "risks_power_achievement_emotional_ideolog_1": "Жесткая, иерархическая организация, ориентированная на подчинение всевозможных догм, правил и законов эмоциональному удовлетворению её руководства. ",
    "risks_power_achievement_emotional_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "risks_power_achievement_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_emotional_ideolog_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, диктатор и «самодур», который использует своё понимание корпоративных идей, правил и принципов ради подчинения окружающих своему влиянию. ",
    "risks_power_achievement_emotional_materialist_1": "Организация, направляющая материальные ресурсы на эмоциональное удовлетворение её руководства. ",
    "risks_power_achievement_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, «11 друзей Оушена», команда покерных игроков.) ",
    "risks_power_achievement_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_achievement_emotional_materialist_4": "Руководитель авторитарно-экспертного плана, «азартный игрок», который вкладывает свои достижения максимального результата в области материального благополучия в обеспечение собственного максимального эмоционального комфорта. ",
    "risks_power_avoid_vitalist_emotional_1": "Политическая структура, общественное движение, правозащитная организация, разрушающие чужие отношения, достижения и эмоции ради распространения своего влияния на как можно больше количества людей. ",
    "risks_power_avoid_vitalist_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, движения Навального.) ",
    "risks_power_avoid_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_vitalist_emotional_4": "Руководитель авторитарно-бюрократического плана, ловкий манипулятор, провокатор, который стремится избежать ответственности за разрушение своей деятельностью эмоционального комфорта других людей, ради увеличения своей личной власти. (Навальный)",
    "risks_power_avoid_vitalist_ideolog_1": "Общественное движение или псевдорелигиозная организация сектантского типа, разрушающие чужие отношения, достижения и эмоции ради распространения своего влияния на как можно больше количества людей. ",
    "risks_power_avoid_vitalist_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, Аум Синрикё, свидетели Иеговы, пятидесятники.) ",
    "risks_power_avoid_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_vitalist_ideolog_4": "Руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради укрепления своей личной власти. ",
    "risks_power_avoid_vitalist_materialist_1": "Контролирующая организация или надзорный орган, разрушающие чужие отношения и достижения в области материального благополучия ради распространения своего влияния на как можно больше количества людей и улучшения качества жизни других людей. ",
    "risks_power_avoid_vitalist_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, Счетная палата, КРУ, ОБЭП, налоговая полиция.) ",
    "risks_power_avoid_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_vitalist_materialist_4": "Руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за разрушение материального обеспечения других людей ради укрепления своей личной власти и семейного благополучия. ",
    "risks_power_avoid_materialist_vitalist_1": "Общественное движение или псевдорелигиозная организация, разрушающие чужие отношения и достижения в области семейного благополучия и качества жизни ради распространения своего влияния на как можно больше количества людей, а также увеличения своего материального благосостояния. ",
    "risks_power_avoid_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, саентология, финансовые пирамиды, различные секты, ЧВК.) ",
    "risks_power_avoid_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_materialist_vitalist_4": "Руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за разрушение своей деятельностью привычного образа жизни других людей, ради своего материального благополучия. ",
    "risks_power_avoid_materialist_ideolog_1": "Жесткая, иерархическая организация авторитарно-бюрократического типа, разрушающая чужие отношения и достижения в отношении различного рода информации, концепций, идей, алгоритмов и правил ради увеличения своего материального благосостояния. ",
    "risks_power_avoid_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, организации, зарабатывающие на шпионаже различного рода и конкурентной разведке, секты, псевдорелигиозные организации, сетевые пирамиды.) ",
    "risks_power_avoid_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_materialist_ideolog_4": "Руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради увеличения масштабов своего влияния в области материального благополучия и социального успеха. ",
    "risks_power_avoid_materialist_emotional_1": "Жесткая, иерархическая организация авторитарно-бюрократического типа, разрушающая чужие отношения и достижения в области эмоционального комфорта ради увеличения своего материального благосостояния. ",
    "risks_power_avoid_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, адвокатские организация, специализирующиеся на разводах; тренинги по pick up, секты, псевдорелигиозные организации, сетевые пирамиды.) ",
    "risks_power_avoid_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_materialist_emotional_4": "Руководитель авторитарно-бюрократического плана, манипулятор и притворщик, который жертвует своим и чужим эмоциональным комфортом ради увеличения своего материального и социального благополучия. ",
    "risks_power_avoid_emotional_vitalist_1": "Общественное движение или закрытые клубы по интересам, специализирующиеся на разрушении семейных отношений и ценностей в угоду удовлетворения собственных эмоциональных потребностей. ",
    "risks_power_avoid_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, штаб-квартиры различных движений нетрадиционной сексуальной ориентации.) ",
    "risks_power_avoid_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_emotional_vitalist_4": "Руководитель авторитарно-бюрократического плана, «самодур» и диктатор, который ради своего эмоционального комфорта стремится избежать ответственности за разрушение своей деятельностью привычного образа жизни других людей.",
    "risks_power_avoid_emotional_ideolog_1": "Жесткая, иерархическая организация, ориентированная на нарушение и разрушение всевозможных догм, правил и законов ради эмоционального удовлетворения её руководства. ",
    "risks_power_avoid_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные криминальные структуры, организации, занимающиеся различными видами шпионажа и рейдерскими захватами.) ",
    "risks_power_avoid_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_emotional_ideolog_4": "Руководитель авторитарно-бюрократического плана, который жертвует корпоративными стандартами правилами и принципами ради подчинения окружающих своему влиянию. ",
    "risks_power_avoid_emotional_materialist_1": "Криминальная организация, разрушающая чужие материальные ресурсы и социальный успех ради эмоционального удовлетворения её руководства. ",
    "risks_power_avoid_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, ОПГ, военизированная структура, занятая рейдерскими захватами.) ",
    "risks_power_avoid_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_emotional_materialist_4": "Руководитель авторитарно-бюрократического плана, «азартный игрок», который жертвует своим материальным благополучием ради подчинения окружающих своему влиянию, покупает подчинение своих сотрудников. ",
    "risks_power_avoid_ideolog_vitalist_1": "Государственные контролирующая организация, силовая структура, надзорный орган, которые жертвуют качеством жизни своих сотрудников в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_power_avoid_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, армия, прокуратура, полиция.) ",
    "risks_power_avoid_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_ideolog_vitalist_4": "Руководитель авторитарно-бюрократического плана, выросший из рядовых специалистов, который жертвует качеством своей жизни в пользу соблюдения определенных законов, стандартов и правил. Основной принцип деятельности - «жизнь по правилу» или «жизнь по закону». ",
    "risks_power_avoid_ideolog_materialist_1": "Государственные контролирующая организация, силовая структура, надзорный орган, которые жертвуют материальным благополучием своих сотрудников и окружающих людей в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_power_avoid_ideolog_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, налоговая полиция, военные производства.) ",
    "risks_power_avoid_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_ideolog_materialist_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который жертвует материальным благополучием ради соблюдения своих идей, правил и принципов. ",
    "risks_power_avoid_ideolog_emotional_1": "Государственные контролирующая организация, силовая структура, надзорный орган, которые жертвуют эмоциональным комфортом своих сотрудников и окружающих людей в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_power_avoid_ideolog_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, идеологически пропагандистские организации, благотворительные фонды, социальная реклама.) ",
    "risks_power_avoid_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_avoid_ideolog_emotional_4": "Руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, манипулятор и пропагандист, который жертвует своим эмоциональным комфортом ради точного соблюдения наработанных им определенных схем, алгоритмов и правил. Эти стандарты он навязывает подчинённым и окружающим с целью увеличения своей личной власти. ",
    "risks_power_involvement_vitalist_ideolog_1": "Управляющая компания или административно-бюрократическая структура, направляющие имеющиеся в её распоряжении наработки, правила, принципы и регламенты на улучшение качества жизни и семейное благополучие. ",
    "risks_power_involvement_vitalist_ideolog_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, комитет по защите прав человека, комитет по защите прав семьи.) ",
    "risks_power_involvement_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_vitalist_ideolog_4": "Руководитель административно-бюрократического плана, который поддерживает устоявшиеся корпоративные стандарты и правила ради сохранения и поддержания определённого качества жизни своей семьи.",
    "risks_power_involvement_vitalist_emotional_1": "Управляющая компания или коллектив, работающие по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, направляющий имеющиеся в его распоряжении стратегии по манипулированию эмоциональным состоянием людей на улучшение качества жизни и семейное благополучие. ",
    "risks_power_involvement_vitalist_emotional_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, комитеты по трудоустройству ветеранов, различные комитеты в рамках общественных и социальных движений по работе с населением.)",
    "risks_power_involvement_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_vitalist_emotional_4": "Руководитель административно-бюрократического плана, манипулятор, который использует эмоциональные отношения и своё умение манипулировать окружающими ради распространения и укрепления своей власти на работе. Управление по принципу «королевский двор». Предпочитает окружать себя в большей степени верными и преданными людьми, нежели чем профессиональными и эффективными. Своих «придворных» он расставляет по местам, руководствуясь интересами своей вотчины. Работает ради ощущения «власти» и собственной вседозволенности. ",
    "risks_power_involvement_vitalist_materialist_1": "Управляющая компания или коллектив, работающие по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, направляющий имеющиеся в его распоряжении материальные и финансовые ресурсы на улучшение качества жизни и семейное благополучие. ",
    "risks_power_involvement_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, любые бюрократические структуры городского или областного управления.)",
    "risks_power_involvement_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_vitalist_materialist_4": "Руководитель административно-бюрократического типа, который вкладывает свои материальные ресурсы в увеличение личной власти в своей семье. ",
    "risks_power_involvement_ideolog_vitalist_1": "Управляющая компания или коллектив, работающие по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который подчиняет свою жизнь уставу, регламенту или принципам, следя за их соблюдением другими людьми. ",
    "risks_power_involvement_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, Рособлнадзор, управляющая и контролирующая структура над дошкольными и образовательными учреждениями.)",
    "risks_power_involvement_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_ideolog_vitalist_4": "Руководитель административно-бюрократического плана, который жёстко навязывает свои стандарты, идеи и правила окружающим ради укрепления своей личной власти. ",
    "risks_power_involvement_ideolog_materialist_1": "Управляющая компания или коллектив, работающие по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который направляет финансовые потоки и ресурсы на поддержание определенных регламентов и программ, отслеживая их использование по назначению. ",
    "risks_power_involvement_ideolog_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, государственные инвестиционные фонды, выделяющие финансирование на различные социальные проекты, мат. капитал.)",
    "risks_power_involvement_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_ideolog_materialist_4": "Руководитель административно-бюрократического типа, который использует свою причастность к материальным средствам ради насаждения своих идей, правил и принципов.",
    "risks_power_involvement_ideolog_emotional_1": "Управляющая компания или коллектив, работающие по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который занимается PR, HR, различной образовательной деятельностью, направляющие административные и эмоциональные ресурсы и креатив своих сотрудников на обеспечение функционирования определенных алгоритмов, программ и концепций.",
    "risks_power_involvement_ideolog_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, АНО ДПО, министерство культуры, министерство образования, комитет по кинематографии.)",
    "risks_power_involvement_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_ideolog_emotional_4": "Руководитель административно-бюрократического плана, который подчиняет себе эмоции окружающих его людей ради торжества своих идей и правил. ",
    "risks_power_involvement_materialist_vitalist_1": "Управляющая компания или коллектив, работающие по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который использует физические возможности и ресурсы своих сотрудников ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_power_involvement_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, различные коммерческие организации с гос. участием, работающие на обеспечении собственного материального благополучия, Газпром, Роснефть, Транснефть.)",
    "risks_power_involvement_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_materialist_vitalist_4": "Руководитель административно-бюрократического плана, который поддерживает личные отношения ради собственного максимального и социального благополучия. ",
    "risks_power_involvement_materialist_ideolog_1": "Коллектив, работающий по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который занимается продажей всевозможных информационных ресурсов, программ, систем и алгоритмов ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_power_involvement_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, различные экспертные бюро, следящие за соблюдением стандартов и нормативов качества.)",
    "risks_power_involvement_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_materialist_ideolog_4": "Руководитель административно-бюрократического плана, который использует идеи и принципы других людей ради увеличения своего личного материального благополучия и социального успеха. ",
    "risks_power_involvement_materialist_emotional_1": "Коллектив, работающий по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который занимается продажей развлечений ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_power_involvement_materialist_emotional_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, центр социальной помощи семьи и детям, психологические социальные службы при НОУ, ДОУ, МОУ, различные бюджетные центры службы общества по культурно-массовым мероприятиям.) ",
    "risks_power_involvement_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_materialist_emotional_4": "Руководитель административно-бюрократического плана, манипулятор и притворщик, который подчиняет себе эмоции окружающих его людей ради увеличения своего личного материального благополучия и социального успеха. ",
    "risks_power_involvement_emotional_vitalist_1": "Бюджетный творческий коллектив, работающий по принципу «королевский двор» под управлением руководителя административно-бюрократического типа, который направляет жизнь своих сотрудников на удовлетворения эмоциональных потребностей её руководства. ",
    "risks_power_involvement_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, театр, музыкальные труппы.) ",
    "risks_power_involvement_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_emotional_vitalist_4": "Руководитель административно-бюрократического плана, который поддерживает личные отношения ради собственного эмоционального комфорта и благополучия. ",
    "risks_power_involvement_emotional_ideolog_1": "Общественное движение или псевдорелигиозная организация сектантского типа, направляющие имеющиеся в их распоряжении различные идеологические ресурсы, концепции, правила и алгоритмы законов на эмоциональное удовлетворение своего руководства. ",
    "risks_power_involvement_emotional_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, движение Кришны.) ",
    "risks_power_involvement_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_emotional_ideolog_4": "Руководитель административно-бюрократического плана, «капризный» манипулятор, который использует идеи и принципы подчинённых ему людей ради своего эмоционального комфорта. Управление по принципу «королевский двор». Предпочитает окружать себя в большей степени верными и преданными людьми, нежели чем профессиональными и эффективными. Своих «придворных» он расставляет по местам, руководствуясь интересами своей вотчины. Работает ради ощущения «власти» и собственной вседозволенности. ",
    "risks_power_involvement_emotional_materialist_1": "Бюджетная организация, направляющая материальные ресурсы по установленным схемам, процедурам и алгоритмам на эмоциональное удовлетворение её руководства и различные культурно-массовые мероприятия. ",
    "risks_power_involvement_emotional_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, министерство спорта, МОК, МС, министерство по вопросам спорта, туризма и молодёжной политики.) ",
    "risks_power_involvement_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_power_involvement_emotional_materialist_4": "Руководитель административно-бюрократического плана, «азартный игрок», который поддерживает взаимовыгодные материальные отношения ради достижения собственного максимального эмоционального комфорта. ",
    "risks_achievement_power_vitalist_ideolog_1": "Небольшая развивающаяся команда экспертов, направляющая имеющиеся в её распоряжении наработки, правила, принципы и регламенты на улучшение качества жизни и семейное благополучие. ",
    "risks_achievement_power_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, фитнес-студии, студии йоги, кафе вегетарианского питания.) ",
    "risks_achievement_power_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_vitalist_ideolog_4": "Руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который навязывает свои идеи, правила и принципы окружающим на работе ради максимального улучшения своего качества жизни и благополучия своей семьи. ",
    "risks_achievement_power_vitalist_materialist_1": "Небольшая развивающаяся команда экспертов, направляющая имеющиеся в её распоряжении материальные и финансовые ресурсы на улучшение качества жизни и семейное благополучие. ",
    "risks_achievement_power_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, любое производственное объединение, продающее свою продукцию.)",
    "risks_achievement_power_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_vitalist_materialist_4": "Руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который направляет свои личные амбиции и материальные ресурсы на улучшение качества своей жизни и благополучие семьи. ",
    "risks_achievement_power_vitalist_emotional_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, направляющая имеющиеся в её распоряжении ресурсы и стратегии по манипулированию эмоциональным состоянием людей на улучшение качества жизни и семейное благополучие. ",
    "risks_achievement_power_vitalist_emotional_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, начинающие социологическая служба, рекламное агентство, event агентство, PR агентство.)",
    "risks_achievement_power_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_vitalist_emotional_4": "Руководитель службы, выросший из рядовых специалистов, направляющий свои личные амбиции и умение манипулировать окружающими людьми на сохранение и поддержание своего привычного образа жизни, и улучшение качества жизни своей семьи. ",
    "risks_achievement_power_ideolog_vitalist_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая подчиняет свою жизнь уставу, регламенту или принципам, следя за их соблюдением другими людьми. ",
    "risks_achievement_power_ideolog_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, проектное бюро, производственно-технический отдел, ОКС.)",
    "risks_achievement_power_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_ideolog_vitalist_4": "Руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который распространяет своё влияние на окружающих ради точного соблюдения наработанных им определенных схем, алгоритмов и правил, корпоративных стандартов. ",
    "risks_achievement_power_ideolog_materialist_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая направляет финансовые потоки и ресурсы на поддержание определенных регламентов и программ, отслеживая их использование по назначению. ",
    "risks_achievement_power_ideolog_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, небольшие НПО, разрабатывающие различные технологии и гаджеты под заказ.)",
    "risks_achievement_power_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_ideolog_materialist_4": "Руководитель службы, профессиональной экспертной группы, выросший из рядовых специалистов, который использует свой контроль над распределением материальных ресурсов ради точного соблюдения наработанных им определенных схем, алгоритмов и правил. ",
    "risks_achievement_power_ideolog_emotional_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая занимается рекламой, PR, HR, пропагандой любых видов деятельности, направляющие эмоциональные ресурсы и креатив своих сотрудников на обеспечение функционирования определенных алгоритмов, программ и концепций.",
    "risks_achievement_power_ideolog_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, ООО СПФИ.)",
    "risks_achievement_power_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_ideolog_emotional_4": "Руководитель профессиональной экспертной группы, выросший из рядовых специалистов, который жертвует своим эмоциональным комфортом ради точного соблюдения собственных, выработанных на практике схем, алгоритмов и правил. ",
    "risks_achievement_power_materialist_vitalist_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая использует физические возможности и ресурсы своих сотрудников ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_achievement_power_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, автомойка, кафе для семьи.)",
    "risks_achievement_power_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_materialist_vitalist_4": "Руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который использует своё влияние на окружающих в качестве средства достижения высоких результатов в области максимального материального благополучия и социального успеха. ",
    "risks_achievement_power_materialist_ideolog_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая занимается продажей всевозможных информационных ресурсов, программ, систем и алгоритмов ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_achievement_power_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, SMM, «локация» игр, инфо-бизнес.) ",
    "risks_achievement_power_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_materialist_ideolog_4": "Руководитель небольшой службы специалистов, выросший из рядовых специалистов, направляющий свои личные амбиции идеи и принципы на достижение своего стабильного материального благополучия. ",
    "risks_achievement_power_materialist_emotional_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая занимается продажей развлечений ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_achievement_power_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, аниматоры, event агентства, эстрадные команды и коллективы.) ",
    "risks_achievement_power_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_materialist_emotional_4": "Руководитель службы, выросший из рядовых специалистов, манипулятор и притворщик, направляющий свои эмоции и умение манипулировать окружающими людьми на достижение своего максимального материального благополучия. ",
    "risks_achievement_power_emotional_vitalist_1": "Небольшая развивающаяся, клиентоориентированная команда экспертов, которая направляет жизненные ресурсы своих сотрудников на формирование и удовлетворение духовных и эмоциональных потребностей общества. ",
    "risks_achievement_power_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, театральные художественные труппы.) ",
    "risks_achievement_power_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_emotional_vitalist_4": "Руководитель службы, выросший из рядовых специалистов, направляющий свои личные амбиции и привычный образ жизни на достижение своего максимального эмоционального комфорта. ",
    "risks_achievement_power_emotional_ideolog_1": "Небольшая развивающаяся команда специалистов, которая ориентирована на подчинение всевозможных догм, правил и законов эмоциональному удовлетворению её руководства. ",
    "risks_achievement_power_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные криминальные структуры, организации, занимающиеся различными видами шпионажа и конкурентной разведкой.) ",
    "risks_achievement_power_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_emotional_ideolog_4": "Авторитарный руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, диктатор и «самодур», который может жертвовать точным соблюдением определенных корпоративных схем, алгоритмов и правил ради своего эмоционального комфорта. ",
    "risks_achievement_power_emotional_materialist_1": "Небольшая развивающаяся команда экспертов, которая направляет материальные ресурсы на эмоциональное удовлетворение её руководства. ",
    "risks_achievement_power_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, команда покерных игроков, работающая под заказ.) ",
    "risks_achievement_power_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_power_emotional_materialist_4": "Руководитель службы, региональный представитель, выросший из рядовых специалистов, «азартный игрок», жертвующий своим материальным благополучием ради достижения своего максимального эмоционального комфорта. ",
    "risks_achievement_avoid_vitalist_ideolog_1": "Государственная силовая структура или контролирующий орган, разрушающие чужие отношения и эмоции ради достижения высоких результатов в конкретной профессиональной области. ",
    "risks_achievement_avoid_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, нотариус, адвокатское бюро, органы опеки.) ",
    "risks_achievement_avoid_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_vitalist_ideolog_4": "Специалист-одиночка, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради достижения своего максимального семейного благополучия. ",
    "risks_achievement_avoid_vitalist_materialist_1": "Силовая структура или контролирующий орган, разрушающие чужие отношения в области материального обеспечения ради высоких результатов в контексте семейного благополучия. ",
    "risks_achievement_avoid_vitalist_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, надзорный орган, юридические консультации, специализирующиеся по разводам, разделу имущества, оформлению наследства, юристы-«судебники».) ",
    "risks_achievement_avoid_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_vitalist_materialist_4": "Специалист-одиночка, который жертвует своим материальным благополучием и социальным успехом в пользу улучшения качества жизни своей семьи ",
    "risks_achievement_avoid_vitalist_emotional_1": "Политическая, силовая структура или орган пропаганды, разрушающие чужие отношения и эмоции ради высоких результатов в области качества жизни. ",
    "risks_achievement_avoid_vitalist_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, общественно-политические движения ветеранов различных силовых структур и участников боевых действий в горячих точках.) ",
    "risks_achievement_avoid_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_vitalist_emotional_4": "Специалист-одиночка, манипулятор, который жертвует своим эмоциональным комфортом ради максимального улучшения качества своей жизни и своего семейного благополучия. ",
    "risks_achievement_avoid_ideolog_vitalist_1": "Команда специалистов, которая жертвует качеством жизни своих сотрудников в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_achievement_avoid_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные отделы прокуратуры и полиции.) ",
    "risks_achievement_avoid_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_ideolog_vitalist_4": "Специалист-одиночка, манипулятор, который стремится избежать ответственности за свои семейные отношения и привычный образ жизни ради точного соблюдения определенных схем, алгоритмов и правил. ",
    "risks_achievement_avoid_ideolog_materialist_1": "Команда специалистов, которая жертвует материальным благополучием своих сотрудников и окружающих людей в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_achievement_avoid_ideolog_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы налоговой полиции, военных производств.) ",
    "risks_achievement_avoid_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_ideolog_materialist_4": "Специалист-одиночка, который жертвует своим материальным благополучием ради точного соблюдения определенных схем, алгоритмов и правил. ",
    "risks_achievement_avoid_ideolog_emotional_1": "Команда специалистов, которая жертвует эмоциональным комфортом своих сотрудников и окружающих людей в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_achievement_avoid_ideolog_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные отделения различных идеологически пропагандистских организаций, благотворительных программ, социальной рекламы.)",
    "risks_achievement_avoid_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_ideolog_emotional_4": "Специалист-одиночка, который жертвует своим эмоциональным комфортом ради точного соблюдения определенных схем, алгоритмов и правил. ",
    "risks_achievement_avoid_materialist_vitalist_1": "Небольшая команда наёмников-экспертов в своей области, разрушающая чужие отношения и достижения в области семейного благополучия и качества жизни ради распространения своего влияния на как можно больше количества людей, а также увеличения своего материального благосостояния. ",
    "risks_achievement_avoid_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, различные объединения наёмников в контексте силовых структур, ЧВК.)",
    "risks_achievement_avoid_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_materialist_vitalist_4": "Специалист-одиночка, который жертвует своим привычным образом жизни ради достижения своего максимального материального благополучия. ",
    "risks_achievement_avoid_materialist_ideolog_1": "Команда специалистов, разрушающая чужие отношения и достижения в отношении различного рода информации, концепций, идей, алгоритмов и правил ради увеличения своего материального благосостояния. ",
    "risks_achievement_avoid_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, организации, зарабатывающие на шпионаже различного рода и конкурентной разведке, команда хакеров.)",
    "risks_achievement_avoid_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_materialist_ideolog_4": "Специалист-одиночка, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради достижения своего материального благополучия и социального успеха. ",
    "risks_achievement_avoid_materialist_emotional_1": "Команда специалистов, разрушающая чужие отношения и достижения в области эмоционального комфорта ради увеличения своего материального благосостояния. ",
    "risks_achievement_avoid_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, местные филиалы всевозможных сект, псевдорелигиозных организаций, сетевых пирамид, тренингов по pick up.) ",
    "risks_achievement_avoid_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_materialist_emotional_4": "Специалист-одиночка, который жертвует своим эмоциональным комфортом ради достижения своего материального благополучия и социального успеха. ",
    "risks_achievement_avoid_emotional_vitalist_1": "Псевдорелигиозная организация сектантского типа, специализирующаяся на разрушении семейных отношений и ценностей в угоду удовлетворения собственных эмоциональных потребностей. ",
    "risks_achievement_avoid_emotional_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных псевдорелигиозных организаций, клуб по интересам.) ",
    "risks_achievement_avoid_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_emotional_vitalist_4": "Специалист-одиночка, который жертвует своим привычным образом жизни ради достижения своего эмоционального удовлетворения от проделанной работы. ",
    "risks_achievement_avoid_emotional_ideolog_1": "Команда экспертов, ориентированная на нарушение и разрушение всевозможных догм, правил и законов ради эмоционального удовлетворения её руководства. ",
    "risks_achievement_avoid_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных организаций, занятых конкурентной разведкой и различных форм шпионажа.)",
    "risks_achievement_avoid_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_emotional_ideolog_4": "Специалист-одиночка без перспектив карьерного роста, «самодур», который избегает ответственности за создание управленческих решений и алгоритмов. Следует утверждённым корпоративным стандартам ради достижения своего максимального эмоционального комфорта. Иногда его «самодурство» может приводить к жестким конфликтам с более эффективными коллегами на работе. ",
    "risks_achievement_avoid_emotional_materialist_1": "Криминальная организация или военизированная структура, разрушающие чужие материальные ресурсы и социальный успех ради эмоционального удовлетворения её руководства. ",
    "risks_achievement_avoid_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, команды наемников, черных пиарщиков.)",
    "risks_achievement_avoid_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_avoid_emotional_materialist_4": "Специалист-одиночка, «азартный игрок», который стремится избежать ответственности за свое материальное благополучие ради достижения собственного максимального эмоционального комфорта.",
    "risks_achievement_involvement_vitalist_ideolog_1": "Небольшая команда специалистов, которая направляет имеющиеся в её распоряжении наработки, правила, принципы и регламенты на улучшение качества жизни и семейное благополучие. ",
    "risks_achievement_involvement_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные фитнес-студии, вегетарианские кафе, передачи про здоровье.)",
    "risks_achievement_involvement_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_vitalist_ideolog_4": "Специалист-одиночка, который направляет своё умение следовать существующим утвержденным корпоративным стандартам ради максимального улучшения качества своей жизни и своего семейного благополучия. ",
    "risks_achievement_involvement_vitalist_materialist_1": "Небольшая команда специалистов, направляющая имеющиеся в её распоряжении материальные и финансовые ресурсы на улучшение качества жизни и семейное благополучие. ",
    "risks_achievement_involvement_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, маленькие семейные бизнес-структуры: сыроварни, коптильни, фермерские цеха.) ",
    "risks_achievement_involvement_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_vitalist_materialist_4": "Специалист-одиночка, который вкладывает своё материальное благополучие в максимальное улучшение качества своей жизни. ",
    "risks_achievement_involvement_vitalist_emotional_1": "Небольшая команда специалистов, направляющая имеющиеся в её распоряжении стратегии по манипулированию эмоциональным состоянием людей на улучшение качества жизни и семейное благополучие. ",
    "risks_achievement_involvement_vitalist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, медийные организации, команда блогеров, нацеленные на социальные благополучия.)",
    "risks_achievement_involvement_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_vitalist_emotional_4": "Специалист-одиночка без перспектив карьерного роста, «проектный боец», наёмник под конкретную задачу или функционал, манипулятор, который платит своим эмоциональным комфортом за максимальное улучшение качества своей жизни. ",
    "risks_achievement_involvement_ideolog_vitalist_1": "Небольшая команда специалистов, которая подчиняет свою жизнь уставу, регламенту или принципам, следя за их соблюдением другими людьми. ",
    "risks_achievement_involvement_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, проектное бюро, производственно-технический отдел.)",
    "risks_achievement_involvement_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_ideolog_vitalist_4": "Специалист-одиночка без перспектив карьерного роста, наёмник под конкретную задачу или функционал, который платит своим привычным образом жизни за точное соблюдение определенных схем, алгоритмов и правил. ",
    "risks_achievement_involvement_ideolog_materialist_1": "Небольшая команда специалистов, которая направляет финансовые потоки и ресурсы на поддержание определенных регламентов и программ, отслеживая их использование по назначению. ",
    "risks_achievement_involvement_ideolog_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, старт-ап, тендерные комитеты, краудфандинговые платформы.)",
    "risks_achievement_involvement_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_ideolog_materialist_4": "Специалист-одиночка, который платит своим материальным благополучием за точное соблюдение определенных схем, алгоритмов и правил. ",
    "risks_achievement_involvement_ideolog_emotional_1": "Небольшая команда специалистов, которая занимается рекламой, PR, HR, пропагандой любых видов деятельности, направляющие эмоциональные ресурсы и креатив своих сотрудников на обеспечение функционирования определенных алгоритмов, программ и концепций. ",
    "risks_achievement_involvement_ideolog_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, ООО СПФИ, кафедра психологии и педагогики в различных ВУЗах, различные АНО ДПО.)",
    "risks_achievement_involvement_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_ideolog_emotional_4": "Специалист-одиночка с перспективами карьерного роста до руководителя среднего звена (небольшой группы специалистов), который жертвует своим эмоциональным комфортом ради точного соблюдения определенных схем, алгоритмов и правил. ",
    "risks_achievement_involvement_materialist_vitalist_1": "Небольшая команда специалистов, которая использует физические возможности и ресурсы своих сотрудников ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_achievement_involvement_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, коммерческий отдел, отдел продаж.)",
    "risks_achievement_involvement_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_materialist_vitalist_4": "Специалист-одиночка, «наёмник», который подчиняет свой образ жизни достижению максимального материального благополучия и социального успеха. ",
    "risks_achievement_involvement_materialist_ideolog_1": "Небольшая команда специалистов, которая занимается продажей всевозможных информационных ресурсов, программ, систем и алгоритмов ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_achievement_involvement_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, местные филиалы, \"Консультант +\", \"1С\", команды, занятые \"локацией\" игр.) ",
    "risks_achievement_involvement_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_materialist_ideolog_4": "Проектный боец, специалист-одиночка, наёмник, который направляет свои идеи, принципы и правила поведения на достижение высоких результатов в области своего материального благополучия. ",
    "risks_achievement_involvement_materialist_emotional_1": "Небольшая команда специалистов, которая занимается продажей развлечений ради достижения высоких результатов в области материального благополучия и социального успеха. ",
    "risks_achievement_involvement_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, развлекательные эстрадные коллективы-гастролеры.)",
    "risks_achievement_involvement_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_materialist_emotional_4": "Специалист-одиночка, который направляет своё умение выстраивать прочные эмоциональные связи и отношения ради достижения высоких результатов в области своего материального благополучия. ",
    "risks_achievement_involvement_emotional_vitalist_1": "Небольшая команда специалистов, которая направляет жизненные ресурсы своих сотрудников на формирование и удовлетворение духовных и эмоциональных потребностей общества. ",
    "risks_achievement_involvement_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, event-агентство, арт-студия, театральные студии.)",
    "risks_achievement_involvement_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_emotional_vitalist_4": "Специалист-одиночка, который направляет своё умение выстраивать прочные отношения в семье ради достижения собственного максимального эмоционального комфорта. ",
    "risks_achievement_involvement_emotional_ideolog_1": "Небольшая команда специалистов, которая ориентирована на подчинение всевозможных догм, правил и законов эмоциональному удовлетворению её руководства. ",
    "risks_achievement_involvement_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, команда троллинга спамом, пранками, выпускающие фейки в социальных сетях.) ",
    "risks_achievement_involvement_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_emotional_ideolog_4": "Специалист-одиночка, который использует точное соблюдение определенных схем, алгоритмов и правил ради увеличения своего эмоционального комфорта. Иногда его «самодурство» может зашкаливать, что чревато появлением интриг и серьезных конфликтов с более эффективными коллегами на работе. ",
    "risks_achievement_involvement_emotional_materialist_1": "Небольшая команда специалистов, которая направляет материальные ресурсы на эмоциональное удовлетворение её руководства. ",
    "risks_achievement_involvement_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, команда покерных игроков, работающая под заказ, казино, различные компьютерные игорные клубы.) ",
    "risks_achievement_involvement_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_achievement_involvement_emotional_materialist_4": "Специалист-одиночка, «азартный игрок», который поддерживает взаимовыгодные материальные отношения ради достижения собственного максимального эмоционального комфорта. ",
    "risks_avoid_power_vitalist_ideolog_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области морали, нравственности и семейного благополучия ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, социальное движение Чайлдфри, свингерское движение, движение гомосексуального типа.) ",
    "risks_avoid_power_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_vitalist_ideolog_4": "Исполнитель без перспектив карьерного роста, который жертвует своими принципами и определенными алгоритмами ради избегания ответственности за привычный образ жизни и семейные отношения. ",
    "risks_avoid_power_vitalist_materialist_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области семейного и материального благополучия ради избегания ответственности за собственные решения. ",
    "risks_avoid_power_vitalist_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, черные риэлторы, движение за пересмотр права собственности на Украине.) ",
    "risks_avoid_power_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_vitalist_materialist_4": "Исполнитель без перспектив карьерного роста, который жертвует своим материальным благополучием ради избегания ответственности за семейные отношения. ",
    "risks_avoid_power_vitalist_emotional_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области семейного благополучия ради избегания ответственности за собственные решения. ",
    "risks_avoid_power_vitalist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, всевозможные фашистские националистические и анархистские, псевдорелигиозные организации ультраправого толка.) ",
    "risks_avoid_power_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_vitalist_emotional_4": "Исполнитель без перспектив карьерного роста. Манипулятор, который избегает личной ответственности за принятые решения. ",
    "risks_avoid_power_ideolog_vitalist_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области различных идей, концепций, правил и семейного благополучия ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_ideolog_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, социальное движение Чайлдфри.) ",
    "risks_avoid_power_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_ideolog_vitalist_4": "Исполнитель без перспектив карьерного роста, который избегает личной ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради увеличения своего семейного благополучия. ",
    "risks_avoid_power_ideolog_materialist_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области различных идей, концепций, правил, материального благополучия и социального успеха ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_ideolog_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, \"инвестиционные\" фонды Сороса.) ",
    "risks_avoid_power_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_ideolog_materialist_4": "Исполнитель без перспектив карьерного роста, который избегает личной ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради увеличения своего материального благополучия.",
    "risks_avoid_power_ideolog_emotional_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области различных идей, концепций, правил и эмоционального комфорта ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_ideolog_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, небольшие группы специалистов, занимающиеся идеологическими диверсиями и провокациями: \"Белые каски\".) ",
    "risks_avoid_power_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_ideolog_emotional_4": "Исполнитель без перспектив карьерного роста, который избегает личной ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради сохранения своего эмоционального комфорта.",
    "risks_avoid_power_materialist_vitalist_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области семейного и материального благополучия и социального успеха ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных финансовых пирамид, МММ, Кэшбери.) ",
    "risks_avoid_power_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_materialist_vitalist_4": "Исполнитель без перспектив карьерного роста, который жертвует своим привычным образом жизни ради избегания ответственности за своё материальное благополучие. ",
    "risks_avoid_power_materialist_ideolog_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области материального благополучия, социального успеха, различных идей, концепций и правил ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, организации, зарабатывающие на шпионаже различного рода и конкурентной разведке, команда хакеров, Анонимус, WikiLeaks.) ",
    "risks_avoid_power_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_materialist_ideolog_4": "Исполнитель без перспектив карьерного роста, который жертвует своими принципами и определенными алгоритмами ради избегания ответственности за доступ к источнику своего материального благополучия. ",
    "risks_avoid_power_materialist_emotional_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области эмоционально комфорта, материального благополучия и социального успеха ради избегания ответственности за принятые решения.",
    "risks_avoid_power_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных сетевых и финансовых пирамид, секты.) ",
    "risks_avoid_power_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_materialist_emotional_4": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом ради избегания ответственности за доступ к источнику своего материального благополучия. ",
    "risks_avoid_power_emotional_vitalist_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области семейного благополучия и эмоционального комфорта ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_emotional_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, социальное движение хиппи, панки.) ",
    "risks_avoid_power_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_emotional_vitalist_4": "Исполнитель без перспектив карьерного роста, который использует своё умение влиять на окружающих ради разрушения эмоционального комфорта окружающих и своего собственного. ",
    "risks_avoid_power_emotional_ideolog_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области идей, концепций и правил, морали и нравственности ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных псевдорелигиозных организаций, сетевых пирамид.) ",
    "risks_avoid_power_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_emotional_ideolog_4": "Исполнитель без перспектив карьерного роста, провокатор и саботажник, который жертвует своими идеями, правилами и принципами ради разрушения эмоционального комфорта окружающих и своего собственного. Его «самодурство» провокаций и интриги могут привести к разрушению той структуры, в которой он работает. ",
    "risks_avoid_power_emotional_materialist_1": "Деструктивная организация или общественное движение анархистского типа, разрушающие чужие амбиции, достижения и отношения в области материального благополучия и материального комфорта ради избегания ответственности за принятые решения. ",
    "risks_avoid_power_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные объединения цыган, нищих, бродяг в Европе.) ",
    "risks_avoid_power_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_power_emotional_materialist_4": "Исполнитель без перспектив карьерного роста, «азартный игрок», провокатор, который жертвует своим материальным благополучием ради разрушения эмоционального комфорта окружающих и своего собственного. ",
    "risks_avoid_achievement_vitalist_ideolog_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области семейного благополучия через транслирование определенных идей, правил и принципов. ",
    "risks_avoid_achievement_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные общественные и социальные организации деструктивного типа, пропагандирующие идеи по развалу традиционного института семьи и брака, движение Чайлдфри, свингерское движение, движение гомосексуального типа.) ",
    "risks_avoid_achievement_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_vitalist_ideolog_4": "Исполнитель без перспектив карьерного роста, который жертвует своим собственными принципами в пользу избегания ответственности за своё семейное благополучие. ",
    "risks_avoid_achievement_vitalist_materialist_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области семейного благополучия через финансовое обеспечение. ",
    "risks_avoid_achievement_vitalist_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные инвестиционные фонды, финансирующие социальные программы по регуляции рождаемости.) ",
    "risks_avoid_achievement_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_vitalist_materialist_4": "Исполнитель без перспектив карьерного роста, который жертвует своим собственным материальным благополучием в пользу избегания ответственности за благополучие семьи. ",
    "risks_avoid_achievement_vitalist_emotional_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области семейного благополучия через эмоциональные манипуляции. ",
    "risks_avoid_achievement_vitalist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, адвокатские и детективные агентства, специализирующиеся на разводах и супружеской неверности.) ",
    "risks_avoid_achievement_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_vitalist_emotional_4": "Исполнитель без перспектив карьерного роста, который использует своё умение влиять на окружающих ради избегания личной ответственности за свои действия. ",
    "risks_avoid_achievement_ideolog_vitalist_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области института семьи и здоровья. ",
    "risks_avoid_achievement_ideolog_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, социальное движение Чайлдфри, клубы по интересам.) ",
    "risks_avoid_achievement_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_ideolog_vitalist_4": "Исполнитель без перспектив карьерного роста, который жертвует своим привычным образом жизни ради избегания ответственности за принятие управленческих решений, следуя установленным корпоративным стандартам. ",
    "risks_avoid_achievement_ideolog_materialist_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области идей, правил и принципов через финансирование. ",
    "risks_avoid_achievement_ideolog_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, \"инвестиционные\" фонды Сороса.) ",
    "risks_avoid_achievement_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_ideolog_materialist_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за определенные схемы и алгоритмы - источники его материального благополучия. ",
    "risks_avoid_achievement_ideolog_emotional_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области идей, правил и принципов через манипулирование людьми. ",
    "risks_avoid_achievement_ideolog_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, псевдорелигиозные, ультраправые организации.) ",
    "risks_avoid_achievement_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_ideolog_emotional_4": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом, следуя имеющимся корпоративным стандартам. ",
    "risks_avoid_achievement_materialist_vitalist_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области материального благополучия через разрушение семьи и здоровья. ",
    "risks_avoid_achievement_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные объединения наёмников в контексте негосударственных силовых структур, ЧВК.) ",
    "risks_avoid_achievement_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_materialist_vitalist_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за материальное благополучие своей семьи. ",
    "risks_avoid_achievement_materialist_ideolog_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области материального благополучия через навязывание различных идейных установок, правил и концепций. ",
    "risks_avoid_achievement_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, организации, зарабатывающие на шпионаже различного рода и конкурентной разведке, команда хакеров, Анонимус, WikiLeaks.) ",
    "risks_avoid_achievement_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_materialist_ideolog_4": "Исполнитель без перспектив карьерного роста, который жертвует своими идеями и принципами ради избегания ответственности за своё материальное благополучие. ",
    "risks_avoid_achievement_materialist_emotional_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области материального благополучия через эмоциональные манипуляции. ",
    "risks_avoid_achievement_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы всевозможных сект, псевдорелигиозных организаций, сетевых пирамид, тренингов по pick up.) ",
    "risks_avoid_achievement_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_materialist_emotional_4": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом ради избегания ответственности за своё материальное благополучие. «Терпит» и притворяется ради «бонусов» и зарплаты.",
    "risks_avoid_achievement_emotional_vitalist_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области эмоционального комфорта через разрушение семейного благополучия. ",
    "risks_avoid_achievement_emotional_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных псевдорелигиозных организаций, клуб по интересам, свингерские клубы.) ",
    "risks_avoid_achievement_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_emotional_vitalist_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за эмоциональный комфорт своей семьи. ",
    "risks_avoid_achievement_emotional_ideolog_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области эмоционального комфорта через навязывание различных идейных установок, правил и концепций. ",
    "risks_avoid_achievement_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных организаций, занятых конкурентной разведкой и различных форм шпионажа, команда троллинга, команда хакеров.) ",
    "risks_avoid_achievement_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_emotional_ideolog_4": "Исполнитель без перспектив карьерного роста, харизматичный саботажник, провокатор, интриган, который жертвует своими идеями и принципами ради избегания ответственности за собственный эмоциональный комфорт.",
    "risks_avoid_achievement_emotional_materialist_1": "Команда специалистов деструктивного типа, разрушающая чужие достижения, амбиции и отношения в области эмоционального комфорта через разрушение материального благополучия. ",
    "risks_avoid_achievement_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, казино, различные компьютерные игорные клубы.) ",
    "risks_avoid_achievement_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_achievement_emotional_materialist_4": "Исполнитель без перспектив карьерного роста, «азартный игрок», который жертвует своим материальным благополучием ради избегания ответственности за свой эмоциональный комфорт. ",
    "risks_avoid_involvement_vitalist_ideolog_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области семейного благополучия через транслирование определенных идей, правил и принципов. ",
    "risks_avoid_involvement_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных общественных и социальных организаций деструктивного типа, пропагандирующих идеи по развалу традиционного института семьи и брака, движение Чайлдфри, свингерское движение, движение гомосексуального типа.) ",
    "risks_avoid_involvement_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_vitalist_ideolog_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за свой формальный подход к работе по принципу «мне сказали - я делаю». ",
    "risks_avoid_involvement_vitalist_materialist_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области семейного благополучия через финансовое обеспечение. ",
    "risks_avoid_involvement_vitalist_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных инвестиционных фондов, финансирующих социальные программы по регуляции рождаемости.)",
    "risks_avoid_involvement_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_vitalist_materialist_4": "Исполнитель без перспектив карьерного роста, который использует свою причастность к материальному благополучию ради избегания ответственности за ситуацию в семье. ",
    "risks_avoid_involvement_vitalist_emotional_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области семейного благополучия через эмоциональные манипуляции. ",
    "risks_avoid_involvement_vitalist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, криминальные структуры, специализирующиеся на разрушение института семьи.)",
    "risks_avoid_involvement_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_vitalist_emotional_4": "Исполнитель без перспектив карьерного роста, манипулятор, который стремится избежать ответственности за своё семейное благополучие. ",
    "risks_avoid_involvement_ideolog_vitalist_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области различных идей, концепций, правил и семейного благополучия ради избегания ответственности за принятые решения. ",
    "risks_avoid_involvement_ideolog_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы псевдорелигиозных организаций и социальное движение Чайлдфри.)",
    "risks_avoid_involvement_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_ideolog_vitalist_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за своё семейное благополучие, следуя имеющимся корпоративным стандартам. ",
    "risks_avoid_involvement_ideolog_materialist_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области идей, правил и принципов через финансирование. ",
    "risks_avoid_involvement_ideolog_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы \"инвестиционных\" фондов Сороса.)",
    "risks_avoid_involvement_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_ideolog_materialist_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за свою причастность к определенным схемам, обеспечивающим ему доступ к источнику его материального благополучия. ",
    "risks_avoid_involvement_ideolog_emotional_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области различных идей, концепций и правил через эмоциональное манипулирование. ",
    "risks_avoid_involvement_ideolog_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы псевдорелигиозных, ультраправых организаций.)",
    "risks_avoid_involvement_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_ideolog_emotional_4": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом в имеющихся отношениях, следуя имеющимся корпоративным стандартам. ",
    "risks_avoid_involvement_materialist_vitalist_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области материального благополучия через разрушение семьи и здоровья. ",
    "risks_avoid_involvement_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные объединения наёмников в контексте негосударственных силовых и криминальных структур, ЧВК.)",
    "risks_avoid_involvement_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_materialist_vitalist_4": "Исполнитель без перспектив карьерного роста, который направляет свою жизнь на избегание ответственности за собственное материальное благополучие. ",
    "risks_avoid_involvement_materialist_ideolog_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области материального благополучия через навязывание различных идейных установок, правил и концепций. ",
    "risks_avoid_involvement_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы организаций, зарабатывающие на шпионаже различного рода и конкурентной разведке, команда хакеров, Анонимус, WikiLeaks.)",
    "risks_avoid_involvement_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_materialist_ideolog_4": "Исполнитель без перспектив карьерного роста, который использует свою причастность к определённым идейным установкам ради избегания ответственности за собственное материальное благополучие. ",
    "risks_avoid_involvement_materialist_emotional_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области материального благополучия через эмоциональные манипуляции. ",
    "risks_avoid_involvement_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы всевозможных сект, псевдорелигиозных организаций, сетевых пирамид, тренингов по pick up.)",
    "risks_avoid_involvement_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_materialist_emotional_4": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за свою причастность к определенному источнику своего материального благополучия. ",
    "risks_avoid_involvement_emotional_vitalist_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области эмоционального комфорта через разрушение семейного благополучия. ",
    "risks_avoid_involvement_emotional_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных псевдорелигиозных организаций, клуб по интересам, свингерские клубы.)",
    "risks_avoid_involvement_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_emotional_vitalist_4": "Исполнитель без перспектив карьерного роста, который направляет свою жизнь ради избегания ответственности за собственное эмоциональное благополучие. ",
    "risks_avoid_involvement_emotional_ideolog_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области эмоционального комфорта через навязывание различных идейных установок, правил и концепций. ",
    "risks_avoid_involvement_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных организаций, занятых конкурентной разведкой и различных форм шпионажа, команда троллинга, команда хакеров, Анонимус, WikiLeaks.)",
    "risks_avoid_involvement_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_emotional_ideolog_4": "Исполнитель без перспектив карьерного роста, провокатор, саботажник, интриган, который стремится избежать ответственности за свою причастность к определенным схемам и алгоритмам. ",
    "risks_avoid_involvement_emotional_materialist_1": "Группа исполнителей деструктивного типа, разрушающая чужие отношения, достижения и амбиции в области эмоционального комфорта через разрушение материального благополучия. ",
    "risks_avoid_involvement_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные представительства различных игровых сетей и компьютерных клубов.)",
    "risks_avoid_involvement_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_avoid_involvement_emotional_materialist_4": "Исполнитель без перспектив карьерного роста, «азартный игрок», который стремится избежать ответственности за своё материальное благополучие и социальный успех. ",
    "risks_involvement_power_vitalist_ideolog_1": "Бюджетный коллектив исполнителей, который направляет имеющиеся в его распоряжении наработки, правила, принципы и регламенты на улучшение качества жизни и семейное благополучие. ",
    "risks_involvement_power_vitalist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные фитнес-студии, семейные массажисты, фитнес-тренеры, учителя.)",
    "risks_involvement_power_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_vitalist_ideolog_4": "Помощник руководителя, исполнитель без перспектив карьерного роста, который направляет свои личные амбиции в области управленческих решений и алгоритмов на сохранение и поддержание привычного образа жизни. ",
    "risks_involvement_power_vitalist_materialist_1": "Бюджетный коллектив исполнителей, направляющий имеющиеся в его распоряжении материальные и финансовые ресурсы на улучшение качества жизни и семейное благополучие. ",
    "risks_involvement_power_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, маленький семейный бизнес по обслуживанию семейных потребностей заказчика: клининг, гувернерство.).",
    "risks_involvement_power_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_vitalist_materialist_4": "Помощник руководителя, исполнитель без перспектив карьерного роста, который вкладывает свои материальные ресурсы в сохранение и поддержание привычного образа жизни и своего семейного благополучия, «работает, чтобы жить, а не живёт, чтобы работать. Стиль деятельности направлен на сохранение и поддержание существующего положения вещей без изменений. ",
    "risks_involvement_power_vitalist_emotional_1": "Бюджетный коллектив исполнителей, направляющий имеющиеся в его распоряжении стратегии по манипулированию эмоциональным состоянием людей на улучшение качества жизни и семейное благополучие. ",
    "risks_involvement_power_vitalist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», \"живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, обслуживающий персонал.)",
    "risks_involvement_power_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_vitalist_emotional_4": "Помощник руководителя, исполнитель без перспектив карьерного роста, «манипулятор», провокатор, интриган, который направляет свои личные эмоции на сохранение и поддержание привычного образа жизни. Проектный боец, наемник под конкретный функционал или задачу. ",
    "risks_involvement_power_ideolog_vitalist_1": "Бюджетный коллектив исполнителей, работающий по принципу «королевский двор», который подчиняет свою жизнь уставу, регламенту или принципам, следя за их соблюдением другими людьми. ",
    "risks_involvement_power_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, небольшой бизнес, который направляет жизненные ресурсы своих сотрудников на сохранение и подтверждение своей приверженности идеям заказчика: эко-поселение, телохранители, старообрядцы.)",
    "risks_involvement_power_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_ideolog_vitalist_4": "Помощник руководителя, который направляет свою жизнь на сохранение и поддержание своей принадлежности к определённым идейным установкам и правилам. Стиль деятельности направлен на сохранение и поддержание существующего положения вещей без изменений с ориентацией на традиции и корпоративные стандарты. ",
    "risks_involvement_power_ideolog_materialist_1": "Бюджетный коллектив исполнителей, работающий по принципу «королевский двор», который направляет финансовые и материальные ресурсы на сохранение и подтверждение своей приверженности идеям и принципам заказчика. ",
    "risks_involvement_power_ideolog_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, народное финансирование, сетевые пирамиды.)",
    "risks_involvement_power_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_ideolog_materialist_4": "Помощник руководителя, который направляет свои материальные ресурсы на сохранение и поддержание своей принадлежности к определённым идейным установкам и правилам. Стиль деятельности направлен на сохранение и поддержание существующей идеологии руководства и корпоративных стандартов. ",
    "risks_involvement_power_ideolog_emotional_1": "Бюджетный коллектив исполнителей, работающий по принципу «королевский двор», который занимается PR, различной образовательной деятельностью, направляющий эмоциональные ресурсы и креатив своих сотрудников на обеспечение функционирования определенных алгоритмов, программ и концепций.",
    "risks_involvement_power_ideolog_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, доверительное представительство.)",
    "risks_involvement_power_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_ideolog_emotional_4": "Помощник руководителя, который жертвует своим эмоциональным комфортом ради сохранения и поддержания своей принадлежности к определённым идейным установкам и правилам. Стиль деятельности направлен на сохранение и поддержание существующей идеологии руководства и корпоративных стандартов. ",
    "risks_involvement_power_materialist_vitalist_1": "Бюджетный коллектив исполнителей, работающий по принципу «королевский двор», который использует физические возможности и ресурсы своих сотрудников ради сохранения и подтверждения своей причастности к источнику своего материального благополучия и социального успеха. ",
    "risks_involvement_power_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, небольшой бизнес по обслуживанию физиологических потребностей заказчиков, фитнес-студии, спа-салоны, танцевальные студии, летнее кафе.)",
    "risks_involvement_power_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_materialist_vitalist_4": "Помощник руководителя, который жертвует привычным образом жизни ради сохранения доступа к источнику своего материального благополучия. ",
    "risks_involvement_power_materialist_ideolog_1": "Бюджетный коллектив исполнителей, работающий по принципу «королевский двор», который занимается продажей всевозможных информационных ресурсов, программ, систем и алгоритмов ради сохранения и подтверждения своей причастности к источнику своего материального благополучия и социального успеха. ",
    "risks_involvement_power_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, школьные репетиторы, поварские курсы, мастер-классы по компьютерной грамотности.)",
    "risks_involvement_power_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_materialist_ideolog_4": "Помощник руководителя, который обеспечивает сохранение и поддержание существующих процессов и алгоритмов работы ради сохранения доступа к источнику своего материального благополучия. ",
    "risks_involvement_power_materialist_emotional_1": "Бюджетный коллектив исполнителей, работающий по принципу «королевский двор», который занимается продажей развлечений ради сохранения и подтверждения своей причастности к источнику своего материального благополучия и социального успеха. ",
    "risks_involvement_power_materialist_emotional_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, небольшой бизнес по обслуживанию эмоциональных потребностей заказчиков, психологическая консультация, таролог-астролог, домашние аниматоры.) ",
    "risks_involvement_power_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_materialist_emotional_4": "Помощник руководителя, который жертвует своим эмоциональным комфортом ради сохранения и поддержания своей принадлежности к определённому уровню материального благосостояния. «Терпит ради денег». ",
    "risks_involvement_power_emotional_vitalist_1": "Бюджетный коллектив исполнителей, который направляет жизненные ресурсы своих сотрудников на сохранение и подтверждение определенного рода отношений, направленных на эмоциональное удовлетворение заказчика или руководства. ",
    "risks_involvement_power_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, обслуживающий персонал.)",
    "risks_involvement_power_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_emotional_vitalist_4": "Помощник руководителя, манипулятор, который жертвует своими амбициями в области семейного благополучия в пользу сохранения своего эмоционального комфорта в имеющихся отношениях на работе. ",
    "risks_involvement_power_emotional_ideolog_1": "Бюджетный коллектив исполнителей, который ориентирован на подчинение всевозможных догм, правил и законов эмоциональному удовлетворению её руководства. ",
    "risks_involvement_power_emotional_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных псевдорелигиозных организаций.)",
    "risks_involvement_power_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_emotional_ideolog_4": "Помощник руководителя, который подчиняет свои идеи, правила и принципы сохранению и поддержанию своего эмоционального комфорта в имеющихся отношениях. При накоплении сильного эмоционального напряжения, он способен саботировать распоряжения руководства, выступая в роли провокатора. ",
    "risks_involvement_power_emotional_materialist_1": "Бюджетный коллектив исполнителей, который направляет материальные ресурсы на эмоциональное удовлетворение его заказчика. ",
    "risks_involvement_power_emotional_materialist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать». Сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, различные местные развлекательные труппы, обеспечивающие event-мероприятия, кейтеринг.) ",
    "risks_involvement_power_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_power_emotional_materialist_4": "Помощник руководителя, «азартный игрок», «авантюрист», который жертвует своими амбициями в области материального благополучия в пользу своего эмоционального комфорта. ",
    "risks_involvement_achievement_vitalist_ideolog_1": "Бюджетная небольшая команда специалистов, которая направляет имеющиеся в её распоряжении наработки, правила, принципы и регламенты на улучшение качества жизни и семейное благополучие. ",
    "risks_involvement_achievement_vitalist_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, фитнес-студии, студии йоги, семейные массажисты.)",
    "risks_involvement_achievement_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_vitalist_ideolog_4": " «Командный игрок», исполнитель, который точно соблюдает определённые идеи, правила и принципы в пользу сохранения и поддержания своей приверженности семье, и привычному для него образу жизни. ",
    "risks_involvement_achievement_ideolog_vitalist_1": "Бюджетная команда специалистов, которая подчиняет свою жизнь уставу, регламенту или принципам, следя за их соблюдением другими людьми. ",
    "risks_involvement_achievement_ideolog_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, проектное бюро, производственно-технический отдел.) ",
    "risks_involvement_achievement_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_ideolog_vitalist_4": " «Командный игрок», исполнитель, который подчиняет свой привычный образ жизни привычной работе с точным соблюдением существующих корпоративных идей, правил и принципов. ",
    "risks_involvement_achievement_ideolog_materialist_1": "Бюджетная команда специалистов, которая направляет финансовые потоки и ресурсы на сохранение и подтверждение своей приверженности определенным регламентам и правилам, отслеживая их использование по назначению. ",
    "risks_involvement_achievement_ideolog_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, старт-ап, тендерные комитеты, краудфандинговые платформы.) ",
    "risks_involvement_achievement_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_ideolog_materialist_4": " «Командный игрок», исполнитель, который рассматривает своё материальное благополучие как следствие точного соблюдения корпоративных идей, правил и принципов. ",
    "risks_involvement_achievement_vitalist_materialist_1": "Бюджетная команда специалистов, направляющая имеющиеся в её распоряжении материальные и финансовые ресурсы на улучшение качества жизни и семейное благополучие. ",
    "risks_involvement_achievement_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, небольшой бюджетный бизнес, в том числе семейного типа, занимающийся производством и продажей своей продукции под заказ: фабрика кухни, столовая при садах и школах.) ",
    "risks_involvement_achievement_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_vitalist_materialist_4": " «Командный игрок», исполнитель, который вкладывает свои материальные ресурсы в улучшения качества жизни своей семьи. ",
    "risks_involvement_achievement_vitalist_emotional_1": "Бюджетная команда специалистов, направляющая имеющиеся в её распоряжении стратегии по манипулированию эмоциональным состоянием людей на улучшение качества жизни и семейное благополучие. ",
    "risks_involvement_achievement_vitalist_emotional_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, детско-юношеские эстрадные коллективы, студии художественной самодеятельности.) ",
    "risks_involvement_achievement_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_vitalist_emotional_4": " «Командный игрок», исполнитель без перспектив карьерного роста под конкретный функционал или задачу, который вкладывается своим эмоциональным комфортом и умением манипулировать на работе ради сохранения своего семейного благополучия. ",
    "risks_involvement_achievement_ideolog_emotional_1": "Бюджетная команда специалистов, которая занимается рекламой, PR, HR, пропагандой любых видов деятельности, направляющие эмоциональные ресурсы и креатив своих сотрудников на сохранение и подтверждение своей причастности к определенным идеям, правилам и концепциям.",
    "risks_involvement_achievement_ideolog_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, ООО СПФИ, кафедра психологии и педагогики в различных ВУЗах, различные АНО ДПО.) ",
    "risks_involvement_achievement_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_ideolog_emotional_4": " «Командный игрок», исполнитель, который жертвует своим эмоциональным комфортом ради сохранения привычной, известной ему работы. ",
    "risks_involvement_achievement_materialist_vitalist_1": "Бюджетная команда специалистов, которая использует физические возможности и ресурсы своих сотрудников ради подтверждения и сохранения своей причастности к источнику своего материального благополучия и социального успеха. ",
    "risks_involvement_achievement_materialist_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, коммерческий отдел, отдел продаж, автомойка, кафе для семьи.) ",
    "risks_involvement_achievement_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_materialist_vitalist_4": " «Вечный заместитель», командный игрок, «второй», который жертвует своими личными и семейными интересами ради материальных «бонусов», премий, стабильного карьерного роста и повышения своего профессионального статуса. ",
    "risks_involvement_achievement_materialist_ideolog_1": "Бюджетная команда специалистов, которая занимается продажей всевозможных информационных ресурсов, программ, систем и алгоритмов ради подтверждения и сохранения своей причастности к источнику своего материального благополучия и социального успеха. ",
    "risks_involvement_achievement_materialist_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы, \"Консультант +\", \"1С\", команды, занятые \"локацией\" игр.) ",
    "risks_involvement_achievement_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_materialist_ideolog_4": "Исполнитель, командный игрок, который вкладывает свои достижения в области улучшения корпоративных стандартов и алгоритмов в «общий командный котёл», ради стабильного получения зарплаты. ",
    "risks_involvement_achievement_materialist_emotional_1": "Бюджетная команда специалистов, которая занимается продажей развлечений ради подтверждения и сохранения своей причастности к источнику своего материального благополучия и социального успеха. ",
    "risks_involvement_achievement_materialist_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, небольшие развлекательные эстрадные коллективы-гастролеры.) ",
    "risks_involvement_achievement_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_materialist_emotional_4": " «Командный игрок», исполнитель, который жертвует своим эмоциональным комфортом ради сохранения и поддержания привычного уровня материального благополучия и социального успеха (ради стабильного получения зарплаты, бонусов и премий). ",
    "risks_involvement_achievement_emotional_vitalist_1": "Бюджетная команда специалистов, которая направляет жизненные ресурсы своих сотрудников на формирование и удовлетворение духовных и эмоциональных потребностей общества. ",
    "risks_involvement_achievement_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, небольшие театральные коллективы, дом пионеров, арт-студии, школа искусств.) ",
    "risks_involvement_achievement_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_emotional_vitalist_4": " «Командный игрок», исполнитель, который направляет свою жизнь на сохранение и поддержание своей эмоциональной причастности к определённому человеку или сообществу. ",
    "risks_involvement_achievement_emotional_ideolog_1": "Бюджетная команда специалистов, которая ориентирована на подчинение всевозможных догм, правил и законов эмоциональному удовлетворению её руководства. ",
    "risks_involvement_achievement_emotional_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, аниматоры, розыгрыши, троллинг.) ",
    "risks_involvement_achievement_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_emotional_ideolog_4": "Манипулятор, интриган, саботажник и провокатор, который может объяснить и оправдать любое эмоциональное состояние и поведение. Иногда эти его особенности могут стать причиной конфликтов и увольнения более эффективных и полезных сотрудников. ",
    "risks_involvement_achievement_emotional_materialist_1": "Бюджетная команда специалистов, которая направляет материальные ресурсы на эмоциональное удовлетворение её руководства. ",
    "risks_involvement_achievement_emotional_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, команда покерных игроков, работающая под заказ, казино, различные компьютерные игорные клубы.) ",
    "risks_involvement_achievement_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_achievement_emotional_materialist_4": "Командный, «азартный игрок», который направляет свои материальные ресурсы на переживание пиковых эмоциональных состояний. Провокатор.",
    "risks_involvement_avoid_vitalist_ideolog_1": "Коллектив исполнителей, разрушающий чужие достижения, амбиции и принципы ради сохранения и подтверждения своей причастности к конкретному образу жизни. ",
    "risks_involvement_avoid_vitalist_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, нотариус, адвокатское бюро, органы опеки.) ",
    "risks_involvement_avoid_vitalist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_vitalist_ideolog_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за принятие решений в отношении управленческих алгоритмов, ради сохранения и поддержания привычного образа жизни. ",
    "risks_involvement_avoid_vitalist_materialist_1": "Небольшой коллектив исполнителей, разрушающий чужие достижения, амбиции в области материального благополучия и социального успеха ради сохранения и подтверждения своей причастности определенному образу жизни. ",
    "risks_involvement_avoid_vitalist_materialist_2": "Служащие, «зарплатники», «работают, чтобы жить, а не живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, надзорный орган, юридические консультации, специализирующиеся по разводам, разделу имущества, оформлению наследства, юристы-«судебники», аффилированному конкретному заказчику.) ",
    "risks_involvement_avoid_vitalist_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_vitalist_materialist_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за своё материальное благополучие ради привычного образа жизни. ",
    "risks_involvement_avoid_vitalist_emotional_1": "Коллектив исполнителей, разрушающий чужие амбиции, эмоции и достижения ради сохранения и подтверждения своей приверженности определенному образу жизни.",
    "risks_involvement_avoid_vitalist_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, обслуживающий персонал.) ",
    "risks_involvement_avoid_vitalist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_vitalist_emotional_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свой эмоциональный комфорт ради сохранения и поддержания привычного образа жизни. ",
    "risks_involvement_avoid_ideolog_vitalist_1": "Коллектив исполнителей, который жертвует качеством жизни своих сотрудников в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_involvement_avoid_ideolog_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные отделы прокуратуры и полиции, социальное движение Чайлдфри.) ",
    "risks_involvement_avoid_ideolog_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_ideolog_vitalist_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свою семью ради сохранения привычной, известной ему работы. ",
    "risks_involvement_avoid_ideolog_materialist_1": "Коллектив исполнителей, который жертвует материальным благополучием своих сотрудников и окружающих людей в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_involvement_avoid_ideolog_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы налоговой полиции, военных производств.) ",
    "risks_involvement_avoid_ideolog_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_ideolog_materialist_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за своё материальное благополучие ради следования утверждённым правилам и алгоритмам работы. ",
    "risks_involvement_avoid_ideolog_emotional_1": "Коллектив исполнителей, который жертвует эмоциональным комфортом своих сотрудников и окружающих людей в пользу соблюдения законов, норм, правил, регламентов и инструкций. ",
    "risks_involvement_avoid_ideolog_emotional_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные отделения различных идеологически пропагандистских организаций, благотворительных программ, социальной рекламы, волонтерство.) ",
    "risks_involvement_avoid_ideolog_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_ideolog_emotional_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свой эмоциональный комфорт ради следования существующим правилам принципам и стандартам. ",
    "risks_involvement_avoid_materialist_vitalist_1": "Коллектив исполнителей, разрушающий чужие достижения, амбиции в области семейного благополучия и качества жизни ради увеличения своего материального благосостояния. ",
    "risks_involvement_avoid_materialist_vitalist_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, различные объединения наёмников в контексте силовых структур, ЧВК.) ",
    "risks_involvement_avoid_materialist_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_materialist_vitalist_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свою семью ради сохранения доступа к источнику своего материального благополучия (зарплат, бонусов и премий). ",
    "risks_involvement_avoid_materialist_ideolog_1": "Коллектив исполнителей, разрушающий чужие амбиции и достижения в отношении различного рода информации, концепций, идей, алгоритмов и правил ради увеличения своего материального благосостояния. ",
    "risks_involvement_avoid_materialist_ideolog_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, организации, зарабатывающие на шпионаже различного рода и конкурентной разведке, команда хакеров.) ",
    "risks_involvement_avoid_materialist_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_materialist_ideolog_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за принятие решений в отношении управленческих алгоритмов, следует утверждённым правилам ради стабильного получения зарплаты, бонусов и премий. ",
    "risks_involvement_avoid_materialist_emotional_1": "Коллектив исполнителей, разрушающий чужие амбиции и достижения в области эмоционального комфорта ради увеличения своего материального благосостояния. ",
    "risks_involvement_avoid_materialist_emotional_2": "Проектные бойцы под задачу или конкретный функционал, «бонусники», «живут, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для них лучше, чем стабильный и небольшой оклад. (Например, местные филиалы всевозможных сект, псевдорелигиозных организаций, сетевых пирамид, тренингов по pick up.) ",
    "risks_involvement_avoid_materialist_emotional_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_materialist_emotional_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свой эмоциональный комфорт, ради сохранения доступа к источнику своего материального благополучия. ",
    "risks_involvement_avoid_emotional_vitalist_1": "Коллектив исполнителей, специализирующийся на разрушении чужих достижений и амбиций в области семейного благополучия и качества жизни ради удовлетворения эмоциональных потребностей. ",
    "risks_involvement_avoid_emotional_vitalist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных псевдорелигиозных организаций, клуб по интересам, свингерские клубы.) ",
    "risks_involvement_avoid_emotional_vitalist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_emotional_vitalist_4": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свою семью ради сохранения своего эмоционального комфорта. ",
    "risks_involvement_avoid_emotional_ideolog_1": "Коллектив исполнителей, ориентированный на нарушение и разрушение всевозможных догм, правил и законов ради эмоционального удовлетворения её руководства или заказчика. ",
    "risks_involvement_avoid_emotional_ideolog_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, местные филиалы различных организаций, занятых конкурентной разведкой и различных форм шпионажа.) ",
    "risks_involvement_avoid_emotional_ideolog_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_emotional_ideolog_4": "Исполнитель без перспектив карьерного роста, манипулятор, интриган, провокатор, который избегает ответственности за правила и алгоритмы ради сохранения своего места на работе. Иногда вследствие указанных особенностей могут возникать конфликты с последующим увольнением более эффективных сотрудников предприятия. ",
    "risks_involvement_avoid_emotional_materialist_1": "Коллектив исполнителей, разрушающий чужие материальные ресурсы, достижения, амбиции в области социального успеха ради эмоционального удовлетворения её руководства или заказчика. ",
    "risks_involvement_avoid_emotional_materialist_2": "Служащие, «зарплатники», «живут, чтобы работать». Для них лучше небольшой стабильный оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. (Например, казино, различные компьютерные игорные клубы.) ",
    "risks_involvement_avoid_emotional_materialist_3": "Профиль вероятного руководителя или ключевого сотрудника: ",
    "risks_involvement_avoid_emotional_materialist_4": "Исполнитель без перспектив карьерного роста, «азартный игрок», который жертвует своими материальными ресурсами ради переживания пиковых эмоциональных состояний. ",
    "text_claims_power_involvement": "[Властник – Причастник]: руководитель административно-бюрократического типа, выстраивающий систему управления по принципу «королевский двор», окружая себя в большей степени преданными исполнителями, нежели чем грамотными специалистами и экспертами. Его принцип деятельности, работающий как по отношению к подчинённым, так и по отношению к вышестоящему руководству – «дружба ради увеличения личной власти». ",
    "text_claims_involvement_power": "[Причастник – Властник]: служащий, секретарь, помощник, который направляет свои личные на сохранение и поддержания своей причастности, нужности и востребованности в жизни или профессиональной деятельности выбранного руководителя, старшего или авторитета.",
    "text_claims_achievement_power": "[Достиженец – Властник]: руководитель экспертно – административного типа, реализующий свои личные амбиции и организаторские способности в достижении высоких результатов в области профессиональной самореализации. Потенциальный резидент, региональный представитель, руководитель филиала, часто выросший с уровня специалистов-экспертов в своей области.",
    "text_claims_power_achievement": "[Властник – Достиженец]: диктатор, руководитель авторитарно-экспертного типа, выстраивающий свою систему управления с опорой на технологии и экспертов, специалистов в конкретных профессиональных областях ради распространения своего личного влияния на максимально большое количество людей. В основе его управления лежит жёсткий контроль и провокация специалистов на достижение ими максимальных результатов.",
    "text_claims_achievement_involvement": "[Достиженец – Причастник]: оперативный сотрудник, социолог, журналист, специалист-одиночка, который выстраивает личные отношения и поддерживает связи с коллегами и начальством ради достижения высоких результатов в конкретной профессиональной области. Его деятельность можно свести к принципу «лично дружит ради достижения профессионального результата».",
    "text_claims_involvement_achievement": "[Причастник – Достиженец]: командный игрок, служащий, который вкладывает свои личные профессиональные рекорды и достижения в общую «командную копилку» ради подтверждения и сохранения принадлежности к «своей команде». ",
    "text_claims_involvement_avoid": "[Причастник – Избегатель]: исполнитель, избегающий личной ответственности за результаты своей деятельности и стремящийся к защите только своих собственных интересов. Его цель - сохранение и поддержание своей принадлежности к выбранному им руководителю, старшему, структуре или авторитету как к источнику ресурсов. В качестве способа реализации в данном случае будет использоваться критика и обесценивание «чужеродных» влияния, амбиций и достижений вплоть до их разрушения.",
    "text_claims_avoid_involvement": "[Избегатель – Причастник]: избегатель, деструктор, конфликтёр, провокатор, саботажник, паразитирующий на ресурсах системы, в которой он находится. Цель - безнаказанное и безответственное потребление ресурсов системы, к которой он принадлежит. В качестве способа реализации в данном случае будет использоваться критика и обесценивание «чужеродных» влияния, амбиций, отношений и достижений вплоть до их разрушения. Часто ситуация доводится до открытого конфликта, вплоть до разрушения системы или ухода из неё, в зависимости от степени и уровня принадлежности к ней.",
    "text_claims_achievement_avoid": "[Достиженец – Избегатель]: специалист, эксперт - одиночка, создающий всевозможные системы и технологии безопасности, рассчитанные на разрушение чужеродного влияния, амбиций и отношений. Его цель - достижение высоких профессиональных результатов. В качестве способа реализации в данном случае будет использоваться обесценивание «чужеродных» влияния, амбиций и отношений вплоть до их разрушения, а управляемый конфликт в качестве основного инструмента реализации замысла.",
    "text_claims_avoid_achievement": "[Избегатель – Достиженец]: избегатель, деструктор, конфликтёр, провокатор, саботажник, паразитирующий на ресурсах системы, в которой он находится. Цель - безнаказанное и безответственное потребление ресурсов системы, к которой он принадлежит, вплоть до её полного разрушения. В качестве способа реализации в данном случае будет использоваться критика и обесценивание «чужеродных» достижений, технологий, систем влияния и отношений и вплоть до их полного разрушения. Часто ситуация доводится до открытого конфликта, вплоть до разрушения системы или ухода из неё, в зависимости от степени и уровня принадлежности к ней.",
    "text_claims_power_avoid": "[Властник – Избегатель]: руководитель, использующий критику и обесценивание «чужеродных» влияния, амбиций и достижений вплоть до их разрушения ради защиты и обеспечения собственных интересов и распространения своего влияния на максимально возможное количество людей. В качестве способа реализации в данном случае будет использоваться критика и обесценивание «чужеродных» достижений, технологий и отношений и вплоть до их полного разрушения, а управляемый конфликт в качестве основного инструмента реализации замысла.",
    "text_claims_avoid_power": "[Избегатель – Властник]: руководитель в фазе деструкции, который использует личное влияние и свою власть как средство разрушения работающей на него системы через избегание ответственности за её эффективность. Цель - разрушение питающей его системы. В качестве способа реализации в данном случае будет использоваться критика и обесценивание ставших «чужеродными» влияний, достижений, технологий и отношений вплоть до их полного разрушения, а открытый жёсткий конфликт в качестве основного инструмента реализации замысла.",
    "textFeel": "[Конкретный опыт], подход к обучению на базе опыта, суждения от чувств, ориентирован на людей, ролевая игра, имитация, группы обсуждения, комфортно в двусмысленности. Люди с таким стилем предпочитают получать информацию посредством прямого, реального опыта. Они обучаются посредством получения ощутимого и конкретного соприкосновения с изучаемым предметом. Они стараются оказаться в таких ситуациях, из которых они могли бы вынести определенный опыт. Эти лица тяготеют к конкретике. Оптимальным условием для их обучения является их непосредственное участие в опыте.",
    "textWatch": "[Рефлексивное наблюдение], подход к обучению объективный рефлексивный, предпочитает роли участника роль наблюдателя, лекция, пассивное наблюдение. Для людей с таким стилем характерно избегание быстрых решений, стремление взвешивать и только после этого реагировать на информацию.",
    "textUnderstand": "[Абстрактная концептуализация], аналитический подход к учёбе, логичен, рационален, ищет однозначные ответы, ориентирован на вещи и символы, уважает авторитеты, учитывает структуру. Людям с таким стилем нужна такая информация, которую они могли бы обдумать. Они лучше усваивают такую информацию, которая поддается разумному исследованию или интеллектуальному эксперименту.",
    "textAction": "[Активное экспериментирование], активный, деятельный подход к учёбе, вовлечение действий для проверки знаний и способностей, лаборатория опытов. Люди с таким стилем оценивают информацию опытным путем. Активно используя информацию, они могут формулировать альтернативные гипотезы относительно направлений применения.",
    "behaviorModel": "Модель поведения",
    "behavior_practices": "без долгих размышлений засучив рукава, принимается за дело. Наслаждаетесь непосредственностью впечатлений и рад всему новому. Сначала действует, а потом думает, если вообще думает. Ему нравится быть активным, и, столкнувшись с проблемой, он немедленно бросает все силы на ее решение. Возможно, что общителен от природы.",
    "behavior_watcher": "обычно не принимает участия в деятельности. На собраниях чаще сидит в задних рядах. Прежде чем принять какое-либо решение, любит собирать разного рода информацию. Предпочитает сначала увидеть, как развивается ситуация, а только потом высказать свое мнение. Вероятно, что ему от природы свойственна осторожность. Сначала думает, потом действует, если вообще действует.",
    "behavior_theorist": "склонен выстраивать логическую последовательность событий, чтобы подогнать их под определенную модель. Любит шаблоны, системы и правила, однако, при этом, предпочитает быть независимым и аналитичным. Может быть полезен для тщательного анализа, поскольку не отвергнет идею просто потому, что она не совпадает с его мировоззрением.",
    "behavior_pragmatic": "всегда стремится проверить идеи на практике. Всегда экспериментирует, хочет заниматься делом, а не просто разговаривать. Прочитав что-то интересное, сразу же загорается желанием это осуществить.",
    "hisMotto": "Его девиз",
    "motto_practices": "Все в жизни нужно попробовать",
    "motto_watcher": "Сначала я должен это обдумать",
    "motto_theorist": "Посмотрим, как это согласуется с...",
    "motto_pragmatic": "Должен существовать способ получше",
    "abilities": "Способности",
    "abilities_practices": "Выполнять планы, Ориентирован на результаты, Заинтересован в них, Рискует, адаптируется к конкретным обстоятельствам, Интуитивный стиль проб и ошибок, Защищает позиции Идей, Ставит цели, Выдерживает сроки, Задействует ресурсы",
    "abilities_watcher": "Генерирование идей, «мозговой штурм», Видеть ситуацию с разных точек зрения, Эмоционален, люди ему интересны, Ищет информационные источники, Чувствует возможности, Исследует новые связи и паттерны, Признаёт противоречия и проблемы, Порождает альтернативы",
    "abilities_theorist": "Создавать теоретические модели, Индивидуальное обоснование, Сравнение альтернатив, Установка критериев, Формирование планов и гипотез, Определение проблем",
    "abilities_pragmatic": "Хорошее практическое применение идей, Хорошо, когда один ответ на проблему, Выбор альтернатив, Сосредоточенные усилия, Оценка планов и программ, Проверка гипотез, Принятие практических решений",
    "risk_c0_watcher_theorist": "Возможно периодическое совершение однотипных ошибок из-за того, что он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c0_watcher_practices": "Возможно нарушение сроков завершения работы в силу не направленности к конкретной цели из-за того, что он может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c0_watcher_pragmatic": "Возможно нарушение сроков завершения работы из-за отсутствия фокусировки на основной вид деятельности при слабой экспериментальной проработке исходной концепции потому, что он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c0_theorist_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может не различать проблемы и возможности, свои идеи при этом часто слабые.",
    "risk_c0_theorist_pragmatic": "Опираясь на желаемое вместо действительного, не имея практического подхода, он может привести к нарушению сроков завершения работы, решая не ту проблему.",
    "risk_c0_theorist_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может не завершить работу вовремя, решая не ту проблему",
    "risk_c0_practices_watcher": "Он может осуществлять громадные свершения, но не тех вещей, что нужно  или же заниматься  тривиальными усовершенствованиями потому, что в ситуации выбора может не различать проблемы и возможности, а собственные идеи у него слабые. ",
    "risk_c0_practices_theorist": "Из-за отсутствия теоретической основы для работы он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Он может осуществлять громадные свершения, но не тех вещей, что нужно  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c0_practices_pragmatic": "Он может осуществлять громадные свершения, но не тех вещей, что нужно  или же заниматься  тривиальными усовершенствованиями потому, что его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности",
    "risk_c0_pragmatic_watcher": "Он может завершить процесс преждевременно или качественно решать не ту проблему потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.",
    "risk_c0_pragmatic_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. А именно, - он может завершить процесс преждевременно или качественно решать не ту проблему.",
    "risk_c0_pragmatic_practices": "Он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели.",
    "risk_c34_watcher_theorist_practices": "Возможно нарушение сроков завершения работы в силу не направленности к конкретной цели из-за периодического совершения однотипных ошибок потому, что он   может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c34_watcher_theorist_pragmatic": "Возможно нарушение сроков завершения работы при слабой экспериментальной проработке исходной концепции и отсутствии фокусировки на основной вид деятельности из-за периодического совершения однотипных ошибок потому, что он   может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c34_watcher_practices_pragmatic": "Возможно нарушение сроков завершения работы при слабой экспериментальной проработке исходной концепции и отсутствии фокусировки на основной вид деятельности и конкретную цель потому, что он   может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c34_watcher_practices_theorist": "Возможно периодическое совершение однотипных ошибок и нарушение сроков завершения работы при отсутствии фокусировки на конкретной цели из-за того, что он   может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c34_watcher_pragmatic_practices": "Возможно нарушение сроков завершения работы при отсутствии фокусировки на конкретную цель и основной вид деятельности, а так же слабой экспериментальной проработке исходной концепции потому, что он   может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c34_watcher_pragmatic_theorist": "Возможно периодическое совершение однотипных ошибок при отсутствие фокусировки на основной вид деятельности и слабой экспериментальной проработке исходной концепции потому, что он  может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c34_theorist_watcher_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может не различать проблемы и возможности, свои идеи при этом часто слабые. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели.",
    "risk_c34_theorist_watcher_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может не различать проблемы и возможности, свои идеи при этом часто слабые. При этом, его теории могут оказаться не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c34_theorist_practices_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может привести к нарушению сроков завершения работы в силу своей не направленности к конкретной цели.  При этом его теории могут оказаться не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c34_theorist_practices_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может привести к нарушению сроков завершения работы в силу своей не направленности к конкретной цели. При этом свои идеи часто слабые потому, что он может не различать проблемы и возможности.",
    "risk_c34_theorist_pragmatic_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели. ",
    "risk_c34_theorist_pragmatic_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При этом свои идеи часто слабые потому, что он может не различать проблемы и возможности.",
    "risk_c34_practices_watcher_theorist": "Из-за отсутствия теоретической основы для работы он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.  Он может осуществлять громадные свершения, но не тех вещей, что нужно  или же заниматься  тривиальными усовершенствованиями потому, что в ситуации выбора может не различать проблемы и возможности при слабости собственных идей.",
    "risk_c34_practices_watcher_pragmatic": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что может не различать проблемы и возможности при слабости своих собственных идей. По итогу,- его теории могут быть не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности",
    "risk_c34_practices_theorist_watcher": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности потому, что  в ситуации выбора может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c34_practices_theorist_pragmatic": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. По итогу, -  его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности.  ",
    "risk_c34_practices_pragmatic_theorist": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом, его теории могут быть не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. По итогу, -  отсутствует теоретическая основа для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. ",
    "risk_c34_practices_pragmatic_watcher": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом его теории могут быть не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Как следствие, - может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c34_pragmatic_watcher_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.  Итогом, - он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели.  ",
    "risk_c34_pragmatic_watcher_practices": "Он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели.  При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.   ",
    "risk_c34_pragmatic_theorist_watcher": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Поскольку в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые, он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели и отсутствия фокусировки на основной деятельности. ",
    "risk_c34_pragmatic_theorist_practices": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели и отсутствия фокусировки на основной деятельности. По итогу это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели",
    "risk_c34_pragmatic_practices_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.  Он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели и отсутствия фокусировки на основной деятельности. ",
    "risk_c34_pragmatic_practices_watcher": "В ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или качественно решать не ту проблему в силу своей не направленности к конкретной цели и отсутствия фокусировки на основной деятельности.  ",
    "risk_c12_watcher_theorist_practices": "Возможно нарушение сроков завершения работы и отсутствие фокусировки на конкретную цель при ориентировке на желаемое вместо действительного (ложные надежды, воздушные замки) и отсутствии практического подхода потому, что он  может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c12_watcher_theorist_pragmatic": "Возможно  отсутствие практического подхода и ориентировка на желаемое вместо действительного (ложные надежды, воздушные замки). Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки и отсутствия фокусировки на основной вид деятельности потому, что он может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c12_watcher_practices_theorist": "Возможное отсутствие теоретической основы для работы, может приводить к периодическим однотипным ошибкам, когда  из раза в раз он  «наступает на одни и те же грабли» независимо от контекста своей деятельности. Это может проявляться в громадных ненужных свершениях или же занятиях  тривиальными усовершенствованиями потому, что в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности.  ",
    "risk_c12_watcher_practices_pragmatic": "Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки и отсутствия фокусировки на основной вид деятельности. Это может приводить к громадным свершениям, но не тех вещей, что надо  или же занятиям  тривиальными усовершенствованиями потому, что в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности.  ",
    "risk_c12_watcher_pragmatic_theorist": "При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности, завершая процесс преждевременно или качественно решая не ту проблему потому, что в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c12_watcher_pragmatic_practices": "Возможно нарушение сроков завершения работы в силу не направленности к конкретной цели. Он может завершить процесс преждевременно или качественно решать не ту  проблему потому, что в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c12_theorist_watcher_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. При этом его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности",
    "risk_c12_theorist_watcher_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c12_theorist_practices_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности.",
    "risk_c12_theorist_practices_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи  у него часто слабые.",
    "risk_c12_theorist_pragmatic_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c12_theorist_pragmatic_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи  у него часто слабые. ",
    "risk_c12_practices_pragmatic_theorist": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом он может завершить процесс работы преждевременно или качественно решать не ту проблему. Из-за того, что отсутствует теоретическая основа для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. ",
    "risk_c12_practices_pragmatic_watcher": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом он может завершить процесс работы преждевременно или качественно решать не ту проблему потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.",
    "risk_c12_practices_theorist_watcher": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии  практического подхода, он может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c12_practices_theorist_pragmatic": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии  практического подхода, его теории могут оказаться не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c12_practices_watcher_theorist": "Находясь в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности. Из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. - Может осуществлять громадные свершения, но не тех вещей, что надо или же заниматься  тривиальными усовершенствованиями.  ",
    "risk_c12_practices_watcher_pragmatic": "Находясь в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности. При этом его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Как следствие, - он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями",
    "risk_c12_pragmatic_practices_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.  Он может завершить процесс преждевременно или, качественно решая не ту проблему осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.",
    "risk_c12_pragmatic_practices_watcher": "В ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом,-он может завершить процесс преждевременно или, качественно решая не ту проблему осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься тривиальными усовершенствованиями.",
    "risk_c12_pragmatic_theorist_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c12_pragmatic_theorist_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода и не умея в ситуации выбора различать проблемы и возможности при слабости своих собственных идей, он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c12_pragmatic_watcher_theorist": "Из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.Он может оказаться парализованным альтернативами вариантов своей деятельности и завершить процесс преждевременно или, качественно решить не ту проблему.",
    "risk_c12_pragmatic_watcher_practices": "Он может оказаться парализованным альтернативами вариантов своей деятельности и завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.   ",
    "risk_c124_watcher_theorist_practices": "В силу слабой экспериментальной проработки при отсутствии фокусировки на основной вид деятельности и конкретную цель, его теории могут оказаться не проверенными. Это может привести к нарушению сроков завершения работы потому, что при ориентировке на желаемое вместо действительного (ложные надежды, воздушные замки) и отсутствии практического подхода, он может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c124_watcher_theorist_pragmatic": "Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки и отсутствия фокусировки на основной вид деятельности и конкретную цель. Это может привести к нарушению сроков завершения работы потому, что ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки) при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c124_watcher_practices_theorist": "Из-за отсутствия фокусировки на работу, его концепции часто бывают не проверены в силу слабой предварительной экспериментальной проработки. В силу этого, он время от времени бывает парализован альтернативами, не имея чёткой системы критериев выбора. Пытаясь это преодолеть, он периодически совершает однотипные ошибки, опираясь на желаемое вместо действительного. Как следствие - его бросает из стороны в сторону,- он либо занимается тривиальными усовершенствованиями, либо осуществляет громадные свершения, но не тех вещей, что действительно нужны.   ",
    "risk_c124_watcher_practices_pragmatic": "Из-за отсутствия фокусировки на работу, его концепции часто бывают не проверены в силу слабой предварительной экспериментальной проработки. В силу этого, он время от времени бывает парализован альтернативами, не имея чёткой системы критериев выбора. Пытаясь это преодолеть, он периодически совершает однотипные ошибки, опираясь на желаемое вместо действительного. Как следствие - его бросает из стороны в сторону,- он либо занимается тривиальными усовершенствованиями, либо осуществляет громадные свершения, но не тех вещей, что действительно нужны.",
    "risk_c124_watcher_pragmatic_theorist": "Опираясь на желаемое вместо действительного, занимаясь несколькими проектами одновременно, он может оказаться парализован альтернативами деятельности, что при слабой экспериментальной проработки и отсутствии фокусировки на основной деятельности может привести к нарушению сроков завершения работы.",
    "risk_c124_watcher_pragmatic_practices": "Возможно нарушение сроков завершения работы в силу не направленности к конкретной цели. Из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности потому, что в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c124_theorist_watcher_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели.  При этом его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности.",
    "risk_c124_theorist_watcher_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. При этом его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели. ",
    "risk_c124_theorist_practices_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. При этом его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи  у него часто слабые.",
    "risk_c124_theorist_practices_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи  у него часто слабые потому, что его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности.",
    "risk_c124_theorist_pragmatic_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи  у него часто слабые. ",
    "risk_c124_theorist_pragmatic_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи  у него часто слабые. Как следствие, - это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели ",
    "risk_c124_practices_pragmatic_watcher": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом он может завершить процесс работы преждевременно или качественно решать не ту проблему. При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Итогом, - в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c124_practices_pragmatic_theorist": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом он может завершить процесс работы преждевременно или качественно решать не ту проблему потому, что в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - при отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. ",
    "risk_c124_practices_theorist_pragmatic": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии  практического подхода, он может не различать проблемы и возможности,  а свои идеи у него часто слабые. Итогом, - его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c124_practices_theorist_watcher": "Может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.  При этом из-за ориентировки на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии  практического подхода, его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Итогом, - он может не различать проблемы и возможности,  а свои идеи у него часто слабые. ",
    "risk_c124_practices_watcher_pragmatic": "Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Как следствие, - находясь в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности. Итогом, - может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c124_practices_watcher_theorist": "При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Как следствие, -находясь в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности.Итогом, -может осуществлять громадные свершения, но не тех вещей, что надо или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c124_pragmatic_practices_watcher": "В ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. При этом из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.Итогом,-он может завершить процесс преждевременно или, качественно решая не ту проблему осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься тривиальными усовершенствованиями.",
    "risk_c124_pragmatic_practices_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом,-он может завершить процесс преждевременно или, качественно решая не ту проблему осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься тривиальными усовершенствованиями.",
    "risk_c124_pragmatic_theorist_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может в ситуации выбора не различать проблемы и возможности при слабости своих собственных идей. Как следствие, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c124_pragmatic_theorist_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может в ситуации выбора не различать проблемы и возможности при слабости своих собственных идей. Как следствие, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c124_pragmatic_watcher_practices": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Он может оказаться парализованным альтернативами вариантов своей деятельности и завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.",
    "risk_c124_pragmatic_watcher_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Он может оказаться парализованным альтернативами вариантов своей деятельности и завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.",
    "risk_c123_watcher_theorist_practices": "В силу слабой экспериментальной проработки при отсутствии фокусировки на основном виде деятельности, его теории могут оказаться не проверенными. Это может приводить к громадным свершениям, но не тех вещей, которые действительно нужны  или же занятиям  тривиальными усовершенствованиями. Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности.",
    "risk_c123_watcher_theorist_pragmatic": "Возможно нарушение сроков завершения работы в силу не направленности к конкретной цели. Он может завершить процесс преждевременно или качественно решать не ту проблему, ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки). При отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности ",
    "risk_c123_watcher_practices_theorist": "Его теории могут оказаться не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки) при отсутствии практического подхода он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности. ",
    "risk_c123_watcher_practices_pragmatic": "При отсутствии теоретической основы для работы, он периодически может совершать однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности, он может завершить процесс преждевременно или, качественно решая не ту проблему, заниматься громадными свершениями  не того, что нужно или тривиальными усовершенствованиями.",
    "risk_c123_watcher_pragmatic_theorist": "Возможно нарушение сроков завершения работы в силу его не направленности к конкретной цели.  Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или, качественно решая не ту проблему, оказаться парализованным альтернативами вариантов своей деятельности.  ",
    "risk_c123_watcher_pragmatic_practices": "При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Оказываясь парализованным альтернативами вариантов своей деятельности в ситуации выбора, он может завершить процесс преждевременно, или, качественно решая не ту проблему, заниматься громадными свершениями, но не тех вещей, которые действительно нужны  или же тривиальными усовершенствованиями.",
    "risk_c123_theorist_watcher_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. Как следствие, - он может завершить процесс преждевременно или качественно решать не ту проблему. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели.  ",
    "risk_c123_theorist_watcher_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. Как следствие, - он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что его теории могут быть не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c123_theorist_practices_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может совершать громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. При этом он может завершить процесс преждевременно или качественно решать не ту проблему потому, что в ситуации выбора может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c123_theorist_practices_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может заниматься громадными свершениями, но не тех вещей, что надо  или же проводить тривиальные усовершенствования. При этом он может оказаться парализован альтернативами вариантов своей деятельности. Как следствие, - его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c123_theorist_pragmatic_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему, занимаясь при этом громадными свершениями, но не тех вещей, что надо  или же  тривиальными усовершенствованиями. Это происходит потому, что в ситуации выбора не может не различать проблемы и возможности, а свои идеи  у него часто слабые. ",
    "risk_c123_theorist_pragmatic_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему, оказаться парализованным альтернативами вариантов своей деятельности. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели.  ",
    "risk_c123_practices_pragmatic_theorist": "Находясь в ситуации выбора он может не различать проблемы и возможности при слабости своих собственных идей. При этом ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему. Итогом,- он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c123_practices_pragmatic_watcher": "При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Находясь в ситуации выбора он может не различать проблемы и возможности при слабости своих собственных идей. Как следствие, - он может завершить процесс преждевременно или качественно решать не ту проблему. Итогом, -  может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями",
    "risk_c123_practices_theorist_watcher": "Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может оказаться парализован альтернативами вариантов своей деятельности. Итогом, - он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c123_practices_theorist_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может завершить процесс преждевременно или качественно решать не ту проблему. Поскольку в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые, он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.",
    "risk_c123_practices_watcher_theorist": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода в ситуации выбора он может оказаться парализован альтернативами вариантов своей деятельности. Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Итогом,- он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями.",
    "risk_c123_practices_watcher_pragmatic": "Из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.Находясь в ситуации выбора, он может оказаться парализован альтернативами вариантов своей деятельности.Как следствие, -он может завершить процесс преждевременно или качественно решать не ту проблему. Итогом, -может осуществлять громадные свершения, но не тех вещей, что надо или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c123_pragmatic_practices_theorist": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки) при отсутствии практического подхода в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями",
    "risk_c123_pragmatic_practices_watcher": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Находясь в ситуации выбора, он может оказаться парализованным альтернативами вариантов своей деятельности. Итогом,- он может завершить процесс преждевременно или качественно решать не ту проблему, может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c123_pragmatic_theorist_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода оказавшись в ситуации выбора, он может не различать проблемы и возможности, а свои идеи  у него часто слабые. Итогом,- он может завершить процесс преждевременно или, качественно решая не ту проблему, осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c123_pragmatic_theorist_watcher": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода оказавшись в ситуации выбора, он может оказаться парализованным альтернативами вариантов своей деятельности. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c123_pragmatic_watcher_theorist": "Ориентируясь на желаемое вместо действительного(ложные надежды, воздушные замки), при отсутствии практического подхода оказавшись в ситуации выбора, он может оказаться парализованным альтернативами вариантов своей деятельности. Итогом, -он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c123_pragmatic_watcher_practices": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора может оказаться парализованным альтернативами вариантов своей деятельности. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели. ",
    "risk_c234_theorist_practices_pragmatic": "Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности независимо от контекста, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли»  из-за отсутствия теоретической основы для работы. При этом его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Как следствие,- это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели.",
    "risk_c234_theorist_pragmatic_practices": "Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности независимо от контекста, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли»  из-за отсутствия теоретической основы для работы.  Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели. Как следствие, - его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности.",
    "risk_c234_practices_theorist_pragmatic": "Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности независимо от контекста, что может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели. Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Как следствие, - его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности.",
    "risk_c234_practices_pragmatic_theorist": "Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности независимо от контекста, что может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели. В силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности его теории могут оказаться не проверены. При отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. ",
    "risk_c234_pragmatic_theorist_practices": "Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности независимо от контекста. Поэтому его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Из-за отсутствия теоретической основы для работы, он периодически совершает эти однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели.",
    "risk_c234_pragmatic_practices_theorist": "Оказываясь в ситуации выбора парализованным альтернативами вариантов своей деятельности независимо от контекста. Поэтому его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели.Из - за отсутствия теоретической основы для работы, он периодически совершает эти однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.",
    "risk_c234_watcher_pragmatic_practices": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может не различать проблемы и возможности, при этом  свои идеи у него часто слабые потому, что его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Всё это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели",
    "risk_c234_watcher_practices_pragmatic": "Ориентируясь на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода он может не различать проблемы и возможности, при этом  свои идеи у него часто слабые. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели. По итогу, - теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности",
    "risk_c234_practices_pragmatic_watcher": "Ориентировка на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели. При этом его теории не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. По итогу, - он может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c234_practices_watcher_pragmatic": "Ориентировка на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода. Это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. По итогу,- его теории могут оказаться не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. ",
    "risk_c234_pragmatic_practices_watcher": "Ориентировка на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода. По этому его теории могут быть не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Это может привести к нарушению сроков завершения работы в силу не направленности к конкретной цели. По итогу, -он  может не различать проблемы и возможности, а свои идеи  у него часто слабые.",
    "risk_c234_pragmatic_watcher_practices": "Ориентировка на желаемое вместо действительного (ложные надежды, воздушные замки), при отсутствии практического подхода. По этому его теории могут быть не проверены в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При этом он может не различать проблемы и возможности, а свои идеи у него часто слабые. Всё это может привести к нарушению сроков завершения работы в силу его не направленности к конкретной цели. ",
    "risk_c234_pragmatic_theorist_watcher": "Он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что его теории могут быть не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При  отсутствии теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Итогом, - в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. ",
    "risk_c234_pragmatic_watcher_theorist": "Он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями потому, что его теории могут быть не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При этом, в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.Итогом, -из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.",
    "risk_c234_theorist_watcher_pragmatic": "Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При этом, в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.  Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. Итогом, - он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c234_theorist_pragmatic_watcher": "В ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.  Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.Итогом, -он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься тривиальными усовершенствованиями.",
    "risk_c234_watcher_theorist_pragmatic": "Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.  Итогом, - он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься  тривиальными усовершенствованиями. ",
    "risk_c234_watcher_pragmatic_theorist": "Из - за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности.Его теории могут оказаться не проверенными в силу слабой экспериментальной проработки или отсутствия фокусировки на основной вид деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые.  Итогом, -он может осуществлять громадные свершения, но не тех вещей, что надо  или же заниматься тривиальными усовершенствованиями.",
    "risk_c234_practices_theorist_watcher": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c234_practices_watcher_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c234_theorist_practices_watcher": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c234_theorist_watcher_practices": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c234_watcher_theorist_practices": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "risk_c234_watcher_practices_theorist": "Из-за отсутствия теоретической основы для работы, он периодически совершает однотипные ошибки из раза в раз «наступая на одни и те же грабли» независимо от контекста своей деятельности. При этом в ситуации выбора он может не различать проблемы и возможности, а свои идеи у него часто слабые. Итогом, - он может завершить процесс преждевременно или, качественно решая не ту проблему, нарушить сроки завершения работы в силу своей не направленности к конкретной цели.  ",
    "workMotivationType": "Тип трудовой мотивации",
    "professionalMotivation": "Профессиональная",
    "professionalMotivationDesc": "Интересует содержание работы.  Не согласен на неинтересные для него работы, сколько бы за них не платили. Его интересуют трудные задания - возможность самовыражения;  считает важной свободу в оперативных действиях;  важно профессиональное признание, как лучшего в профессии.",
    "patriotMotivation": "Патриотическая",
    "patriotMotivationDesc": "Необходима идея, которая будет им двигать; важно общественное признание участия в успехе;  главная награда - всеобщее признание незаменимости в фирме.",
    "masterMotivation": "Хозяйская",
    "masterMotivationDesc": "Добровольно принимает на себя ответственность; характеризуется обостренным требованием свободы действий;  не терпит контроля.",
    "avoidMotivation": "Избегательная",
    "avoidMotivationDesc": "Все равно, какую работу выполнять, нет предпочтений;  согласен на низкую оплату, при условии, чтобы другие не получали больше;  низкая квалификация; не стремится повысить квалификацию, противодействует этому;  низкая активность и выступление против активности других; низкая ответственность, стремление переложить ее на других;  стремление к минимизации усилий.",
    "leaderAbilities": "Лидерские данные",
    "average": "Средние",
    "averageHigh": "Высокие, средние",
    "high": "Высокие",
    "low": "Крайне низкие.",
    "relationWithOtherWorkers": "Отношения с другими работниками",
    "goodOrNeutral": "Доброжелательные или нейтральные, реже напряженные",
    "good": "Хорошие",
    "neutral": "Нейтральные",
    "veryGood": "Замечательные",
    "careerOrientation": "Карьерные ориентации",
    "careerOrientationProfessional": "Ради дела – высокие, ради власти – отсутствуют.",
    "careerOrientationPatriot": "Ради дела – низкие, ради власти – низкие.",
    "careerOrientationMaster": "Ради дела – средние или высокие, ради власти – низкие.",
    "careerOrientationLumpen": "Ради дела – отсутствуют, ради власти – средние или высокие.",
    "learningRelation": "Отношение к обучению",
    "activePositive": "Активное, позитивное.",
    "positive": "Позитивное",
    "neutralNegative": "Нейтрально негативное",
    "activeNegative": "Активно негативное",
    "workBehavior": "Ожидаемое трудовое поведение",
    "workBehaviorProfessional": "дисциплина – средняя, со срывами, инициатива – высокая, функции – расширение.",
    "workBehaviorPatriot": "дисциплина – высокая, инициатива – высокая, функции – расширение.",
    "workBehaviorMaster": "дисциплина – средняя, инициатива – высокая в пределах работы/функции, функции – стремление к системной законченности.",
    "workBehaviorLumpen": "дисциплина – средняя или низкая, инициатива – отсутствует, функции – сужение",
    "paymentForm": "Предпочтительная для сотрудника форма и особенности оплаты",
    "paymentFormProfessional": "повременная (оклад, тариф), основанная на учете различий в уровне квалификации работников или в особенностях выполняемой работы (должности).",
    "paymentFormPatriot": "с не очень большой переменной частью заработка, основанной на учете трудового вклада работника в общие результаты работы подразделения и организации в целом.",
    "paymentFormMaster": "сдельная или повременная оплата по результатам индивидуальным или руководимой им команды (группы, бригады).",
    "paymentFormLumpen": "повременная, с зависимостью зарплаты от затрат труда (с учетом переработок времени), плюс премии по общим результатам работы подразделения и/или всего предприятия.",
    "stimulationType": "Рекомендуемые виды стимулирования",
    "basic": "Базовые",
    "stimulationTypeProfessional1": "Карьера, развитие.",
    "stimulationTypeProfessional2": "Организационные - условия работы, ее содержание и организация.",
    "stimulationTypePatriot": "Моральные - грамоты, почетные знаки, представление к наградам, доска почета и пр.",
    "stimulationTypeMaster": "Участие в совладении и управлении.",
    "stimulationTypeLumpen1": "Негативные - неудовольствие, наказания, угроза потери работы.",
    "stimulationTypeLumpen2": "Патернализм (забота о работнике) - дополнительное социальное и медицинское страхование, создание  условий для отдыха и пр.",
    "stimulationTypeLumpen3": "Натуральные - покупка или аренда жилья, предоставление автомобиля и др.",
    "applicable": "Применимы",
    "applicableToProfessional1": "Денежные - заработная плата, включая все виды премий и надбавок",
    "applicableToProfessional2": "Участие в совладении и управлении",
    "applicableToProfessional3": "Моральные - грамоты, почетные знаки, представление к наградам, доска почета и пр.",
    "applicableToPatriot1": "Патернализм (забота о работнике) - дополнительное социальное и медицинское страхование, создание условий для отдыха и пр.",
    "applicableToPatriot2": "Участие в совладении и управлении",
    "applicableToMaster1": "Денежные - заработная плата, включая все виды премий и надбавок.",
    "applicableToMaster2": "Организационные - условия работы, ее содержание и организация",
    "applicableToMaster3": "Карьера, развитие",
    "applicableWithTargetAgreement": "Применимы при целевой договорённости",
    "applicableWithTargetAgreementToProfessional": "Натуральные (покупка или аренда жилья, предоставление автомобиля и др.)",
    "applicableAsSign": "Применимы в знаковой форме",
    "applicableAsSignPatriot1": "Натуральные - покупка или аренда жилья, предоставление автомобиля и др.",
    "applicableAsSignPatriot2": "Негативные - неудовольствие, наказания, угроза потери работы.",
    "neutralPatriot1": "Организационные - условия работы, ее содержание и организация",
    "neutralPatriot2": "Карьера, развитие",
    "neutralPatriot3": "Денежные - заработная плата, включая все виды премий и надбавок",
    "neutralMaster1": "Моральные - грамоты, почетные знаки, представление к наградам, доска почета и пр.",
    "neutralLumpen1": "Денежные - заработная плата, включая все виды премий и надбавок.",
    "neutralLumpen2": "Моральные - грамоты, почетные знаки, представление к наградам, доска почета и пр.",
    "forbidden": "Запрещены",
    "neutralNotEffective": "Нейтральные (безрезультатные)",
    "forbiddenToProfessional1": "Патернализм (забота о работнике) - дополнительное социальное и медицинское страхование, создание условий для отдыха и пр.",
    "forbiddenToProfessional2": "Негативные - неудовольствие, наказания, угроза потери работы.",
    "forbiddenToMaster1": "Негативные - неудовольствие, наказания, угроза потери работы.",
    "forbiddenToMaster2": "Патернализм (забота о работнике) - дополнительное социальное и медицинское страхование, создание  условий для отдыха и пр.",
    "forbiddenToLumpen1": "Организационные - условия работы, ее содержание и организация.",
    "forbiddenToLumpen2": "Карьера, развитие",
    "forbiddenToLumpen3": "Участие в совладении и управлении",
    "testingTimeIs": "Время тестирования составило",
    "behaviorLogic": "Логика поведения",
    "strategy_practices": "Сделал на практике",
    "strategy_watcher": "Увидел результат",
    "strategy_theorist": "Построил концепцию",
    "strategy_pragmatic": "Оценил риски и выгоды",
    "motivation_professional": "Предоставьте возможность обучаться за счет компании, повышайте в должности, признавайте достижения, не контролируйте каждый шаг. Не оплачивайте обучение «профессионалов» просто так, ставьте цели, например что з\\п будет повышена после обучения при условии улучшения результатов.",
    "motivation_patriot": "Поблагодарите сотрудника на глазах у всего коллектива, отмечайте его заслуги визуально (доска почета, звание «лучший сотрудник»). Линейному руководителю предложите войти в состав совета директоров или посещать стратегические совещания. Если в компании патриотов много, разработайте положение о социальной защите работников.",
    "motivation_master": "Рядовому сотруднику дайте возможность брать на себя полную ответственность за выполнение своей работы, делегируйте полномочия. Руководителю предложите курировать стратегические проекты компании, владеть акциями.",
    "motivation_lumpen": "Четко формулируйте задания и требования, причем они должны быть простейшие, например, работа на конвейере. Заботьтесь о сотруднике как родная мать – применяйте методы кнута и пряника.",
    "punishment_professional": "Игнорируйте личные достижения самого сотрудника и его подчиненных, ухудшите условия труда и лишите возможности учиться и развиваться, завалите рутинной работой",
    "punishment_patriot": "Благодарите других сотрудников компании так, чтобы патриот это видел. При этом его не поощряйте. Можно пригрозить увольнением, лишить возможности «опекать» других сотрудников (перевести в другой отдел, филиал)",
    "punishment_master": "Такие сотрудники не терпят, когда их контролируют, указывают, что именно и как нужно делать.",
    "punishment_lumpen": "Пригрозите, что лишите возможности получать единственный доход, не помогайте решать материальные проблемы, лишите доплат и бонусов.",
    "principal_ideolog_emotional": "Эмоции ради идеи",
    "principal_ideolog_vitalist": "Жизнь ради идеи",
    "principal_ideolog_materialist": "Деньги ради идеи",
    "principal_emotional_ideolog": "Идеи ради эмоций",
    "principal_emotional_vitalist": "Жизнь ради эмоций",
    "principal_emotional_materialist": "Деньги ради эмоций",
    "principal_vitalist_ideolog": "Идеи ради жизни",
    "principal_vitalist_emotional": "Эмоции ради жизни",
    "principal_vitalist_materialist": "Деньги ради жизни",
    "principal_materialist_ideolog": "Идеи ради денег",
    "principal_materialist_emotional": "Эмоции ради денег",
    "principal_materialist_vitalist": "Жизнь ради денег",
    "interests_text_materialist": "Материальные блага",
    "interests_text_ideolog": "Принципы",
    "interests_text_emotional": "Эмоциональная удовлетворенность",
    "interests_text_vitalist": "Образ жизни",
    "text_claims_power": "[Стремление к личной власти], личностное влияние, обаяние, стремление распространить своё влияние на максимально большое количество людей.",
    "text_claims_achievement": "[Достижение] высокого результата (рекорда) в конкретной профессиональной области, профессиональная самореализация.",
    "text_claims_involvement": "[Причастность] к определенным системам и структурам, выстраивание личных отношений, исполнение существующих нормативов и процедур.",
    "text_claims_avoid": "[Стремление избежать] ответственности, зависть к чужим успехам.",
    "comment_power_achievement_vitalist_ideolog": "Потенциальный руководитель авторитарно-экспертного плана, диктатор, выросший из рядовых специалистов, который использует своё понимание корпоративных идей, правил и принципов ради укрепления и распространения своего собственного влияния на окружающих. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Для него лучше небольшой оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_power_achievement_vitalist_materialist": "Потенциальный руководитель авторитарно-экспертного плана, диктатор, выросший из рядовых специалистов, который зарабатывает деньги и строит карьеру ради укрепления и распространения своего влияния на окружающих и благополучия своей семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Для него лучше небольшой оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода со всеми наработками к другому работодателю в ситуации конфликта интересов семьи и работы. Потенциальная группа риска. ",
    "comment_power_achievement_vitalist_emotional": "Потенциальный руководитель авторитарно-экспертного плана, диктатор, выросший из рядовых специалистов, который использует свои достижения в области манипуляций эмоциями окружающих в качестве средства укрепления и распространения своего собственного влияния на окружающих, а также своего семейного благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде использования служебного положения в личных целях, а также саботажа с целью повышения собственной важности в глазах руководства или вследствие обиды на «несправедливое отношение». Потенциальная группа риска. ",
    "comment_power_achievement_ideolog_vitalist": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который навязывает свои идеи, правила и принципы окружающим ради укрепления и распространения своего собственного влияния на них. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Для него лучше небольшой оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.  ",
    "comment_power_achievement_ideolog_materialist": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который использует свои достижения в области материального успеха ради торжества своих идей, правил и принципов в области управления. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Для него лучше небольшой оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_power_achievement_ideolog_emotional": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, манипулятор и пропагандист, который направляет свои достижения в области эмоционального влияния на точное соблюдение наработанных им определенных схем, алгоритмов и правил. Эти стандарты он навязывает подчинённым ему экспертам с целью увеличения своей личной власти. Служащий - «зарплатник», - «работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_power_achievement_materialist_vitalist": "Потенциальный руководитель авторитарно-экспертного плана, диктатор у себя в подразделении, выросший из рядовых специалистов, манипулятор и притворщик, который жертвует собственным семейным благополучием ради увеличения своего статуса в области материальных благ и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск создания собственного параллельного бизнеса за счёт использования ресурсов компании. Потенциальная группа риска. ",
    "comment_power_achievement_materialist_ideolog": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который вкладывает свои опыт и знания в увеличение масштабов своего влияния в области материального благополучия и социального успеха. Риск принципиальных разногласий с работодателем и увода подчинённых ему экспертов вместе со всеми их наработками в самостоятельный бизнес. Риск коррупционной составляющей в виде возможной продажи закрытой коммерческой информации на сторону при наличии более выгодных материальных перспектив. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Потенциальная группа риска. ",
    "comment_power_achievement_materialist_emotional": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, манипулятор и притворщик, который жертвует собственным эмоциональным комфортом ради увеличения своего статуса в области материальных благ и социального успеха. Риск коррупционной составляющей. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Потенциальная группа риска. ",
    "comment_power_achievement_emotional_vitalist": "Потенциальный руководитель авторитарно-экспертного плана, «самодур», выросший из рядовых специалистов, который использует своё умение подчинять окружающих своему влиянию, ради своего эмоционального комфорта. Иногда его «самодурство» может зашкаливать, что чревато увольнением эффективных сотрудников с формулировкой «не сошлись характерами» с руководством. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_power_achievement_emotional_ideolog": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, диктатор и «самодур», который использует своё понимание корпоративных идей, правил и принципов ради подчинения окружающих своему влиянию. Иногда его «самодурство» может зашкаливать, что чревато увольнением эффективных сотрудников с формулировкой «не сошлись характерами» с руководством. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_power_achievement_emotional_materialist": "Потенциальный руководитель авторитарно-экспертного плана, «азартный игрок», который вкладывает свои достижения максимального результата в области материального благополучия в обеспечение собственного максимального эмоционального комфорта. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде использования материальных и финансовых ресурсов работодателя в своих личных целях ради удовлетворения эмоциональных потребностей. Потенциальная группа риска. ",
    "comment_power_avoid_emotional_vitalist": "Потенциальный руководитель авторитарно-бюрократического плана, «самодур» и диктатор, который стремится избежать ответственности за разрушение своей деятельностью привычного образа жизни других людей, ради своего эмоционального комфорта. Его «самодурство» может привести к разрушению всей руководимой им структуры вследствие его эмоционального всплеска по любому поводу. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_power_avoid_vitalist_emotional": "Потенциальный руководитель авторитарно-бюрократического плана, ловкий манипулятор, который стремится избежать ответственности за разрушение своей деятельностью эмоционального комфорта других людей, ради увеличения своей личной власти. Его манипуляции могут привести к разрушению возглавляемой им структуры вследствие чрезмерного количества интриг. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска. ",
    "comment_power_avoid_vitalist_ideolog": "Потенциальный руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради укрепления своей личной власти. Основная стратегия деятельности-сохранение и поддержание существующего положения вещей без изменений, опора на традиции. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_power_avoid_vitalist_materialist": "Потенциальный руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за разрушение материального обеспечения других людей ради укрепления своей личной власти и семейного благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде использования корпоративных ресурсов в личных целях по семейным причинам. Потенциальная группа риска.",
    "comment_power_avoid_materialist_vitalist": "Потенциальный руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за разрушение своей деятельностью привычного образа жизни других людей, ради своего материального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка за счет ресурсов работодателя в ситуации более выгодных материальных перспектив.  Потенциальная группа риска.",
    "comment_power_avoid_materialist_ideolog": "Потенциальный руководитель авторитарно-бюрократического плана, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради увеличения масштабов своего влияния в области материального благополучия и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи закрытой служебной информации на сторону в ситуациях более выгодных материальных перспектив. Потенциальная группа риска. ",
    "comment_power_avoid_materialist_emotional": "Потенциальный руководитель авторитарно-бюрократического плана, манипулятор и притворщик, который жертвует своим и чужим эмоциональным комфортом ради увеличения своего материального и социального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка за счет ресурсов работодателя в ситуациях более выгодных материальных перспектив. Потенциальная группа риска.",
    "comment_power_avoid_emotional_ideolog": "Потенциальный руководитель авторитарно-бюрократического плана, который жертвует корпоративными стандартами правилами и принципами ради подчинения окружающих своему влиянию. Иногда его «самодурство» может зашкаливать, что чревато увольнением эффективных сотрудников с формулировкой «не сошлись характерами» с руководством вплоть до разрушения всей структуры. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_power_avoid_emotional_materialist": "Потенциальный руководитель авторитарно-бюрократического плана, «азартный игрок», который жертвует своим материальным благополучием ради подчинения окружающих своему влиянию, покупает подчинение своих сотрудников. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работатьПотенциальный руководитель авторитарно-бюрократического плана, который жертвует корпоративными стандартами правилами и принципами ради подчинения окружающих своему влиянию. Иногда его «самодурство» может зашкаливать, что чревато увольнением эффективных сотрудников с формулировкой «не сошлись характерами». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде использования ресурсов работодателя в своих интересах под внешним благовидным предлогом. Потенциальная группа риска.",
    "comment_power_involvement_vitalist_ideolog": "Потенциальный руководитель административно-бюрократического плана, который поддерживает устоявшиеся корпоративные стандарты и правила ради сохранения и поддержания определённого качества жизни своей семьи. Управление с опорой на преданность сотрудника в большей степени, чем на его грамотность и профессионализм. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_power_involvement_vitalist_emotional": "Руководитель административно-бюрократического плана, манипулятор, который использует эмоциональные отношения и своё умение манипулировать окружающими ради распространения и укрепления своей власти на работе. Управление по принципу «королевский двор». Предпочитает окружать себя в большей степени верными и преданными людьми, нежели чем профессиональными и эффективными. Своих «придворных» он расставляет по местам, руководствуясь интересами своей вотчины. Работает ради ощущения «власти» и собственной вседозволенности. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_power_involvement_vitalist_materialist": "Потенциальный руководитель административно-бюрократического типа, который вкладывает свои материальные ресурсы в увеличение личной власти в своей семье. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка на ресурсах работодателя в случаях «семейной необходимости». Потенциальная группа риска.",
    "comment_power_involvement_ideolog_vitalist": "Потенциальный руководитель административно-бюрократического плана, который жёстко навязывает свои стандарты, идеи и правила окружающим ради укрепления своей личной власти. Служащий - «зарплатник», - «работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_power_involvement_ideolog_materialist": "Потенциальный руководитель административно-бюрократического типа, который использует свою причастность к материальным средствам ради насаждения своих идей, правил и принципов. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода вместе со всеми наработками и командой в ситуациях идейных разногласий с руководства. Потенциальная группа риска.",
    "comment_power_involvement_ideolog_emotional": "Потенциальный руководитель административно-бюрократического плана, который подчиняет себе эмоции окружающих его людей ради торжества своих идей и правил. Служащий - «зарплатник», - «работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода вместе со всеми наработками и командой в ситуациях идейных разногласий с руководства. Потенциальная группа риска.",
    "comment_power_involvement_materialist_vitalist": "Потенциальный руководитель административно-бюрократического плана, который поддерживает личные отношения ради собственного максимального и социального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах работодателя в ситуации более широких материальных перспектив. Потенциальная группа риска.",
    "comment_power_involvement_materialist_ideolog": "Потенциальный руководитель административно-бюрократического плана, который использует идеи и принципы других людей ради увеличения своего личного материального благополучия и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи закрытой информации на сторону в случае более широких материальных перспектив. Потенциальная группа риска.",
    "comment_power_involvement_materialist_emotional": "Потенциальный руководитель административно-бюрократического плана, манипулятор и притворщик, который подчиняет себе эмоции окружающих его людей ради увеличения своего личного материального благополучия и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде получения откатов. Потенциальная группа риска. ",
    "comment_power_involvement_emotional_vitalist": "Потенциальный руководитель административно-бюрократического плана, который поддерживает личные отношения ради собственного эмоционального комфорта и благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Управление по принципу «королевский двор» с выделением фаворитов при формальном соблюдении корпоративных правил и стандартов. Потенциальная группа риска. ",
    "comment_power_involvement_emotional_ideolog": "Потенциальный руководитель административно-бюрократического плана, «капризный» манипулятор, который использует идеи и принципы подчинённых ему людей ради своего эмоционального комфорта. Управление по принципу «королевский двор». Предпочитает окружать себя в большей степени верными и преданными людьми, нежели чем профессиональными и эффективными. Своих «придворных» он расставляет по местам, руководствуясь интересами своей вотчины. Работает ради ощущения «власти» и собственной вседозволенности. Служащий - «зарплатник». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_power_involvement_emotional_materialist": "Потенциальный руководитель административно-бюрократического плана, «азартный игрок», который поддерживает взаимовыгодные материальные отношения ради достижения собственного максимального эмоционального комфорта. Служащий-«зарплатник». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде использования ресурсов работодателя в личных целях под внешним благовидным предлогом. Потенциальная группа риска.",
    "comment_achievement_power_vitalist_ideolog": "Потенциальный руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который навязывает свои идеи, правила и принципы окружающим на работе ради максимального улучшения своего качества жизни и благополучия своей семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_power_vitalist_materialist": "Потенциальный руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который направляет свои личные амбиции и материальные ресурсы на улучшение качества своей жизни и благополучие семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде получения откатов при «семейной необходимости» в том случае, если руководство не поможет решить вопрос по-другому. Потенциальная группа риска. ",
    "comment_achievement_power_vitalist_emotional": "Потенциальный руководитель службы, региональный представитель, выросший из рядовых специалистов, направляющий свои личные амбиции и умение манипулировать окружающими людьми на сохранение и поддержание своего привычного образа жизни, и улучшение качества жизни своей семьи. Проектный боец под решение конкретной задачи или под определённый функционал. Служащий - «зарплатник», - «работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_power_ideolog_vitalist": "Потенциальный руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который распространяет своё влияние на окружающих ради точного соблюдения наработанных им определенных схем, алгоритмов и правил, корпоративных стандартов. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_achievement_power_ideolog_materialist": "Потенциальный руководитель службы, профессиональной экспертной группы, выросший из рядовых специалистов, который использует свой контроль над распределением материальных ресурсов ради точного соблюдения наработанных им определенных схем, алгоритмов и правил. Служащий-«зарплатник». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_power_ideolog_emotional": "Потенциальный руководитель профессиональной экспертной группы, выросший из рядовых специалистов, который жертвует своим эмоциональным комфортом ради точного соблюдения собственных, выработанных на практике схем, алгоритмов и правил. «Проектный боец», который работает ради определённого интереса. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_power_materialist_vitalist": "Коммерсант, потенциальный руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, который использует своё влияние на окружающих в качестве средства достижения высоких результатов в области максимального материального благополучия и социального успеха. Риск коррупционной составляющей в случае «семейной необходимости», если руководство не поможет решить вопрос. Потенциальная группа риска. ",
    "comment_achievement_power_materialist_ideolog": "Потенциальный руководитель небольшой службы специалистов, выросший из рядовых специалистов, направляющий свои личные амбиции идеи и принципы на достижение своего стабильного материального благополучия. Риск ухода к другому работодателю со всеми наработками в случае материальной нестабильности, а также использования корпоративных ресурсов для дополнительного заработка. Потенциальная группа риска.",
    "comment_achievement_power_materialist_emotional": "Руководитель службы, региональный представитель, выросший из рядовых специалистов, манипулятор и притворщик, направляющий свои эмоции и умение манипулировать окружающими людьми на достижение своего максимального материального благополучия. Предприниматель, проектный боец, «бонусник», - «живёт, чтобы работать», для него лучше сдельная зарплата с хорошей премией или «бонусом» по итогу работы, нежели чем стабильный, но небольшой оклад. Риск коррупционной составляющей и ухода на новое место вместе со всеми наработками при появлении более выгодных условий личного обогащения на другом месте. Потенциальная группа риска.",
    "comment_achievement_power_emotional_vitalist": "Потенциальный руководитель службы, региональный представитель, выросший из рядовых специалистов, направляющий свои личные амбиции и привычный образ жизни на достижение своего максимального эмоционального комфорта. Служащий-«зарплатник». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_achievement_power_emotional_ideolog": "Авторитарный руководитель небольшой профессиональной экспертной группы, выросший из рядовых специалистов, диктатор и «самодур», который может жертвовать точным соблюдением определенных корпоративных схем, алгоритмов и правил ради своего эмоционального комфорта. Иногда его «самодурство» может зашкаливать, что чревато увольнением эффективных сотрудников с формулировкой «не сошлись характером» с руководством. Потенциальная группа риска",
    "comment_achievement_power_emotional_materialist": "Потенциальный руководитель службы, региональный представитель, выросший из рядовых специалистов, «азартный игрок», жертвующий своим материальным благополучием ради достижения своего максимального эмоционального комфорта. Служащий-«зарплатник». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск использования ресурсов работодателя в личных целях под внешним благовидным предлогом. Потенциальная группа риска. ",
    "comment_achievement_avoid_vitalist_ideolog": "Специалист-одиночка, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради достижения своего максимального семейного благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_avoid_vitalist_materialist": "Специалист-одиночка, который жертвует своим материальным благополучием и социальным успехом в пользу улучшения качества жизни своей семьи. Ради обеспечения семейного благополучия возможны дополнительные заработки на стороне. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_avoid_vitalist_emotional": "Специалист-одиночка, манипулятор, который жертвует своим эмоциональным комфортом ради максимального улучшения качества своей жизни и своего семейного благополучия. Проектный боец под конкретный функционал или задачу. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_achievement_avoid_ideolog_vitalist": "Специалист-одиночка, манипулятор, который стремится избежать ответственности за свои семейные отношения и привычный образ жизни ради точного соблюдения определенных схем, алгоритмов и правил. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_avoid_ideolog_materialist": "Специалист-одиночка, который жертвует своим материальным благополучием ради точного соблюдения определенных схем, алгоритмов и правил. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода со своими наработками на сторону в случае идейных разногласий с руководством. Потенциальная группа риска.",
    "comment_achievement_avoid_ideolog_emotional": "Специалист-одиночка, который жертвует своим эмоциональным комфортом ради точного соблюдения определенных схем, алгоритмов и правил. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода со своими наработками на сторону в случае идейных разногласий с руководством. Потенциальная группа риска.",
    "comment_achievement_avoid_materialist_vitalist": "Специалист-одиночка, который жертвует своим привычным образом жизни ради достижения своего максимального материального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде заработка на ресурсах компании при появлении перспектив улучшения качества жизни. Потенциальная группа риска.",
    "comment_achievement_avoid_materialist_ideolog": "Специалист-одиночка, который стремится избежать ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради достижения своего материального благополучия и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи закрытой служебной информации на сторону при появлении материальных перспектив. Потенциальная группа риска.",
    "comment_achievement_avoid_materialist_emotional": "Специалист-одиночка, который жертвует своим эмоциональным комфортом ради достижения своего материального благополучия и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании при появлении более выгодных материальных перспектив. Потенциальная группа риска.",
    "comment_achievement_avoid_emotional_vitalist": "Специалист-одиночка, который жертвует своим привычным образом жизни ради достижения своего эмоционального удовлетворения от проделанной работы. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск эмоциональных интриг на работе. ",
    "comment_achievement_avoid_emotional_ideolog": "Специалист-одиночка без перспектив карьерного роста, «самодур», который избегает ответственности за создание управленческих решений и алгоритмов. Следует утверждённым корпоративным стандартам ради достижения своего максимального эмоционального комфорта. Иногда его «самодурство» может приводить к жестким конфликтам с более эффективными коллегами на работе. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_avoid_emotional_materialist": "Специалист-одиночка, «азартный игрок», который стремится избежать ответственности за свое материальное благополучие ради достижения собственного максимального эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск использования корпоративных ресурсов в личных целях под внешним благовидным предлогом. Потенциальная группа риска.",
    "comment_achievement_involvement_vitalist_ideolog": "Специалист-одиночка, который направляет своё умение следовать существующим утвержденным корпоративным стандартам ради максимального улучшения качества своей жизни и своего семейного благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_involvement_vitalist_materialist": "Специалист-одиночка, который вкладывает своё материальное благополучие в максимальное улучшение качества своей жизни. Служащий - «зарплатник», - «работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Возможен риск коррупционной составляющей при «семейной необходимости». Потенциальная группа риска.",
    "comment_achievement_involvement_vitalist_emotional": "Специалист-одиночка без перспектив карьерного роста, «проектный боец», наёмник под конкретную задачу или функционал, манипулятор, который платит своим эмоциональным комфортом за максимальное улучшение качества своей жизни. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_involvement_ideolog_vitalist": "Специалист-одиночка без перспектив карьерного роста, наёмник под конкретную задачу или функционал, который платит своим привычным образом жизни за точное соблюдение определенных схем, алгоритмов и правил. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_achievement_involvement_ideolog_materialist": "Специалист-одиночка, который платит своим материальным благополучием за точное соблюдение определенных схем, алгоритмов и правил. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода со семи наработками в случае принципиальных разногласий с руководством. Потенциальная группа риска.",
    "comment_achievement_involvement_ideolog_emotional": "Специалист-одиночка с перспективами карьерного роста до руководителя среднего звена (небольшой группы специалистов), который жертвует своим эмоциональным комфортом ради точного соблюдения определенных схем, алгоритмов и правил. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода со всеми своими наработками в случае принципиальных разногласий с руководством. Потенциальная группа риска.",
    "comment_achievement_involvement_materialist_vitalist": "Специалист-одиночка, «наёмник», который подчиняет свой образ жизни достижению максимального материального благополучия и социального успеха. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать». Сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании в ситуации более широких материальных перспектив. Потенциальная группа риска.",
    "comment_achievement_involvement_materialist_ideolog": "Проектный боец, специалист-одиночка, наёмник, который направляет свои идеи, принципы и правила поведения на достижение высоких результатов в области своего материального благополучия. Предприниматель, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи закрытой коммерческой информации на сторону. Потенциальная группа риска. ",
    "comment_achievement_involvement_materialist_emotional": "Специалист-одиночка, который направляет своё умение выстраивать прочные эмоциональные связи и отношения ради достижения высоких результатов в области своего материального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка за счет ресурсов работодателя в ситуации более широких материальных перспектив. Потенциальная группа риска.",
    "comment_achievement_involvement_emotional_vitalist": "Специалист-одиночка, который направляет своё умение выстраивать прочные отношения в семье ради достижения собственного максимального эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_involvement_emotional_ideolog": "Специалист-одиночка, который использует точное соблюдение определенных схем, алгоритмов и правил ради увеличения своего эмоционального комфорта. Иногда его «самодурство» может зашкаливать, что чревато появлением интриг и серьезных конфликтов с более эффективными коллегами на работе. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_achievement_involvement_emotional_materialist": "Специалист-одиночка, «азартный игрок», который поддерживает взаимовыгодные материальные отношения ради достижения собственного максимального эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде использования корпоративных ресурсов в личных интересах под внешним благовидным предлогом. Потенциальная группа риска.",
    "comment_avoid_power_vitalist_ideolog": "Исполнитель без перспектив карьерного роста, который жертвует своими принципами и определенными алгоритмами ради избегания ответственности за привычный образ жизни и семейные отношения. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на системе и саботирования распоряжений руководства под видом идейных противоречий. Потенциальная группа риска.",
    "comment_avoid_power_vitalist_materialist": "Исполнитель без перспектив карьерного роста, который жертвует своим материальным благополучием ради избегания ответственности за семейные отношения. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка на ресурсах работодателя при благоприятных условиях. Потенциальная группа риска.",
    "comment_avoid_power_vitalist_emotional": "Исполнитель без перспектив карьерного роста. Манипулятор, который избегает личной ответственности за принятые решения. Риск саботирования распоряжений вышестоящего руководства ради сохранения своего эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_avoid_power_ideolog_vitalist": "Исполнитель без перспектив карьерного роста, который избегает личной ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради увеличения своего семейного благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск саботирования распоряжений вышестоящего руководства под видом идейных противоречий. Потенциальная группа риска.",
    "comment_avoid_power_ideolog_materialist": "Исполнитель без перспектив карьерного роста, который избегает личной ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради увеличения своего материального благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода с закрытой служебной информацией и корпоративными наработками на более выгодные условия труда. Потенциальная группа риска.",
    "comment_avoid_power_ideolog_emotional": "Исполнитель без перспектив карьерного роста, который избегает личной ответственности за принятие управленческих решений и алгоритмов, следуя утверждённым корпоративным стандартам ради сохранения своего эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск саботирования распоряжений вышестоящего руководства, интриг и провокаций против коллег в силу идейных противоречий. Потенциальная группа риска.",
    "comment_avoid_power_materialist_vitalist": "Исполнитель без перспектив карьерного роста, который жертвует своим привычным образом жизни ради избегания ответственности за своё материальное благополучие. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка за счет ресурсов работодателя при благоприятных условиях. Потенциальная группа риска.",
    "comment_avoid_power_materialist_ideolog": "Исполнитель без перспектив карьерного роста, который жертвует своими принципами и определенными алгоритмами ради избегания ответственности за доступ к источнику своего материального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи закрытой коммерческой информации на сторону при благоприятных условиях. Потенциальная группа риска.",
    "comment_avoid_power_materialist_emotional": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом ради избегания ответственности за доступ к источнику своего материального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании при благоприятных условиях. Потенциальная группа риска. ",
    "comment_avoid_power_emotional_vitalist": "Исполнитель без перспектив карьерного роста, который использует своё умение влиять на окружающих ради разрушения эмоционального комфорта окружающих и своего собственного. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск интриг против более эффективных коллег на работе и утечки секретной информации под влиянием сильных эмоций. Потенциальная группа риска.",
    "comment_avoid_power_emotional_ideolog": "Исполнитель без перспектив карьерного роста, провокатор и саботажник, который жертвует своими идеями, правилами и принципами ради разрушения эмоционального комфорта окружающих и своего собственного. Его «самодурство» провокаций и интриги могут привести к разрушению той структуры, в которой он работает. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска. ",
    "comment_avoid_power_emotional_materialist": "Исполнитель без перспектив карьерного роста, «азартный игрок», провокатор, который жертвует своим материальным благополучием ради разрушения эмоционального комфорта окружающих и своего собственного. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде использования корпоративных ресурсов в личных целях под внешним благовидным предлогом. Потенциальная группа риска.",
    "comment_avoid_achievement_vitalist_ideolog": "Исполнитель без перспектив карьерного роста, который жертвует своим собственными принципами в пользу избегания ответственности за своё семейное благополучие. «Терпит» ради семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на системе и саботирования распоряжений руководства под видом идейного конфликта. Потенциальная группа риска.",
    "comment_avoid_achievement_vitalist_materialist": "Исполнитель без перспектив карьерного роста, который жертвует своим собственным материальным благополучием в пользу избегания ответственности за благополучие семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. «Откупается» от необходимости решать вопросы своей семьи. Риск коррупционной составляющей в виде заработка на ресурсах работодателя в случае личной или «семейной необходимости». Потенциальная группа риска.",
    "comment_avoid_achievement_vitalist_emotional": "Исполнитель без перспектив карьерного роста, который использует своё умение влиять на окружающих ради избегания личной ответственности за свои действия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на системе и саботирования распоряжений руководства с уходом в интриги по отношению к более эффективным коллегам. Из-за его интриг существует опасность развала подразделения или организации, в которой он работает. Потенциальная группа риска.",
    "comment_avoid_achievement_ideolog_vitalist": "Исполнитель без перспектив карьерного роста, который жертвует своим привычным образом жизни ради избегания ответственности за принятие управленческих решений, следуя установленным корпоративным стандартам. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на системе и саботирования распоряжений руководства под видом идейных противоречий. Потенциальная группа риска.",
    "comment_avoid_achievement_ideolog_materialist": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за определенные схемы и алгоритмы-источники его материального благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде слива закрытой коммерческой информации на сторону по идейным соображениям или за материальные вознаграждения. Потенциальная группа риска. ",
    "comment_avoid_achievement_ideolog_emotional": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом, следуя имеющимся корпоративным стандартам. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск слива закрытой служебной информации на сторону вследствие обиды на руководство, по идейным соображениям. Потенциальная группа риска.",
    "comment_avoid_achievement_materialist_vitalist": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за материальное благополучие своей семьи. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах работодателя при наличии благоприятной возможности. Потенциальная группа риска.",
    "comment_avoid_achievement_materialist_ideolog": "Исполнитель без перспектив карьерного роста, который жертвует своими идеями и принципами ради избегания ответственности за своё материальное благополучие. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи на сторону закрытой коммерческой информации при наличии благоприятной возможности. Потенциальная группа риска.",
    "comment_avoid_achievement_materialist_emotional": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом ради избегания ответственности за своё материальное благополучие. «Терпит» и притворяется ради «бонусов» и зарплаты. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска.",
    "comment_avoid_achievement_emotional_vitalist": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за эмоциональный комфорт своей семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск служебных романов, конфликтов и интриг на работе. Потенциальная группа риска.",
    "comment_avoid_achievement_emotional_ideolog": "Исполнитель без перспектив карьерного роста, харизматичный саботажник, провокатор, интриган, который жертвует своими идеями и принципами ради избегания ответственности за собственный эмоциональный комфорт. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Иногда его «самодурство» может зашкаливать, что может привести к развалу организации или подразделения, в котором он работает. Потенциальная группа риска.",
    "comment_avoid_achievement_emotional_materialist": "Исполнитель без перспектив карьерного роста, «азартный игрок», который жертвует своим материальным благополучием ради избегания ответственности за свой эмоциональный комфорт. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде растраты корпоративных ресурсов под внешним благовидным предлогом. Потенциальная группа риска.",
    "comment_avoid_involvement_vitalist_ideolog": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за свой формальный подход к работе по принципу «мне сказали-я делаю». Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на ресурсах системы и саботирования распоряжений руководства «по семейным обстоятельствам». Потенциальная группа риска. ",
    "comment_avoid_involvement_vitalist_materialist": "Исполнитель без перспектив карьерного роста, который использует свою причастность к материальному благополучию ради избегания ответственности за ситуацию в семье. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка за счет ресурсов компании при благоприятной возможности. Потенциальная группа риска.",
    "comment_avoid_involvement_vitalist_emotional": "Исполнитель без перспектив карьерного роста, манипулятор, который стремится избежать ответственности за своё семейное благополучие. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на ресурсах системы и саботирования распоряжений руководства по «семейной необходимости» и ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска.",
    "comment_avoid_involvement_ideolog_vitalist": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за своё семейное благополучие, следуя имеющимся корпоративным стандартам. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на ресурсах системы и саботирования распоряжений руководства под видом идейных разногласий. Потенциальная группа риска.",
    "comment_avoid_involvement_ideolog_materialist": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за свою причастность к определенным схемам, обеспечивающим ему доступ к источнику его материального благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Возможный коррупционер. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании, а также продажа коммерческой информации на сторону. Потенциальная группа риска.",
    "comment_avoid_involvement_ideolog_emotional": "Исполнитель без перспектив карьерного роста, который жертвует своим эмоциональным комфортом в имеющихся отношениях, следуя имеющимся корпоративным стандартам. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск слива на сторону закрытой служебной информации в силу идейных противоречий с политикой компании или обиды на руководство. Потенциальная группа риска.",
    "comment_avoid_involvement_materialist_vitalist": "Исполнитель без перспектив карьерного роста, который направляет свою жизнь на избегание ответственности за собственное материальное благополучие. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании при благоприятной возможности. Потенциальная группа риска.",
    "comment_avoid_involvement_materialist_ideolog": "Исполнитель без перспектив карьерного роста, который использует свою причастность к определённым идейным установкам ради избегания ответственности за собственное материальное благополучие. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде закрытой служебной информации на сторону при благоприятной возможности. Потенциальная группа риска.",
    "comment_avoid_involvement_materialist_emotional": "Исполнитель без перспектив карьерного роста, который стремится избежать ответственности за свою причастность к определенному источнику своего материального благополучия. Потенциальный «бонусник», возможный коррупционер. Предприниматель, проектный боец, «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска.",
    "comment_avoid_involvement_emotional_vitalist": "Исполнитель без перспектив карьерного роста, который направляет свою жизнь ради избегания ответственности за собственное эмоциональное благополучие. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск саботирования распоряжений руководства и паразитирования на ресурсах системы ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска.",
    "comment_avoid_involvement_emotional_ideolog": "Исполнитель без перспектив карьерного роста, провокатор, саботажник, интриган, который стремится избежать ответственности за свою причастность к определенным схемам и алгоритмам. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Иногда его «самодурство» может зашкаливать, что приводит к конфликтам и интригам против эффективных коллег, что, в конечным итоге, может привести к развалу подразделения или организации, в которой он работает. Потенциальная группа риска.",
    "comment_avoid_involvement_emotional_materialist": "Исполнитель без перспектив карьерного роста, «азартный игрок», который стремится избежать ответственности за своё материальное благополучие и социальный успех. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде растрат ресурсов компании ради собственного эмоционального удовлетворения под благовидным предлогом. Потенциальная группа риска.",
    "comment_involvement_power_vitalist_ideolog": "Помощник руководителя, исполнитель без перспектив карьерного роста, который направляет свои личные амбиции в области управленческих решений и алгоритмов на сохранение и поддержание привычного образа жизни. Стиль деятельности направлен на сохранение и поддержание существующего положения вещей без изменений. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_involvement_power_vitalist_materialist": "Помощник руководителя, исполнитель без перспектив карьерного роста, который вкладывает свои материальные ресурсы в сохранение и поддержание привычного образа жизни и своего семейного благополучия, «работает, чтобы жить, а не живёт, чтобы работать. Стиль деятельности направлен на сохранение и поддержание существующего положения вещей без изменений. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей возможен при «семейной необходимости» в виде личного приработка, за счёт тайного использования ресурсов компании, если руководство не поможет решить вопрос по-другому. Потенциальная группа риска.  ",
    "comment_involvement_power_vitalist_emotional": "Помощник руководителя, исполнитель без перспектив карьерного роста, «манипулятор», провокатор, интриган, который направляет свои личные эмоции на сохранение и поддержание привычного образа жизни. Проектный боец, наемник под конкретный функционал или задачу. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Существует риск саботирования распоряжений руководства ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска. ",
    "comment_involvement_power_ideolog_vitalist": "Помощник руководителя, который направляет свою жизнь на сохранение и поддержание своей принадлежности к определённым идейным установкам и правилам. Стиль деятельности направлен на сохранение и поддержание существующего положения вещей без изменений с ориентацией на традиции и корпоративные стандарты. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_power_ideolog_materialist": "Помощник руководителя, который направляет свои материальные ресурсы на сохранение и поддержание своей принадлежности к определённым идейным установкам и правилам. Стиль деятельности направлен на сохранение и поддержание существующей идеологии руководства и корпоративных стандартов. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_power_ideolog_emotional": "Помощник руководителя, который жертвует своим эмоциональным комфортом ради сохранения и поддержания своей принадлежности к определённым идейным установкам и правилам. Стиль деятельности направлен на сохранение и поддержание существующей идеологии руководства и корпоративных стандартов. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_involvement_power_materialist_vitalist": "Помощник руководителя, который жертвует привычным образом жизни ради сохранения доступа к источнику своего материального благополучия. Служащий, «бонусник», который бежит из семьи на работу, чтобы получать зарплату и различные материальные поощрения. «Живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей возможен при «семейной необходимости» в виде личного приработка, за счёт тайного использования ресурсов компании, если руководство не поможет решить вопрос по-другому. Потенциальная группа риска. ",
    "comment_involvement_power_materialist_ideolog": "Помощник руководителя, который обеспечивает сохранение и поддержание существующих процессов и алгоритмов работы ради сохранения доступа к источнику своего материального благополучия. Служащий, «бонусник», который бежит из семьи на работу, чтобы получать зарплату и различные материальные поощрения. «Живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи закрытой корпоративной информации на сторону в случае материальных затруднений, если руководство не поможет решить вопрос по-другому. Потенциальная группа риска. ",
    "comment_involvement_power_materialist_emotional": "Помощник руководителя, который жертвует своим эмоциональным комфортом ради сохранения и поддержания своей принадлежности к определённому уровню материального благосостояния. «Терпит ради денег». Служащий, «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании в ситуации личной обиды, если руководство не поможет решить вопрос по-другому. Потенциальная группа риска. ",
    "comment_involvement_power_emotional_vitalist": "Помощник руководителя, манипулятор, который жертвует своими амбициями в области семейного благополучия в пользу сохранения своего эмоционального комфорта в имеющихся отношениях на работе. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск служебного романа или эмоциональных интриг на работе. Потенциальная группа риска. ",
    "comment_involvement_power_emotional_ideolog": "Помощник руководителя, который подчиняет свои идеи, правила и принципы сохранению и поддержанию своего эмоционального комфорта в имеющихся отношениях. При накоплении сильного эмоционального напряжения, он способен саботировать распоряжения руководства, выступая в роли провокатора. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска. ",
    "comment_involvement_power_emotional_materialist": "Помощник руководителя, «азартный игрок», «авантюрист», который жертвует своими амбициями в области материального благополучия в пользу своего эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании при личной эмоциональной потребности. Потенциальная группа риска.  ",
    "comment_involvement_achievement_vitalist_ideolog": "«Командный игрок», исполнитель, который точно соблюдает определённые идеи, правила и принципы в пользу сохранения и поддержания своей приверженности семье, и привычному для него образу жизни. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_achievement_ideolog_vitalist": "«Командный игрок», исполнитель, который подчиняет свой привычный образ жизни привычной работе с точным соблюдением существующих корпоративных идей, правил и принципов. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. ",
    "comment_involvement_achievement_ideolog_materialist": "«Командный игрок», исполнитель, который рассматривает своё материальное благополучие как следствие точного соблюдения корпоративных идей, правил и принципов. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_achievement_vitalist_materialist": "«Командный игрок», исполнитель, который вкладывает свои материальные ресурсы в улучшения качества жизни своей семьи. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Возможна коррупционная составляющая при «семейной необходимости». Потенциальная группа риска",
    "comment_involvement_achievement_vitalist_emotional": "«Командный игрок», исполнитель без перспектив карьерного роста под конкретный функционал или задачу, который вкладывается своим эмоциональным комфортом и умением манипулировать на работе ради сохранения своего семейного благополучия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_achievement_ideolog_emotional": "«Командный игрок», исполнитель, который жертвует своим эмоциональным комфортом ради сохранения привычной, известной ему работы. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск ухода по идейным противоречиям. ",
    "comment_involvement_achievement_materialist_vitalist": "«Вечный заместитель», командный игрок, «второй», который жертвует своими личными и семейными интересами ради материальных «бонусов», премий, стабильного карьерного роста и повышения своего профессионального статуса. Служащий, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск дополнительных подработок при семейной необходимости за счет ресурсов компании. Потенциальная группа риска. ",
    "comment_involvement_achievement_materialist_ideolog": "Исполнитель, командный игрок, который вкладывает свои достижения в области улучшения корпоративных стандартов и алгоритмов в «общий командный котёл», ради стабильного получения зарплаты. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск продажи коммерческой информации на сторону при благоприятных условиях. Потенциальная группа риска. ",
    "comment_involvement_achievement_materialist_emotional": "«Командный игрок», исполнитель, который жертвует своим эмоциональным комфортом ради сохранения и поддержания привычного уровня материального благополучия и социального успеха (ради стабильного получения зарплаты, бонусов и премий). Предприниматель, проектный боец, «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде заработка на ресурсах компании или продажи закрытой коммерческой информации на сторону при благоприятной возможности в ситуации обиды на руководство. Потенциальная группа риска. ",
    "comment_involvement_achievement_emotional_vitalist": "«Командный игрок», исполнитель, который направляет свою жизнь на сохранение и поддержание своей эмоциональной причастности к определённому человеку или сообществу. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск служебных романов на работе и утечки закрытой служебной информации на сторону под влиянием сильных эмоций. Потенциальная группа риска. ",
    "comment_involvement_achievement_emotional_ideolog": "Манипулятор, интриган, саботажник и провокатор, который может объяснить и оправдать любое эмоциональное состояние и поведение. Иногда эти его особенности могут стать причиной конфликтов и увольнения более эффективных и полезных сотрудников. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска. ",
    "comment_involvement_achievement_emotional_materialist": "Командный, «азартный игрок», который направляет свои материальные ресурсы на переживание пиковых эмоциональных состояний. Провокатор. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск растрат ресурсов организации в личных целях под внешним благовидным предлогом ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска. ",
    "comment_involvement_avoid_vitalist_ideolog": "Исполнитель без перспектив карьерного роста, который избегает ответственности за принятие решений в отношении управленческих алгоритмов, ради сохранения и поддержания привычного образа жизни. «Зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на ресурсах компании и саботаж распоряжений руководства под видом идейных разногласий. Потенциальная группа риска. ",
    "comment_involvement_avoid_vitalist_materialist": "Исполнитель без перспектив карьерного роста, который избегает ответственности за своё материальное благополучие ради привычного образа жизни. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск коррупционной составляющей в виде тайного заработка на ресурсах компании при семейной необходимости. Потенциальная группа риска. ",
    "comment_involvement_avoid_vitalist_emotional": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свой эмоциональный комфорт ради сохранения и поддержания привычного образа жизни. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск паразитирования на ресурсах системы и саботаж распоряжений руководства под видом идейных противоречий ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска. ",
    "comment_involvement_avoid_ideolog_vitalist": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свою семью ради сохранения привычной, известной ему работы. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_avoid_ideolog_materialist": "Исполнитель без перспектив карьерного роста, который избегает ответственности за своё материальное благополучие ради следования утверждённым правилам и алгоритмам работы. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_avoid_ideolog_emotional": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свой эмоциональный комфорт ради следования существующим правилам принципам и стандартам. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_involvement_avoid_materialist_vitalist": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свою семью ради сохранения доступа к источнику своего материального благополучия (зарплат, бонусов и премий). Служащий, «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде заработка на ресурсах компании в благоприятной ситуации. Потенциальная группа риска. ",
    "comment_involvement_avoid_materialist_ideolog": "Исполнитель без перспектив карьерного роста, который избегает ответственности за принятие решений в отношении управленческих алгоритмов, следует утверждённым правилам ради стабильного получения зарплаты, бонусов и премий. Служащий, «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде продажи на сторону закрытой служебной информации при благоприятных условиях. Потенциальная группа риска. ",
    "comment_involvement_avoid_materialist_emotional": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свой эмоциональный комфорт, ради сохранения доступа к источнику своего материального благополучия. Предприниматель, проектный боец, «бонусник», «живёт, чтобы работать», сдельная зарплата с хорошей премией или «бонусом» по итогу работы для него лучше, чем стабильный и небольшой оклад. Риск коррупционной составляющей в виде заработка на ресурсах компании при благоприятных условиях в ситуации обиды на руководство. Потенциальная группа риска. ",
    "comment_involvement_avoid_emotional_vitalist": "Исполнитель без перспектив карьерного роста, который избегает ответственности за свою семью ради сохранения своего эмоционального комфорта. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск утечки на сторону закрытой информации под влиянием сильных эмоций. Потенциальная группа риска. ",
    "comment_involvement_avoid_emotional_ideolog": "Исполнитель без перспектив карьерного роста, манипулятор, интриган, провокатор, который избегает ответственности за правила и алгоритмы ради сохранения своего места на работе. Иногда вследствие указанных особенностей могут возникать конфликты с последующим увольнением более эффективных сотрудников предприятия. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Потенциальная группа риска.",
    "comment_involvement_avoid_emotional_materialist": "Исполнитель без перспектив карьерного роста, «азартный игрок», который жертвует своими материальными ресурсами ради переживания пиковых эмоциональных состояний. Служащий-«зарплатник»,-«работает, чтобы жить, а не живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск растраты корпоративных ресурсов под благовидным предлогом ради удовлетворения своих эмоциональных потребностей. Потенциальная группа риска.",
    "comment_power_avoid_ideolog_materialist": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, который жертвует материальным благополучием ради соблюдения своих идей, правил и принципов. Служащий - «зарплатник», - «живёт, чтобы работать». Для него лучше небольшой оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_power_avoid_ideolog_emotional": "Потенциальный руководитель авторитарно-экспертного плана, выросший из рядовых специалистов, манипулятор и пропагандист, который жертвует своим эмоциональным комфортом ради точного соблюдения наработанных им определенных схем, алгоритмов и правил. Эти стандарты он навязывает подчинённым и окружающим с целью увеличения своей личной власти. Служащий - «зарплатник», - «живёт, чтобы работать». Стабильный оклад для него лучше, чем сдельная зарплата с перспективами бонусов и премий по итогам работы.",
    "comment_power_avoid_ideolog_vitalist": "Потенциальный руководитель авторитарно-бюрократического плана, выросший из рядовых специалистов, который жертвует качеством своей жизни в пользу соблюдения определенных законов, стандартов и правил. Основной принцип деятельности – «жизнь по правилу» или «жизнь по закону». Служащий - «зарплатник», - «живёт, чтобы работать». Для него лучше небольшой оклад, чем сдельная зарплата с перспективами бонусов и премий по итогам работы. Риск разрушения руководимой им системы в угоду соблюдения неэффективным принципам, законам и догм. Потенциальная группа риска."
  },
  "client": {
    "title": "Персональный советник - Тестирование",
    "hello": "Здравствуйте",
    "beforeWeStartPleaseEnterYourData": "Перед тем, как начать тестирование, пожалуйста введите свои данные",
    "beforeStartTest": "Перед началом тестирования Вы должны дать",
    "getPersonalDataProcessingAgreement": "согласие на обработку персональных данных",
    "enterData": "Ввести данные",
    "openFileFolder": "Открыть папку с файлами результатов",
    "openFolderWithTest": "Открыть папку с файлами тестирований",
    "claims": "Притязания",
    "interests": "Интересы",
    "strategy": "Стратегия",
    "motivation": "Мотивация",
    "extraQuestions": "Доп. вопросы",
    "done": "Готово",
    "nextWorker": "Следующий сотрудник",
    "processing": "Идет обработка...",
    "testing": "Тестирование",
    "testingDone": "Тестирование завершено",
    "testResultsSaved": "Результаты тестирования успешно сохранены и обработаны",
    "youCanDownloadMiniReport": "Вы можете скачать отчет с результатом тестирования",
    "help": "Справка",
    "back": "Назад",
    "exit": "Выйти",
    "confirmPersonalDataAgreement": "Вы должны дать согласие на обработку персональных данных",
    "helpFile": "help_ru.pdf",
    "personalDataAgreementFile": "agree_ru.pdf",
    "theAnswerIs": "Выбран ответ \"{0}\"",
    "theAnswerIsFrom": "Выбран ответ \"{0}\" из вариантов \"{1}\"",
    "yes": "Да",
    "no": "Нет",
    "startTest": "Начать тестирование",
    "endTest": "Завершить тестирование",
    "questionsRest": "Осталось ответить на {0}",
    "questionPlural": "вопрос|вопроса|вопросов",
    "pleaseIntroduce": "Пожалуйста, представьтесь",
    "showResults": "Просмотреть результаты",
    "pleaseEnterData": "Пожалуйста, введите свои данные",
    "chooseMoreSuitableAnswer": "Выберите ответ, который кажется Вам наиболее подходящим",
    "noMoreThanOneAnswer": "В каждой строке должно быть не более одного ответа.",
    "extraQuestion": "В критической ситуации Вам важнее {0} или {1}?",
    "chooseInterest": "Выберите {0} ценности",
    "youShouldChoose10Interests": "Необходимо выбрать ровно 10 ценностей в каждой группе",
    "youShouldChooseAtLeast3VirtualInterests": "Необходимо выбрать не менее 3 вируальных ценностей",
    "youShouldChooseAtLeast3AntiInterests": "Необходимо выбрать не менее 3 вируальных ценностей",
    "chooseMostValuableInterestInEachLine": "Выберите самую важную ценность в каждой строке",
    "chooseInterestsDesc": "Выберите в каждой колонке 10 наиболее значимых для Вас ценностей.",
    "virtualInterests": "Виртуальные ценности",
    "chooseVirtualInterestsDesc": "Выберите из перечисленных <b>минимум 3</b> «виртуальных ценностей» (они ценятся, потому что их нет, но очень хочется ими обладать, т.к. это добавляет силы. Например: Деньги-сбережения)",
    "antiInterests": "Антиценности",
    "antiInterestsDesc": "Выберите из перечисленных <b>минимум 3</b> «анти-ценностей» (они есть, но они отнимают силы и ресурсы. Например: Долг, Кредит)",
    "sortInterests": "Отсортируйте выбранные ценности в порядке важности <b>(самые важные сверху)</b>",
    "oneOfFour": "Один из четырех",
    "oneOfFourDesc": "Выберите из 4-х ценностей одну, самую важную на Ваш взгляд",
    "chooseOnlyOneAnswer": "В каждом вопросе отметьте только один вариант ответа",
    "important": "Очень важно",
    "notImportant": "Совсем не важно",
    "notReallyImportant": "Не очень важно",
    "next": "Далее",
    "selected": "Выбрано",
    "question": "Вопрос",
    "pleaseEnterYourName": "Пожалуйста, введите свое имя или никнейм",
    "confirmExit": "Вы уверены, что хотите завершить тестирование?"
  }
};
