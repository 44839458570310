<app-layout
  [activeBreadcrumb]="currentState"
  [maxQuestionNumber]="maxQuestion"
  [questionNumber]="currentQuestion"
>
  <app-profile
    *ngIf="currentState === 'ENTER'"
    [newTest]="newTest"
    (next)="next()"
  ></app-profile>
  <app-claims-test
    *ngIf="currentState === 'CLAIMS'"
    [newTest]="newTest"
    (nextState)="next()"
    (nextQuestion)="currentQuestion = $event"
  ></app-claims-test>
  <app-interests-test
    *ngIf="currentState === 'INTERESTS'"
    [newTest]="newTest"
    (nextState)="next()"
  ></app-interests-test>
  <app-strategy-test
    *ngIf="currentState === 'STRATEGY'"
    [newTest]="newTest"
    (nextState)="next()"
    (nextQuestion)="currentQuestion = $event"
  ></app-strategy-test>
  <app-motivation-test
    *ngIf="currentState === 'MOTIVATION'"
    [newTest]="newTest"
    (nextState)="next()"
    (nextQuestion)="currentQuestion = $event"
  ></app-motivation-test>
  <app-extra-questions-test
    *ngIf="currentState === 'EXTRA'"
    [newTest]="newTest"
    (nextState)="next()"
  ></app-extra-questions-test>

  <div *ngIf="currentState === 'FINISH'">
    <div *ngIf="isLoading" class="f-col f-align-center">
      <br/>
      Идет обработка...
    </div>
    <div *ngIf="!isLoading && reportResult" class="f-col f-align-center">
      <p class="text-18 v-gap-16"><b>Тестирование завершено</b></p>
      <button (click)="nextWorker.emit()" class="btn v-gap-16 finish-button">Следующий сотрудник</button>
    </div>
    <div *ngIf="tryAgain" class="f-col f-align-center">
      <p class="text-24 v-gap-16"><b> ⚠ Во время отправки данных произошла ошибка ⚠</b></p>
      <a (click)="sendResult()" class="btn finish-button v-gap-16">Попробовать еще раз</a>
      <br/>
      <button (click)="downloadTestFile()" class="btn secondary v-gap-16 finish-button">Скачать файл с ответами</button>
    </div>
  </div>
</app-layout>
