<div class="f-col f-align-center f-justify-center">
  <div class="text-center v-gap-16">
    <b>{{ getCurrentQuestion().text }}</b>
  </div>
  <div class="text-center v-gap-32">
    Выберите верный для Вас вариант
  </div>
    <label
      class="radio answer hover-input"
      (mouseenter)="startHover(true)"
      (mouseleave)="stopHover(true)"
      (click)="setAnswer(1)"
    >
      <input type="radio" [value]="1" [(ngModel)]="currentAnswer">
      <span>Да</span>
    </label>

    <label
      class="radio answer hover-input v-gap-32"
      (mouseenter)="startHover(false)"
      (mouseleave)="stopHover(false)"
      (click)="setAnswer(0)"
    >
      <input type="radio" [value]="0" [(ngModel)]="currentAnswer">
      <span>Нет</span>
    </label>

  <button class="btn" (click)="answer(currentAnswer)">Далее</button>
</div>
