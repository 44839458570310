import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestFile } from '@common/types/testFile';
import { Lang } from '@common/lang';
import { MotivationQuestion } from '@common/types/questions/motivation';
import { QuestionRepo } from '../../../services/question-repo/questionRepo';

@Component({
  selector: 'app-motivation-test',
  templateUrl: './motivation-test.component.html',
  styleUrls: ['./motivation-test.component.scss']
})
export class MotivationTestComponent implements OnInit {

  @Input() newTest: TestFile;
  @Output() nextState: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextQuestion = new EventEmitter<number>();

  public T = Lang.getText();

  public questions: MotivationQuestion[] = [];
  // Специальная переменная для вопроса является ли сотрудник руководителем
  public beforeLastQuestion = false;
  public currentOption: number = null;

  public currentQuestion = 0;
  private startThinkTime: number;
  private startHoverTime: number;
  private hoverTimes = {
    [-1]: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  };

  constructor(
    private questionRepo: QuestionRepo,
  ) {}

  async ngOnInit() {
    this.questions = this.questionRepo.motivation();
    this.startThinkTime = (new Date()).getTime();
  }

  public getCurrentQuestion(): MotivationQuestion {
    return this.questions[this.currentQuestion];
  }

  public getCurrentOptions(): { option: number, text: string }[] {
    const q = this.getCurrentQuestion();
    return Object.keys(q.options).map(key => ({ option: parseInt(key, 10), text: q.options[key] as string }));
  }

  trackByFn(option) {
    return option.option;
  }

  selectAnswer() {
    if (!this.currentOption) {
      return;
    }
    this.newTest.test.setAnswer(this.getCurrentQuestion().id, this.currentOption);
    this.currentOption = null;

    // Залисываем время ответа
    this.newTest.test.answerTimes.push({
      questionId: this.getCurrentQuestion().id,
      testGroup: 'motivation',
      startTime: this.startThinkTime,
      endTime: (new Date()).getTime(),
    });
    this.startThinkTime = (new Date()).getTime();

    // Записываем время фокуса
    this.stopHover(this.currentOption);
    this.startHover();
    Object.keys(this.hoverTimes).map(key => {
      if (Object.keys(this.getCurrentQuestion().options).indexOf(key) !== -1) {
        this.newTest.test.focusTimes.push({
          testGroup: 'motivation',
          questionId: this.getCurrentQuestion().id,
          answer: key,
          time: this.hoverTimes[key] || 0,
        });
      }
    });
    this.hoverTimes = {
      [-1]: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    };

    this.currentQuestion++;
    this.nextQuestion.emit(this.currentQuestion);
    if (this.currentQuestion === this.questions.length - 2) {
      this.beforeLastQuestion = true;
    }
    // Пропускаем последний вопрос, если мы ответили на предпоследний
    if (this.currentQuestion >= this.questions.length - 1) {
      this.nextState.emit(this.newTest);
    }
  }

  startHover() {
    this.startHoverTime = (new Date()).getTime();
  }

  stopHover(answer: number) {
    this.hoverTimes[answer] += (new Date()).getTime() - this.startHoverTime;
  }

  isLeader(answer: boolean) {
    // Сбрасываем замеряемое время для этого вопроса
    this.startThinkTime = (new Date()).getTime();
    this.startHover();
    this.beforeLastQuestion = false;
    if (!answer) {
      this.currentQuestion++;
    }
  }
}
