<div *ngIf="!beforeLastQuestion" class="text-center v-gap-32">
  <p class="text-18" *ngIf="getCurrentQuestion().groupText"><b>{{ getCurrentQuestion().groupText }}</b></p>
  <p class="text-18" [ngClass]="{'bold': !getCurrentQuestion().groupText}">{{ getCurrentQuestion().text }}</p>
  <p class="text-18" *ngIf="!getCurrentQuestion().groupText">Выберите наиболее подходящий для Вас вариант</p>
</div>

<div *ngIf="beforeLastQuestion" class="text-center v-gap-32">
  <p class="text-18"><b>{{ T.motivation.separate_question }}</b></p>
</div>

<div *ngIf="!beforeLastQuestion && !getCurrentQuestion().group" class="v-gap-32">
  <label
    *ngFor="let option of getCurrentOptions(); trackBy: trackByFn"
    (mouseenter)="startHover()"
    (mouseleave)="stopHover(option.option)"
    class="radio hover-input option v-gap-8"
  >
    <input
      type="radio"
      [(ngModel)]="currentOption"
      [value]="option.option"
    />
    <span>{{ option.text }}</span>
  </label>
</div>

<div *ngIf="!beforeLastQuestion && getCurrentQuestion().group" class="v-gap-32">
  <label
    *ngFor="let option of getCurrentOptions(); trackBy: trackByFn"
    (mouseenter)="startHover()"
    (mouseleave)="stopHover(option.option)"
    class="radio hover-input option v-gap-8">
    <input
      type="radio"
      [(ngModel)]="currentOption"
      [value]="option.option"
    />
    <span>{{ option.text }}</span>
  </label>
</div>

<div *ngIf="beforeLastQuestion">
  <div class="f-row f-grow f-justify-between">
    <button
      class="btn"
      (click)="isLeader(true)"
    >{{ T.client.yes }}
    </button>
    <button
      class="btn btn-default btn-lg btn-option width-all"
      (click)="isLeader(false)"
    >{{ T.client.no }}
    </button>
  </div>
</div>

<div class="f-row f-grow f-justify-center" *ngIf="!beforeLastQuestion">
  <button
    class="btn btn-default btn-lg btn-option width-all"
    (click)="selectAnswer()"
  >{{ T.client.next }}
  </button>
</div>
