<app-layout>
  <div class="f-row f-grow f-justify-center v-gap-16">
    <p class="text-18 text-center">Перед началом тестирования необходимо дать согласие на обработку персональных данных</p>
  </div>
  <div class="f-col f-grow v-gap-48">
    <p class="text-18"><b>СОГЛАСИЕ СУБЪЕКТА НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</b></p>
    <p class="text-18">Настоящим во исполнение требований Федерального закона от 27 июля 2006 года No 152-ФЗ «О персональных данных» свободно, своей волей и в своем интересе даю свое согласие на обработку моих персональных данных и хранение данных об этих результатах на электронных носителях оператору персональных данных – Обществу с ограниченной ответственностью «Умные кадровые решения»(далее – Оператор).</p>
    <p class="text-18">Под персональными данными я понимаю следующую информацию, относящуюся ко мне как к субъекту персональных данных: указанные мною в заявлении и (или) анкете, заполненных на Сайте <a [href]="env?.MAIN_URL" target="_blank">{{env?.MAIN_URL}}</a> (далее – Сайт) в целях прохождения мной тестирования, Фамилия, Имя, Отчество, Дата рождения, Пол, E-Mail, Номер телефона, Адрес регистрации, Наименование организации-работодателя, Наименование подразделения, Должность, Общий стаж трудовой деятельности, а также результаты моего тестирования.</p>
    <p class="text-18">Под обработкой персональных данных я понимаю следующие действия (операции) или совокупность действий (операций), совершаемые Оператором с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, удаление, уничтожение персональных данных, а также осуществление иных действий, предусмотренных действующим законодательством Российской Федерации. Данное согласие действует до достижения целей обработки персональных данных или в течение срока хранения информации.</p>
    <p class="text-18">Данное согласие может быть отозвано в любой момент по моему письменному заявлению.</p>
    <p class="text-18">Настоящим я подтверждаю, что указанные в заявлении и (или) анкете, заполненных на Сайте в целях прохождения тестирования, мои персональные данные являются достоверными и могут обрабатываться Оператором вышеуказанными способами.</p>
    <p class="text-18">Я согласен(на) с тем, что текст данного мной согласия хранится в электронном виде и (или) на бумажном носителе Оператором и подтверждает факт дачи согласия на обработку персональных данных.</p>
  </div>
  <div class="f-col w-100 f-justify-center f-align-center">
    <label class="checkbox v-gap-48 text-18">
      <input type="checkbox" [(ngModel)]="agreed">
      <span>Даю свое согласие на обработку персональных данных</span>
    </label>
    <button class="btn" (click)="next()">Далее</button>
  </div>
  <div class="v-gap-64"></div>
  <div></div>
</app-layout>
