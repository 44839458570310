import {QuestionInterface} from './questionInterface';
import {MotivationIndicator} from './indicators';
import {Result} from '@common/types/result';
import { Lang } from '@common/lang';

const T = Lang.getText();

export class MotivationQuestion implements QuestionInterface {
  public id: number;
  public text: string;
  public options: { [p: number]: string };
  public keys: { [p: number]: MotivationIndicator|MotivationIndicator[] };
  public group: number = null;
  public groupText: string = null;

  constructor(data: Partial<MotivationQuestion>) {
    Object.assign(this, data);
  }

  fillResult(result: Result, answer: any) {
    const res = this.keys[answer];
    if (!res) {
      return;
    }
    if (res instanceof Array) {
      res.forEach(item => result.addWeight(item));
    } else {
      result.addWeight(res);
    }
  }
}

export const motivationQuestions: MotivationQuestion[] = [
  new MotivationQuestion({
    id: 30001,
    text: T.motivation.question_30001,
    options: {
      1: T.motivation.question_30001_1,
      2: T.motivation.question_30001_2,
      3: T.motivation.question_30001_3,
      4: T.motivation.question_30001_4
    },
    keys: {1: 'master', 2: 'professional', 3: 'patriot', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30002,
    text: T.motivation.question_30002,
    options: {
      1: T.motivation.question_30002_1,
      2: T.motivation.question_30002_2,
      3: T.motivation.question_30002_3,
      4: T.motivation.question_30002_4
    },
    keys: {1: 'master', 2: 'professional', 3: 'patriot', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30003,
    text: T.motivation.question_30003,
    options: {
      1: T.motivation.question_30003_1,
      2: T.motivation.question_30003_2,
      3: T.motivation.question_30003_3,
      4: T.motivation.question_30003_4
    },
    keys: {1: 'lumpen', 2: 'professional', 3: 'master', 4: 'patriot'}
  }),
  new MotivationQuestion({
    id: 30004,
    text: T.motivation.question_30004,
    options: {
      1: T.motivation.question_30004_1,
      2: T.motivation.question_30004_2,
      3: T.motivation.question_30004_3,
      4: T.motivation.question_30004_4,
    },
    keys: {1: 'professional', 2: 'master', 3: 'patriot', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30005,
    text: T.motivation.question_30005,
    options: {
      1: T.motivation.question_30005_1,
      2: T.motivation.question_30005_2,
      3: T.motivation.question_30005_3,
      4: T.motivation.question_30005_4,
    },
    keys: {1: 'professional', 2: 'patriot', 3: 'lumpen', 4: 'master'}
  }),
  new MotivationQuestion({
    id: 30061,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30061,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: ['professional', 'lumpen'], 2: 'patriot', [-1]: 'master'}
  }),
  new MotivationQuestion({
    id: 30062,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30062,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'professional', 2: 'lumpen', [-1]: ['master', 'patriot']}
  }),
  new MotivationQuestion({
    id: 30063,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30063,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'lumpen', 2: 'professional', [-1]: ['patriot', 'master']}
  }),
  new MotivationQuestion({
    id: 30064,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30064,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'lumpen', 2: 'patriot', [-1]: ['master', 'professional']}
  }),
  new MotivationQuestion({
    id: 30065,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30065,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'master', [-1]: ['patriot', 'professional', 'lumpen'], 2: []}
  }),
  new MotivationQuestion({
    id: 30066,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30066,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'lumpen', 2: 'professional', [-1]: ['master', 'patriot']}
  }),
  new MotivationQuestion({
    id: 30067,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30067,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'professional', 2: 'patriot', [-1]: ['lumpen', 'master']}
  }),
  new MotivationQuestion({
    id: 30068,
    group: 6,
    groupText: T.motivation.question_30006,
    text: T.motivation.question_30068,
    options: {1: T.pages.important, 2: T.pages.notReallyImportant, [-1]: T.pages.notImportant},
    keys: {1: 'lumpen', 2: 'master', [-1]: ['professional', 'patriot']}
  }),
  new MotivationQuestion({
    id: 30007,
    text: T.motivation.question_30007,
    options: {
      1: T.motivation.question_30007_1,
      2: T.motivation.question_30007_2,
      3: T.motivation.question_30007_3
    },
    keys: {1: ['patriot', 'master'], 2: 'professional', 3: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30008,
    text: T.motivation.question_30008,
    options: {
      1: T.motivation.question_30008_1,
      2: T.motivation.question_30008_2,
      3: T.motivation.question_30008_3,
      4: T.motivation.question_30008_4
    },
    keys: {1: 'master', 2: 'professional', 3: 'patriot', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30009,
    text: T.motivation.question_30009,
    options: {
      1: T.motivation.question_30009_1,
      2: T.motivation.question_30009_2,
      3: T.motivation.question_30009_3,
      4: T.motivation.question_30009_4
    },
    keys: {1: 'patriot', 2: 'professional', 3: 'master', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30010,
    text: T.motivation.question_30010,
    options: {
      1: T.motivation.question_30010_1,
      2: T.motivation.question_30010_2,
      3: T.motivation.question_30010_3,
      4: T.motivation.question_30010_4
    },
    keys: {1: 'patriot', 2: 'master', 3: 'professional', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30011,
    text: T.motivation.question_30011,
    options: {
      1: T.motivation.question_30011_1,
      2: T.motivation.question_30011_2,
      3: T.motivation.question_30011_3,
      4: T.motivation.question_30011_4,
    },
    keys: {1: 'professional', 2: 'master', 3: 'lumpen', 4: 'patriot'}
  }),
  new MotivationQuestion({
    id: 30012,
    text: T.motivation.question_30012,
    options: {
      1: T.motivation.question_30012_1,
      2: T.motivation.question_30012_2,
      3: T.motivation.question_30012_3,
      4: T.motivation.question_30012_4,
    },
    keys: {1: 'professional', 2: 'lumpen', 3: 'patriot', 4: 'master'}
  }),
  new MotivationQuestion({
    id: 30013,
    text: T.motivation.question_30013,
    options: {
      1: T.motivation.question_30013_1,
      2: T.motivation.question_30013_2,
      3: T.motivation.question_30013_3,
      4: T.motivation.question_30013_4,
    },
    keys: {1: 'master', 2: 'patriot', 3: 'professional', 4: 'lumpen'}
  }),
  new MotivationQuestion({
    id: 30014,
    text: T.motivation.question_30014,
    options: {
      1: T.motivation.question_30014_1,
      2: T.motivation.question_30014_2,
      3: T.motivation.question_30014_3,
      4: T.motivation.question_30014_4,
    },
    keys: {1: 'master', 2: 'patriot', 3: 'professional', 4: 'lumpen'}
  })
];
