import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestFile } from '@common/types/testFile';
import { Lang } from '@common/lang';
import { Worker } from '@common/types/worker';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @Input() newTest: TestFile;
  @Output() next: EventEmitter<any> = new EventEmitter<any>();

  public T = Lang.getText();
  public errors: { [p in keyof Worker]?: string } = {};

  constructor() { }

  ngOnInit() {
  }

  async validateUser() {
    this.errors = {};
    if (!this.newTest.worker.firstName) {
      this.errors['firstName'] = this.T.pages.errorNameNotFilled;
    }
    if (!this.newTest.worker.secondName) {
      this.errors['secondName'] = this.T.pages.errorSurnameNotFilled;
    }
    if (!this.newTest.worker.birthday) {
      this.errors['birthday'] = this.T.pages.errorBirthdayNotFilled;
    }
    if (!this.newTest.worker.getBirthdayDate()) {
      this.errors['birthday'] = this.T.pages.errorBirthdayIncorrect;
    }
    if (this.newTest.worker.getBirthdayDate() > new Date()) {
      this.errors['birthday'] = this.T.pages.errorBirthdayIncorrect;
    }
    if (Object.keys(this.errors).length > 0) {
      return;
    }
    this.next.emit();
  }
}
