import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DotEnvInterface } from './dot-env.interface';
import { parseDotEnv } from '@common/utils/dotenv/parseDotEnv';


@Injectable()
export class DotEnvService {

  constructor(
    protected http: HttpClient,
  ) {
  }

  public async get(): Promise<DotEnvInterface> {
    const data = await this.http.get('/assets/public.env', { responseType: 'text' }).toPromise();
    return parseDotEnv(data) as DotEnvInterface;
  }
}
