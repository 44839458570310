import {ClaimIndicator, Indicator, indicatorTitle, InterestIndicator, MotivationIndicator, StrategyIndicator} from '@common/types/questions/indicators';

export interface ResultValueInterface {
  name: Indicator;
  nameTitle: string;
  weight: number;
  extraWeight: number;
  isConflict?: boolean;
}

export class Result  {

  public resultArray: ResultValueInterface[][] = [];

  public fellAnswers = 0;
  public understandAnswers = 0;
  public watchAnswers = 0;
  public actionAnswers = 0;

  constructor() {
    const line1: ClaimIndicator[] = ['involvement', 'avoid', 'achievement', 'power'];
    const line2: InterestIndicator[] = ['ideolog', 'materialist', 'emotional', 'vitalist'];
    const line3: StrategyIndicator[] = ['pragmatic', 'watcher', 'practices', 'theorist'];
    const line4: MotivationIndicator[] = ['master', 'patriot', 'professional', 'lumpen'];
    const lines = [line1, line2, line3, line4];
    for (let i = 0; i < lines.length; i++) {
      this.resultArray[i] = [];
      for (const item of lines[i]) {
        this.resultArray[i].push({ name: item, nameTitle: indicatorTitle(item), weight: 0, extraWeight: 0 });
      }
    }
  }

  public addWeight(i: Indicator, value = 1) {
    this.getValue(i).weight += value;
  }

  public setExtraWeight(i: Indicator, value) {
    this.getValue(i).extraWeight = value;
  }

  public getValue(indicator: Indicator): ResultValueInterface {
    for (let i = 0; i < 4; i++) {
      const item = this.resultArray[i].find(arrayItem => arrayItem.name === indicator);
      if (item) {
        return item;
      }
    }
    return null;
  }

  public sort() {
    for (let i = 0; i < 4; i++) {
      this.resultArray[i].sort((a, b) => {
        if (a.weight !== b.weight) {
          return b.weight - a.weight;
        }
        return b.extraWeight - a.extraWeight;
      });
    }
    // Проставяем метки для кофликтных значений
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        this.resultArray[i][j].isConflict = false;
      }
    }
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 3; j++) {
        if (this.resultArray[i][j].weight === this.resultArray[i][j + 1].weight) {
          this.resultArray[i][j].isConflict = true;
          this.resultArray[i][j + 1].isConflict = true;
        }
      }
    }
  }

  isConflict(): boolean {
    for (let i = 0; i < this.resultArray.length; i++) {
      const row = this.resultArray[i];
      for (let j = 0; j < row.length - 1; j++) {
        if (row[j].weight === row[j + 1].weight && row[j].extraWeight === row[j + 1].extraWeight) {
          return true;
        }
      }
    }
    return false;
  }
}
