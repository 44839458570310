import { T_EN } from './lang_en';
import { T_RU } from './lang_ru';
import { T_SVS } from './lang_svs';

export class Lang {
  public static lang: 'EN' | 'RU' | 'SVS' = 'RU';
  public static getText(): typeof T_RU {
    const list = {
      EN: T_EN,
      RU: T_RU,
      SVS: T_SVS,
    };
    return list[this.lang] as any;
  }
}
