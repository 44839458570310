import {QuestionInterface} from '@common/types/questions/questionInterface';
import {Result} from '@common/types/result';
import { Lang } from '@common/lang';

const T = Lang.getText();

export class StrategyQuestion implements QuestionInterface {
  public id: number;
  public text: string;
  public options: { [p: number]: string };

  constructor(data: Partial<StrategyQuestion>) {
    Object.assign(this, data);
  }

  fillResult(result: Result, answer: any) {
    switch (answer) {
      case 1:
        result.addWeight('practices');
        result.addWeight('watcher');
        result.fellAnswers++;
        break;
      case 2:
        result.addWeight('theorist');
        result.addWeight('watcher');
        result.watchAnswers++;
        break;
      case 3:
        result.addWeight('theorist');
        result.addWeight('pragmatic');
        result.understandAnswers++;
        break;
      case 4:
        result.addWeight('practices');
        result.addWeight('pragmatic');
        result.actionAnswers++;
        break;
    }
  }
}

export const strategyQuestions: StrategyQuestion[] = [
  new StrategyQuestion({
    id: 20001,
    text: T.strategy.question_20001,
    options: {
      1: T.strategy.question_20001_1,
      2: T.strategy.question_20001_2,
      3: T.strategy.question_20001_3,
      4: T.strategy.question_20001_4
    },
  }),
  new StrategyQuestion({
    id: 20002,
    text: T.strategy.question_20002,
    options: {
      1: T.strategy.question_20002_1,
      2: T.strategy.question_20002_2,
      3: T.strategy.question_20002_3,
      4: T.strategy.question_20002_4
    },
  }),
  new StrategyQuestion({
    id: 20003,
    text: T.strategy.question_20003,
    options: {
      1: T.strategy.question_20003_1,
      2: T.strategy.question_20003_2,
      3: T.strategy.question_20003_3,
      4: T.strategy.question_20003_4
    }
  }),
  new StrategyQuestion({
    id: 20004,
    text: T.strategy.question_20004,
    options: {
      1: T.strategy.question_20004_1,
      2: T.strategy.question_20004_2,
      3: T.strategy.question_20004_3,
      4: T.strategy.question_20004_4,
    },
  }),
  new StrategyQuestion({
    id: 20005,
    text: T.strategy.question_20005,
    options: {
      1: T.strategy.question_20005_1,
      2: T.strategy.question_20005_2,
      3: T.strategy.question_20005_3,
      4: T.strategy.question_20005_4,
    },
  }),
  new StrategyQuestion({
    id: 20006,
    text: T.strategy.question_20006,
    options: {
      1: T.strategy.question_20006_1,
      2: T.strategy.question_20006_2,
      3: T.strategy.question_20006_3,
      4: T.strategy.question_20006_4,
    },
  }),
  new StrategyQuestion({
    id: 20007,
    text: T.strategy.question_20007,
    options: {
      1: T.strategy.question_20007_1,
      2: T.strategy.question_20007_2,
      3: T.strategy.question_20007_3,
      4: T.strategy.question_20007_4,
    },
  }),
  new StrategyQuestion({
    id: 20008,
    text: T.strategy.question_20008,
    options: {
      1: T.strategy.question_20008_1,
      2: T.strategy.question_20008_2,
      3: T.strategy.question_20008_3,
      4: T.strategy.question_20008_4,
    },
  }),
  new StrategyQuestion({
    id: 20009,
    text: T.strategy.question_20009,
    options: {
      1: T.strategy.question_20009_1,
      2: T.strategy.question_20009_2,
      3: T.strategy.question_20009_3,
      4: T.strategy.question_20009_4,
    },
  }),
  new StrategyQuestion({
    id: 20010,
    text: T.strategy.question_20010,
    options: {
      1: T.strategy.question_20010_1,
      2: T.strategy.question_20010_2,
      3: T.strategy.question_20010_3,
      4: T.strategy.question_20010_4,
    },
  }),
  new StrategyQuestion({
    id: 20011,
    text: T.strategy.question_20011,
    options: {
      1: T.strategy.question_20011_1,
      2: T.strategy.question_20011_2,
      3: T.strategy.question_20011_3,
      4: T.strategy.question_20011_4,
    },
  }),
  new StrategyQuestion({
    id: 20012,
    text: T.strategy.question_20012,
    options: {
      1: T.strategy.question_20012_1,
      2: T.strategy.question_20012_2,
      3: T.strategy.question_20012_3,
      4: T.strategy.question_20012_4,
    },
  }),
];
