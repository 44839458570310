import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DotEnvService} from '@common/ng/dot-env/dot-env.service';
import {ActivatedRoute} from '@angular/router';
import {OnlineApiService} from '../services/onlineApi/online-api.service';
import {DotEnvInterface} from '@common/ng/dot-env/dot-env.interface';
import * as moment from 'moment';
import {AlertService} from '@common/ng/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  public stage: 'start' | 'test' | 'loading' = 'loading';
  public preventClose = false;
  public subs: Subscription[] = [];
  public config: DotEnvInterface;
  public stopByError = false;

  constructor(
    public dotEnv: DotEnvService,
    public alert: AlertService,
    public api: OnlineApiService,
    public route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.config = await this.dotEnv.get();
    this.subs.push(this.route.queryParams.subscribe(async params => {
      if (params.token) {
        this.api.setToken(params.token);
        await this.start();
      } else {
        this.stopByError = true;
        await this.alert.html(`Для доступа к тестированию, используйте ссылку в
            <a href="${this.config.MAIN_URL}" target="_blank">Личном кабинете</a>`, 'HR Lean', true);
      }
    }));

    await this.api.sampleLog();

    // Проверяем каждые 30 сек, не "протухли" ли данные пользователя"
    setInterval(async () => {
      if (!this.stopByError) {
        await this.check();
      }
    }, 30 * 1000);
  }

  protected apiError(error: string, status: number = null) {
    this.stopByError = true;
    if (status === 0) {
      // TODO! Нужно дать возможность пользователю продолжить тестирование после восстановления соединения
      this.alert.html(
        `Пожалуйста, проверьте Ваше соединение с интернетом`,
        'Соединение разорвано',
        true,
        [{text: 'Проверить ещё раз', action: async (toast) => {
            await this.check();
            this.alert.removeToast(toast.id);
          }}]
      );
    } else {
      this.alert.html(
        `<br/>${error}<br/><p>Пожалуйста, обратитесь к администратору или
        <a href=${this.config.MAIN_URL}/#contacts target="_blank">свяжитесь с нами</a></p>`,
        'Ошибка доступа',
        true);
    }
  }

  protected async check(): Promise<boolean> {
    const profile = await this.api.loadProfile(async (error, status) => this.apiError(error, status));
    if (!profile) {
      return false;
    }
    if (profile.onlineTestsUsed >= profile.onlineTests) {
      this.apiError('У Вас закончились онлайн тестирования');
      return false;
    }
    if (!profile.notExpires && moment().diff(profile.onlineExpired) > 0) {
      this.apiError('Срок действия Вашей лицензии истек');
      return false;
    }
    return true;
  }

  public setStage(stage: 'start' | 'test' | 'loading') {
    this.stage = stage;
    window.scrollTo({ top: 0 });
  }

  public async start() {
    this.setStage('loading');
    if (await this.check()) {
      this.setStage('start');
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.preventClose) {
      $event.returnValue = true;
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
