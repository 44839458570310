<div class="f-col f-align-center">
  <p class="text-18 text-center v-gap-32"><b>{{ getQuestsion() }}</b></p>
  <label class="radio hover-input option v-gap-8">
    <input
      type="radio"
      class="btn"
      [(ngModel)]="currentMatch"
      [value]="1"
    />
    <span [innerHtml]="getTitle(versus1) | safeHtml"></span>
  </label>
  <label class="radio hover-input option v-gap-32">
    <input
      type="radio"
      class="btn"
      [(ngModel)]="currentMatch"
      [value]="2"
    />
    <span [innerHtml]="getTitle(versus2) | safeHtml"></span>
  </label>
  <button
    class="btn"
    (click)="answer()"
  >{{ T.client.next }}</button>
</div>

