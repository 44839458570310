import { Result } from './result';
import { IndicatorGroups } from '@common/types/questions/indicators';

export interface AnswersInterface {
  [questionId: number]: any;
}

export interface ExtraAnswersInterface {
  [indicator: string]: number;
}

export class Test {
  public id: number;
  public uuid: string;
  public date: string;
  public shortReportFile: string;
  public longReportFile: string;
  public answers: AnswersInterface = {};
  public extraAnswers: ExtraAnswersInterface = {};
  public workerId: number;
  public executeTime = 0;
  public answerTimes: { questionId: number, testGroup: IndicatorGroups, startTime: number, endTime: number, comment?: string }[] = [];
  public focusTimes: { questionId: number, testGroup: IndicatorGroups, answer: any, time: number, comment?: string }[] = [];

  public result: Result;

  public constructor(data: any = {}) {
    Object.assign(this, data);
  }

  public isAnswer(questionId, answer) {
    return this.answers[questionId] && this.answers[questionId] === answer;
  }

  public setAnswer(questionId, answer) {
    this.answers[questionId] = answer;
  }

  public hasAnswer(questionId) {
    return !!this.answers[questionId];
  }

  public addOption(questionId, option) {
    if (!this.answers[questionId]) {
      this.answers[questionId] = [];
    }
    this.answers[questionId].push(option);
  }

  public toggleOption(questionId, option) {
    if (this.hasOption(questionId, option)) {
      this.removeOption(questionId, option);
    } else {
      this.addOption(questionId, option);
    }
  }

  public removeOption(questionId, option, i = 0) {
    if (!this.answers[questionId]) {
      return;
    }
    const index1 = this.answers[questionId].indexOf(option);
    if (index1 === -1) {
      return;
    }
    const index2 = this.answers[questionId].lastIndexOf(option);
    // Выбираем с какой стороны удалять элемент - с начала или с конца
    // (нужно для корректного удаления при перестановке)
    const index = Math.abs(index1 - i) < Math.abs(index2 - i) ? index1 : index2;
    this.answers[questionId].splice(index, 1);
  }

  public hasOption(questionId, option) {
    return this.answers[questionId] && this.answers[questionId].indexOf(option) !== -1;
  }

  public executeMinutesString() {
    const zeros = '00';
    const minutes = Math.floor(this.executeTime / 60);
    return zeros.substring((minutes.toString()).length, 2) + minutes.toString();
  }

  public executeSecondsString() {
    const zeros = '00';
    const seconds = this.executeTime % 60;
    return zeros.substring((seconds.toString()).length, 2) + seconds.toString();
  }
}
