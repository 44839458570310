import { Lang } from '@common/lang';

const T = Lang.getText();

export type IndicatorGroups = 'claims' | 'interests' | 'strategy' | 'motivation';
export type ClaimIndicator = 'avoid' | 'power' | 'achievement' | 'involvement';
export type InterestIndicator = 'ideolog' | 'materialist' | 'emotional' | 'vitalist';
export type StrategyIndicator = 'practices' | 'watcher' | 'pragmatic' | 'theorist';
export type MotivationIndicator = 'master' | 'patriot' | 'professional' | 'lumpen';

export type Indicator = ClaimIndicator | InterestIndicator | StrategyIndicator | MotivationIndicator;

export const ALL_INDICATORS: Indicator[] = [
  'avoid', 'power', 'achievement', 'involvement',
  'ideolog', 'materialist', 'emotional', 'vitalist',
  'practices', 'watcher', 'pragmatic', 'theorist',
  'master', 'patriot', 'professional', 'lumpen',
];

export function indicatorTitle(i: Indicator): string {
  switch (i) {
    case 'avoid':
      return T.reports.avoid;
    case 'power':
      return T.reports.power;
    case 'achievement':
      return T.reports.achievement;
    case 'involvement':
      return T.reports.involvement;

    case 'ideolog':
      return T.reports.ideolog;
    case 'materialist':
      return T.reports.materialist;
    case 'emotional':
      return T.reports.emotional;
    case 'vitalist':
      return T.reports.vitalist;

    case 'practices':
      return T.reports.practices;
    case 'watcher':
      return T.reports.watcher;
    case 'pragmatic':
      return T.reports.pragmatic;
    case 'theorist':
      return T.reports.theorist;

    case 'master':
      return T.reports.master;
    case 'patriot':
      return T.reports.patriot;
    case 'professional':
      return T.reports.professional;
    case 'lumpen':
      return T.reports.lumpen;
  }
}
