import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AlertService} from '@common/ng/alert.service';
import { DotEnvService } from '@common/ng/dot-env/dot-env.service';
import { DotEnvInterface } from '@common/ng/dot-env/dot-env.interface';

@Component({
  selector: 'app-start-testing-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  public agreed = false;
  public env: DotEnvInterface = null;
  public isLoading = true;
  @Output() onAgree = new EventEmitter();

  constructor(
    public alert: AlertService,
    protected dotEnv: DotEnvService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.env = await this.dotEnv.get();
    this.isLoading = true;
  }

  async next() {
    if (!this.agreed) {
      await this.alert.alert('Для продолжения Вы должны дать согласие на обработку персональных данных');
      return;
    }
    this.onAgree.emit();
  }
}
