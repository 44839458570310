import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { IndexModule } from './index.module';
import { environment } from './environments/environment';
import { checkSupportedBrowser, printUnsupportedBrowserText } from './browser-check';

if (checkSupportedBrowser()) {
  if (environment.production) {
    enableProdMode();
  }
  platformBrowserDynamic().bootstrapModule(IndexModule)
    .catch(err => console.error(err));
} else {
  printUnsupportedBrowserText();
}

