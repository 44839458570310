<div class="text-center v-gap-32">
  <p class="text-18 v-gap-16"><b>{{ getCurrentQuestion().text }}</b></p>
  <p class="text-18">Выберите верный для Вас вариант</p>
</div>

<div class="f-col f-grow f-align-start v-gap-64">
  <label class="radio hover-input option v-gap-8"
         *ngFor="let option of getCurrentOptions(); trackBy: trackByFn">
    <input
      type="radio"
      [(ngModel)]="currentAnswer"
      [value]="option"
      (mouseenter)="startHover()"
      (mouseleave)="stopHover(option.option)"
    />
    <span>{{ option.text }}</span>
  </label>
</div>

<div class="f-row f-grow f-align-center f-justify-center">
  <button class="btn" (click)="selectAnswer(currentAnswer)">Далее</button>
</div>

<div class="v-gap-32"></div>
<div></div>
